import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Utils} from "@common/lib/utils";

@Injectable({
  providedIn: "root"
})
export class InstanceIsConnectedGuard implements CanActivate {

  constructor(private userService: UserService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    while (!this.userService.user || !this.userService.user.controllers.controllers) {
      await Utils.sleep(100);
    }
    while (true) {
      const instance = this.userService.user.controllers.controllers.find(elt => elt.id == route.params.controllerId);
      this.userService.controllerSelected = instance;
      if (instance && instance.isLoaded) {
        break;
      } else {
        console.log("Instance not loaded i wait");
        await Utils.sleep(100);
      }
    }

    return true;
  }

}
