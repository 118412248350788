import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";

@Injectable({
  providedIn: "root"
})
export class TeamResolver implements Resolve<boolean> {
  constructor(private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.userService.componentSelected = ComponentTypeSelectedEnum.TEAM;
    const controller = this.userService.user.controllers.controllers.find(
      elt => elt.id === route.params.controllerId);
    // @ts-ignore
    this.userService.controllerSelected = controller;
    this.userService.teamSelected = controller.teams.find(elt => elt.id == route.params.teamId);
    this.userService.botSelected = null;
    this.userService.user.emit("ControllerSelectedUpdated");
    // @ts-ignore
    return of(this.userService.teamSelected);
  }
}
