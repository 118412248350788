<button
  [ngClass]="{'selected': selected}"
  (contextmenu)="onContextMenu($event)" class="button-router" mat-button>
  <mat-icon>description</mat-icon>
  <span class="label">{{proxyServer.id}}</span>
  <span class="fake-div"></span>
  <div [style.background-color]="proxyServer.connected ? 'green':'red'"
       class="state mat-elevation-z8"></div>
</button>
<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <!--    <button (click)="userService.user.scripts.deleteScript(script)" mat-menu-item>Supprimer le script</button>-->
  </ng-template>
</mat-menu>
