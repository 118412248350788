import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {MatDialog} from "@angular/material/dialog";
import {ControllerSchema} from "@dashboard/src/app/service/user/controllers/ControllerSchema";

@Component({
  selector: "app-controller-element",
  templateUrl: "./controller-element.component.html",
  styleUrls: ["./controller-element.component.scss"]
})
export class ControllerElementComponent implements OnInit {
  @Input() controller: Controller;
  @Input() name: string;
  @Input() inGroup: boolean;
  @Input() schema: ControllerSchema;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};

  constructor(public userService: UserService, public dialog: MatDialog) {
  }

  public get selected() {
    return this.userService.controllerSelected == this.controller;
  }

  public get otherGroups() {
    return this.userService.user.controllers.schema.elements.filter(elt => elt._type == "ControllerGroupSchema");
  }

  ngOnInit(): void {
  }

  clickOnContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    // this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  restartController() {
    this.controller.restart();
  }

  rename() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Rename the controller",
        forms: [{
          label: "Name of the controller",
          name: "name",
          type: "string",
          required: true,
          defaultValue: this.controller.name
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        const controllerSchema = this.userService.user.controllers.schema.getControllerById(this.controller.id);
        if (controllerSchema) {
          controllerSchema.name = result.name;
          this.userService.user.controllers.sendUpdateSchema();
        }
      }
    });
  }

  public get infosCountBot() {
    const countBots = this.controller.bots.length;
    const countBotsConnected = this.controller.bots.filter(elt => elt.network.connected).length;
    return `${countBotsConnected} / ${countBots}`;
  }
}
