import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

export class SimplePather {
  private bot: Bot;
  public onBreak: boolean;

  public constructor(bot: Bot, simplePather: SimplePather) {
    this.bot = bot;
    this.onBreak = simplePather.onBreak;
    this.monitorPackets()
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("PatherStateUpdated",(packet: any) => {
      this.onBreak = packet.onBreak;
    })
  }

  public updateOnBreak(onBreak: boolean) {
    this.bot.send("PatherStateUpdateRequestMessage",{
      onBreak: onBreak
    })
  }
}
