import {Component} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-add-team-instance-dialog",
  templateUrl: "./add-team-instance-dialog.component.html",
  styleUrls: ["./add-team-instance-dialog.component.scss"]
})
export class AddTeamInstanceDialogComponent {

  public formGroup = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required])
  });

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<AddTeamInstanceDialogComponent>) {
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        name: this.formGroup.get("name").value
      });
    }
  }

  public close() {
    this.dialogRef.close(null);
  }
}
