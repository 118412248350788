import {Routes} from "@angular/router";
import {IsSignedInGuard} from "@dashboard/src/app/security/IsSignedInGuard";
import {ControllerComponent} from "@dashboard/src/app/pages/controller/controller.component";
import {ControllerResolver} from "@dashboard/src/app/pages/controller/controller.resolver";
import {
  ControllerConsoleComponent
} from "@dashboard/src/app/pages/controller/controller-console/controller-console.component";
import {
  ControllerConfigComponent
} from "@dashboard/src/app/pages/controller/controller-config/controller-config.component";


export const ROUTES_INSTANCES_NEZU_PC: Routes = [
  {
    path: "controller/:controllerId",
    component: ControllerComponent,
    canActivate: [
      IsSignedInGuard
    ],
    resolve: {
      controller: ControllerResolver
    },
    children: [
      {
        path: "",
        outlet: "tabs",
        component: ControllerConsoleComponent
      },
      {
        path: "console",
        outlet: "tabs",
        component: ControllerConsoleComponent
      },
      {
        path: "config",
        outlet: "tabs",
        component: ControllerConfigComponent
      }
    ]
  }
];
