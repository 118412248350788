import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Utils} from "@common/lib/utils";

@Injectable({
  providedIn: "root"
})
export class IsSignedInGuard implements CanActivate {

  constructor(private userService: UserService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    while (!this.userService.user || !this.userService.user.controllers.controllers) {
      await Utils.sleep(100);
    }
    return true;
  }

}
