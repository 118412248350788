<div class="informations-map">
  <span>{{ bot.game.map.position }}</span>
  <span>{{ bot.game.map.worldName + " [" + bot.game.map.worldId + "] [" + bot.game.map.areaId + "]" }}</span>
  <span>{{ bot.game.map.subAreaName + " [" + bot.game.map.subAreaId + "]" }}</span>
  <span>{{ bot.game.map.mapName }}</span>
  <span>{{ bot.game.map.mapId }}</span>
</div>

<div *ngIf="bot.game.character.state.context == 1" class="informations-role-play">
  <div class="entities-list">
    <div *ngFor="let entitie of bot.game.map.actors.getPlayerActors()">
      <!--      {{entitie.name + " on " + entitie.disposition.cellId}}-->
    </div>
  </div>
</div>

<div *ngIf="bot.game.character.state.context == 2" class="informations-fight">
  <div class="fighters-list">
    <div *ngFor="let fighter of bot.game.fight.allies"
         [ngClass]="{ 'isTurn': bot.game.fight.turnFighterId == fighter.contextualId }"
         class="fighter-infos">
      <span class="fighter-level">
       {{ fighter.level }}
      </span>
      <span *ngIf="fighter.name" class="fighter-name">
        {{
          fighter.name.length > 24 ?
            (fighter.name | slice:0:24) + '..'
            : fighter.name
        }}
      </span>
      <span class="fighter-pa">
        {{ fighter.stats.actionPoints }} PA
      </span>
      <span class="fighter-pm">
        {{ fighter.stats.movementPoints }} PM
      </span>
      <div class="progress-container">
        <mat-progress-bar
          [mode]="'determinate'"
          [value]="fighter.lifePourcent"
          class="progress-bar-stats"
          color="warn">
        </mat-progress-bar>
        <div class="value-box">
          {{ fighter.stats.lifePoints + ' (' + fighter.lifePourcent + '%)' }}
        </div>
      </div>
    </div>
  </div>
  <div class="fighters-list">
    <div *ngFor="let fighter of bot.game.fight.enemies"
         [ngClass]="{ 'isTurn': bot.game.fight.turnFighterId == fighter.contextualId }"
         class="fighter-infos">
      <span class="fighter-level">
        {{ fighter.level }}
      </span>
      <span class="fighter-name">
     {{
          fighter.name.length > 24 ?
            (fighter.name | slice:0:24) + '..'
            : fighter.name
        }}
      </span>
      <span class="fighter-pa">
        {{ fighter.stats.actionPoints }} PA
      </span>
      <span class="fighter-pm">
        {{ fighter.stats.movementPoints }} PM
      </span>
      <div class="progress-container">
        <mat-progress-bar
          [mode]="'determinate'"
          [value]="fighter.lifePourcent"
          class="progress-bar-stats"
          color="warn">
        </mat-progress-bar>
        <div class="value-box">
          {{ fighter.stats.lifePoints + ' (' + fighter.lifePourcent + '%)' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!bot.game.character.state.context" class="informations-null">

</div>

<div class="canvas-container">
  <div #KonvaContainer class="myCanvas" id="container-konva"></div>
</div>

<div id="menu">
  <div>
    <button id="pulse-button">Pulse</button>
    <button id="delete-button">Delete</button>
  </div>
</div>
