import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomConsoleComponent} from '@dashboard/src/app/features/custom-console/custom-console.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [CustomConsoleComponent],
  exports: [CustomConsoleComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ]
})
export class CustomConsoleModule {
}
