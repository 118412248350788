import {Component, OnInit} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

@Component({
  selector: "app-bot-config",
  templateUrl: "./bot-config.component.html",
  styleUrls: ["./bot-config.component.scss"]
})
export class BotConfigComponent implements OnInit {
  public bot: Bot;
  public formControlConfig: UntypedFormControl;
  public editorOptions = {theme: "vs-dark", automaticLayout: true, language: "json"};


  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("BotConfigUpdatedMessage", (packet: any) => {
      if (packet.force) {
        this.initData();
      }
    }, 5000);
    console.log("init form test");
    this.initData();
  }


  public initData() {
    this.initFormControl(this.bot);
  }

  private initFormControl(bot: Bot) {
    this.formControlConfig = new UntypedFormControl(bot.config.configContent);
    this.formControlConfig.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((newValue: string) => {
      if (bot.config.configContent !== newValue) {
        try {
          JSON.parse(newValue);
          bot.config.configContent = newValue;
          bot.config.updateConfig(newValue);
        } catch (e) {
          console.log(`Config not a valid json`);
        }
      }
    });
  }


  updateConfig() {

  }

  reformatCode() {

  }

  configContentChange($event: any) {

  }
}
