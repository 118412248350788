import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {MatMenuTrigger} from "@angular/material/menu";
import {ActivatedRoute, Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "app-bot-element",
  templateUrl: "./bot-element.component.html",
  styleUrls: ["./bot-element.component.scss"]
})
export class BotElementComponent implements OnInit {
  @Input() bot: Bot;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit(): void {
  }


  onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
    // this.ref.detectChanges();
  }

  remove() {
    this.bot.controller.removeBot(this.bot.login);
  }

  async selectThisBot() {
    let outletRoute = "";
    if (this.userService.botSelected) {
      const primaryChild = this.route.children.find(elt => elt.outlet == "primary");
      if (primaryChild) {
        const childrenTabs = primaryChild.children.find(elt => elt.outlet == "tabs");
        if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
          outletRoute = childrenTabs.snapshot.url[0].path;
        } else {
          outletRoute = "console";
        }
      }
    }
    if (!outletRoute) {
      outletRoute = "console";
    }
    await this.router.navigateByUrl(`controller/${this.bot.controllerId}`);
    this.router.navigateByUrl(`/controller/${this.bot.controllerId}/bot/${this.bot.login}/(tabs:${outletRoute})`);
  }

  copyLoginPassword() {
    this.clipboard.copy(this.bot.login + " " + this.bot.password);
  }

  copyPseudo() {
    this.clipboard.copy(this.bot.game.character.name);
  }

  createCharacter() {
    const breeds = [
      {name: "AutoByConfig", value: -1},
      {name: "Random", value: -2},
      {name: "Feca", value: 1},
      {name: "Osamodas", value: 2},
      {name: "Enutrof", value: 3},
      {name: "Sram", value: 4},
      {name: "Xelor", value: 5},
      {name: "Ecaflip", value: 6},
      {name: "Eniripsa", value: 7},
      {name: "Iop", value: 8},
      {name: "Cra", value: 9},
      {name: "Sadida", value: 10},
      {name: "Sacrieur", value: 11},
      {name: "Pandawa", value: 12},
      {name: "Roublard", value: 13},
      {name: "Zobal", value: 14},
      {name: "Steamer", value: 15},
      {name: "Eliotrope", value: 16},
      {name: "Huppermage", value: 17},
      {name: "Ouginak", value: 18}
    ];
    const servers = this.bot.controller.type == "NEZU_TOUCH" ? [
      {name: "Ombre", value: 50},
      {name: "Herde", value: 290},
      {name: "Terra", value: 291},
      {name: "Orukam", value: 292},
      {name: "Tylezia", value: 293},
    ] : [
      {name: "Oshimo", value: 403},
      {name: "Terra Cogita", value: 404},
      {name: "Herdegrize", value: 405},
      {name: "Dodge", value: 406},
      {name: "Grandapan", value: 401},
      {name: "Brutas", value: 407}
    ];
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Create a new character",
        forms: [{
          label: "Name",
          name: "name",
          type: "string",
          required: true,
          defaultValue: "auto"
        }, {
          label: "Breed",
          name: "breed",
          type: "selector",
          required: true,
          possibleValues: breeds,
          defaultValue: -1
        }, {
          label: "Server",
          name: "serverIdentifier",
          type: "selector",
          required: true,
          possibleValues: servers
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.bot.game.authentication.connectAndCreate(result.serverIdentifier, result.breed, result.name);
      }
    });
  }

  createFromConfig() {
    this.bot.createFromConfig();
  }

  setState(state: string) {
    this.bot.setAccountState(state);
  }

  removeAndSave() {
    this.bot.controller.removeAndSaveBot(this.bot.login);
  }

  clickOnBot(event: MouseEvent) {
    if (this.userService.keys[67]) {
      this.bot.createFromConfig();
    }
    if (this.userService.keys[71]) {
      this.setState("GOOD");
    }
    if (this.userService.keys[76]) {
      this.setState("LOST");
    }
    if (this.userService.keys[83]) {
      this.removeAndSave();
    }
  }

  editComment() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Comment",
        forms: [{
          label: "Comment",
          name: "comment",
          type: "textarea",
          required: false,
          defaultValue: this.bot.config.comment
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.bot.config.editComment(result.comment);
      }
    });
  }
}
