import {Bot} from "..";

export class BotConfig {
  public bot: Bot;
  public configContent: string;
  public comment: string;

  constructor(bot: Bot, botConfig: BotConfig) {
    this.bot = bot;
    this.configContent = botConfig.configContent || "{}";
    this.comment = botConfig.comment;
    this.monitorPackets();
  }

  updateConfig(newConfig: string) {
    this.bot.send("BotConfigUpdateRequestMessage", {
      config: newConfig
    });
  }

  public editComment(comment: string) {
    this.bot.send("BotCommentUpdateRequestMessage", {comment});
  }


  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("BotConfigUpdatedMessage", (packet: any) => {
      this.configContent = packet.config;
    });
    wrapper.addMonitorPacket("BotCommentUpdatedMessage", (packet: any) => {
      this.comment = packet.comment;
    });
  }
}
