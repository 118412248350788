import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ControllerComponent} from "@dashboard/src/app/pages/controller/controller.component";
import {RouterModule} from "@angular/router";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSortModule} from "@angular/material/sort";
import {MatFormFieldModule} from "@angular/material/form-field";
import {BrowserModule} from "@angular/platform-browser";
import {MatSelectModule} from "@angular/material/select";
import {MonacoEditorModule} from "@materia-ui/ngx-monaco-editor";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {CustomConsoleModule} from "@dashboard/src/app/features/custom-console/custom-console.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ControllerResolver} from "@dashboard/src/app/pages/controller/controller.resolver";
import {ROUTES_INSTANCES_NEZU_PC} from "@dashboard/src/app/pages/controller/controller.routes";
import {ControllerConsoleComponent} from "./controller-console/controller-console.component";
import {ControllerConfigComponent} from "./controller-config/controller-config.component";
import {MatDividerModule} from "@angular/material/divider";


@NgModule({
  declarations: [
    ControllerComponent,
    ControllerConsoleComponent,
    ControllerConfigComponent
  ],
  exports: [
    ControllerComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES_INSTANCES_NEZU_PC),
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    BrowserModule,
    MatSelectModule,
    MonacoEditorModule,
    MatButtonModule,
    MatPaginatorModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    CustomConsoleModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule
  ],
  providers: [
    ControllerResolver
  ]
})
export class ControllerModule {
}
