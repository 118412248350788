import {Routes} from "@angular/router";
import {InstanceIsConnectedGuard} from "@dashboard/src/app/pages/controller/bot/InstanceIsConnectedGuard";
import {
  TeamConfigComponent
} from "@dashboard/src/app/pages/controller/team/team-content/team-config/team-config.component";
import {TeamComponent} from "@dashboard/src/app/pages/controller/team/team.component";
import {TeamResolver} from "@dashboard/src/app/pages/controller/team/team.resolver";


export const ROUTES_TEAM: Routes = [
  {
    path: "controller/:controllerId/team/:teamId",
    component: TeamComponent,
    canActivate: [
      InstanceIsConnectedGuard
    ],
    resolve: {
      team: TeamResolver
    },
    children: [
      {
        path: "",
        outlet: "tabs",
        component: TeamConfigComponent
      },
      {
        path: "config",
        outlet: "tabs",
        component: TeamConfigComponent
      }
    ]
  }
];
