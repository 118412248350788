import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {BotConfig} from "./config";
import {BotConsole} from "./console";
import {BotGame} from "./game";
import {BotNetwork} from "./network";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";
import {SimplePather} from "@dashboard/src/app/service/user/controllers/controller/bot/simplePather";
import {BotStats} from "@dashboard/src/app/service/user/controllers/controller/bot/stats";
import {BotFm} from "@dashboard/src/app/service/user/controllers/controller/bot/fm";

export class Bot extends CustomDispatcher {

  public controller: Controller;
  public controllerId: string;
  public alias: string;
  public login: string;
  public password: string;
  public game: BotGame;
  public stats: BotStats;
  public config: BotConfig;
  public console: BotConsole;
  public network: BotNetwork;
  public simplePather: SimplePather;
  public fm: BotFm;
  public teamId: string;
  public team: Team;
  public teamIndex = 0;

  public subscriptionEndDate: number;
  public subscriptionElapsedDuration: number;
  public accountCreation: number;
  public isBan: boolean;
  public accountState: string;

  public constructor(controller: Controller, bot: Bot = {} as Bot) {
    super("bot");
    this.controller = controller;
    this.controllerId = controller.id;
    this.alias = bot.alias;
    this.login = bot.login;
    this.password = bot.password;
    this.stats = new BotStats(this, bot.stats || {} as BotStats);
    this.config = new BotConfig(this, bot.config || {} as BotConfig);
    this.game = new BotGame(this, bot.game || {} as BotGame);
    this.console = new BotConsole(this, bot.console || {} as BotConsole);
    this.network = new BotNetwork(this, bot.network || {} as BotNetwork);
    this.simplePather = new SimplePather(this, bot.simplePather || {} as SimplePather);
    this.fm = new BotFm(this, bot.fm || {} as BotFm);
    this.teamId = bot.teamId;
    this.isBan = bot.isBan;
    this.teamIndex = bot.teamIndex || 0;
    if (this.teamId) this.team = this.controller.teams.find(elt => elt.id == this.teamId);
    this.accountCreation = bot.accountCreation;
    this.subscriptionElapsedDuration = bot.subscriptionElapsedDuration;
    this.subscriptionEndDate = bot.subscriptionEndDate;
    this.accountState = bot.accountState;
    this.monitorPackets();
  }

  public send(callName: string, data?: any) {
    this.controller.socket.send({
      callName,
      data,
      cible: "BOT",
      login: this.login
    }, "PacketWithCible");
  }

  createFromConfig() {
    this.send("CreateCharacterFromConfig", {});
  }

  setAccountState(accountState: string) {
    this.send("AccountStateRequestMessage", {
      accountState
    });
  }

  private monitorPackets() {
    const wrapper = this.wrap();
    wrapper.addMonitorPacket("BotBanStateUpdated", (packet: any) => {
      this.isBan = packet.isBan;
    });
    wrapper.addMonitorPacket("AccountStateUpdatedMessage", (packet: any) => {
      this.accountState = packet.accountState;
    });
  }
}
