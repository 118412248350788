<div class="stat-container">
  <div class="stat-header">
    <div class="stat stat-min">Min</div>
    <div class="stat stat-max">Max</div>
    <div class="stat stat-effects">Effects</div>
    <div class="stat stat-modifiers">Modif.</div>
    <div class="stat stat-value">Si</div>
    <div class="stat stat-value">Pa</div>
    <div class="stat stat-value">Ra</div>
  </div>

  <div *ngFor="let effect of effects" [style.background-color]="effect.backgroundcolor" class="stat-row">
    <div class="stat stat-min">{{ effect.min }}</div>
    <div class="stat stat-max">{{ effect.max }}</div>
    <div [ngClass]="{
        'stat-green': effect.value > 0,
        'stat-grey': effect.value == 0,
        'stat-blue':effect.isExtraEffect
    }" class="stat stat-effects">
      {{ effect.value + " " + effect.name + " ( ID = " + effect.id + " )" }}
    </div>
    <div class="stat stat-modifiers">{{ effect.modif }}</div>
    <div (click)="bot.fm.fmWithObject(effect.si?.objectUID)" class="stat stat-value">{{ effect.si?.quantity || 0 }}
    </div>
    <div (click)="bot.fm.fmWithObject(effect.pa?.objectUID)" class="stat stat-value">{{ effect.pa?.quantity || 0 }}
    </div>
    <div (click)="bot.fm.fmWithObject(effect.ra?.objectUID)" class="stat stat-value">{{ effect.ra?.quantity || 0 }}
    </div>
  </div>
</div>
