<div *ngIf="actualJob" class="job">
  <mat-progress-bar
    [mode]="'determinate'"
    [value]="actualJob['_pourcentXp']"
    class="progress-bar-stats"
    color="warn">
  </mat-progress-bar>
  <div class="value-box">
    {{actualJob["_name"] + " " + actualJob.jobLevel + " (" + actualJob["_pourcentXp"] + '%)'}}
  </div>
</div>
<div class="fm-exchange mat-elevation-z8">
  <div class="actual-item-fm actual-item">
    <img [src]="actualItemImg">
  </div>
  <div class="actual-item-rune actual-item">
    <img [src]="actualRuneImg">
  </div>
</div>
