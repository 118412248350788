import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {Utils} from "@common/lib/utils";

export class BotFm {
  public logs: {
    timestamp: number,
    magicPoolStatus: number,
    content: string,
    iconId: number,
    objectUID: number,
    modifs: {effectId: number, value:number}[]
  }[] = [];
  public actualItem: ObjectItem = null;
  public fmInProgress: boolean = false;
  private bot: Bot;

  public constructor(bot: Bot, stats: BotFm) {
    this.bot = bot;
    this.monitorPackets();
  }

  async fmWithObject(objectUID: number) {
    if (this.fmInProgress) return false;
    this.fmInProgress = true;
    return new Promise((resolve, reject) => {
      const wrapper = this.bot.wrap();
      const requestId = Utils.generateRandomUID();
      const timeout = setTimeout(() => {
        this.fmInProgress = false;
        wrapper.done();
        return resolve("TIMEOUT");
      }, 10000);
      wrapper.addMonitorPacket("FmWithObjectResult", (packet: any) => {
        Utils.sleep(300).then(() => {
          this.fmInProgress = false;
          wrapper.done();
          clearTimeout(timeout);
          return resolve(true);
        });
      });
      this.bot.send("FmWithObjectRequest", {
        requestId,
        objectUID
      });
    });
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("LogsFmUpdateMessage", (packet: any) => {
      this.logs = packet.logs;
    });
    wrapper.addMonitorPacket("LogFmAddedMessage", (packet: any) => {
      this.logs.push(packet.log);
      if (this.logs.length > 150) {
        this.logs = this.logs.slice(this.logs.length - 101, this.logs.length - 1);
      }
    });
    wrapper.addMonitorPacket("ActualItemFmUpdatedMessage", (packet: any) => {
      this.actualItem = packet.actualItem;
    }, 2000);
  }
}
