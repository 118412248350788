import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {MatMenuTrigger} from "@angular/material/menu";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {
  AddBotInstanceDialogComponent
} from "@dashboard/src/app/features/sidenav/controller-content/add-bot-instance-dialog/add-bot-instance-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: "app-team-solo-element",
  templateUrl: "./team-solo-element.component.html",
  styleUrls: ["./team-solo-element.component.scss"]
})
export class TeamSoloElementComponent implements OnInit {
  @Input() controller: Controller;
  public _open: boolean = false;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};

  constructor(
    public dialog: MatDialog,
    private clipboard: Clipboard,
    public userService: UserService
  ) {
  }

  public get LIST_IDS(): string[] {
    return ["solo", ...this.controller.teams.map(elt => elt.id)];
  }

  clickOnContextMenu(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  ngOnInit(): void {
  }

  dropBot(event: CdkDragDrop<Bot[]>) {
    if (event.previousContainer === event.container) {
      this.controller.moveBotInArray(event.previousIndex, event.currentIndex);
    } else {
      const teamSource = event.previousContainer.id;
      const teamCible = event.container.id;
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      this.controller.moveBotBetweenMultipleTeam(teamSource, teamCible, previousIndex, currentIndex);
    }
  }

  addBot() {
    this.dialog.open(AddBotInstanceDialogComponent, {
      width: "500px",
      data: {}
    }).afterClosed().pipe(
    ).subscribe(async (bot: any) => {
      if (bot) {
        this.controller.addBot(bot);
      }
    });
  }

  copyIds() {
    let str = "";
    for (const bot of this.controller.botsSolo) {
      str += bot.login + " " + bot.password + "\n";
    }
    this.clipboard.copy(str);
  }

  removeBots() {
    for (const bot of this.controller.botsSolo) {
      this.controller.removeBot(bot.login);
    }
  }
}

