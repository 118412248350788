export enum ChatServerTypeEnum {
    RECRUTEMENT = 6,
    COMMERCE = 5,
    GENERAL = 7,
    ERROR = 104,
    INFO = 103,
    WARN = 102,
    NORMAL = 101,
    DEBUG = 105
}
