import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {getBreedNameById} from "@protocol/enums/BreedEnum";

@Component({
  selector: 'app-bot-header',
  templateUrl: './bot-header.component.html',
  styleUrls: ['./bot-header.component.scss']
})
export class BotHeaderComponent implements OnInit {
  public bot: Bot;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.data.subscribe(data => {
      this.bot = data.bot;
    });
  }
  ngOnInit(): void {
  }

  protected readonly getBreedNameById = getBreedNameById;
}
