import {Component, OnInit} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {environment} from "@dashboard/src/environments/environment";

@Component({
  selector: "app-bot-fm-history",
  templateUrl: "./bot-fm-history.component.html",
  styleUrls: ["./bot-fm-history.component.scss"]
})
export class BotFmHistoryComponent implements OnInit {

  public bot: Bot;
  public pathImages = `${environment.HTTP_SERVER_ADDRESS}/images/items/`;

  constructor(private userService: UserService) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
  }

}
