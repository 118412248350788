<form [formGroup]="formGroup" >
  <h3 mat-dialog-title class="title">Add team</h3>

  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="actions">
    <button class="action-button" color="primary" mat-raised-button cdkFocusInitial (click)="submit()">Valid</button>
    <button class="action-button" color="primary" mat-dialog-close mat-raised-button (click)="close()">Cancel</button>
  </div>
</form>
