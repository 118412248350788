import {AfterViewInit, Component} from "@angular/core";
import {CanvasJS, CanvasJSAngularChartsModule} from "@canvasjs/angular-charts";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-kamas-by-date",
  standalone: true,
  imports: [
    CanvasJSAngularChartsModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  templateUrl: "./kamas-by-date.component.html",
  styleUrl: "./kamas-by-date.component.scss"
})
export class KamasByDateComponent implements AfterViewInit {
  chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    theme: "dark2",
    title: {
      text: "Kamas progression"
    },
    axisX: {
      valueFormatString: "DD/MM HH:mm"
    },
    axisY: {
      logarithmic: false, //change it to false
      title: "Quantity",
      titleFontColor: "#6D78AD",
      lineColor: "#6D78AD",
      gridThickness: 0,
      lineThickness: 1,
      labelFormatter: this.addSymbols
    },
    legend: {
      verticalAlign: "top",
      fontSize: 16,
      dockInsidePlotArea: true
    },
    data: [{
      type: "line",
      markerType: "circle",  // Type de marqueur (circle, square, triangle, etc.)
      markerSize: 8,         // Taille des marqueurs
      connectNullData: true,
      xValueType: "dateTime",
      xValueFormatString: "dd/MM HH:mm:ss",
      name: "Log Scale",
      dataPoints: [] as any[]
    }
    ],
    toolTip: {
      content: (e: any) => {
        const entryPoint = e.entries[0].dataPoint;
        // var content = "Date: " + e.entries[0].dataPoint.x.toLocaleDateString() + "<br/>Value: " + e.entries[0].dataPoint.y;
        // content += " unités"; // Texte supplémentaire
        return `${new Date(entryPoint.data.date).toLocaleTimeString()} ${entryPoint.data.comment} ${entryPoint.data.kamas} )`;
      }
    }
  };
  public bot: Bot;
  public form = new FormGroup({
    searchText: new FormControl("")
  });

  public chart: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.bot = userService.botSelected;
  }

  ngAfterViewInit() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("StatsUpdated", () => {
      console.log("StatsUpdated");
      this.updateData();
    });
    this.form.controls.searchText.valueChanges.subscribe(newValue => {
      console.log("test");
      this.updateData();
    });
  }

  onSearch() {

  }

  getChartInstance(chart: object) {
    this.chart = chart;
  }

  private addSymbols(e: any) {
    if (e.value == 0) {
      return CanvasJS.formatNumber(0, "#,##0.##");
    }
    var suffixes = ["", "K", "M", "B", "T"];

    var order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);
    if (order > suffixes.length - 1)
      order = suffixes.length - 1;

    var suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order), "#,##0.##") + suffix;
  }

  private async updateData() {
    let datas = this.bot.stats.datas;
    if (this.form.controls.searchText.value != "") {
      datas = datas.filter(data => {
        return data.comment.toLowerCase().indexOf(this.form.controls.searchText.value.toLowerCase()) != -1;
      });
    }
    const points: { x: number, y: number, data: any }[] = [];
    for (const data of datas) {
      points.push({
        x: new Date(data.date).getTime(),
        y: data.kamas,
        data: data
      });
    }
    console.log(points);
    this.chartOptions.data[0].dataPoints = points;
    this.chart.render();

  }
}
