import {AfterViewInit, Component} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {CanvasJS} from "@canvasjs/angular-charts";

@Component({
  selector: "app-bot-stats-graph",
  templateUrl: "./bot-stats-graph.component.html",
  styleUrls: ["./bot-stats-graph.component.scss"]
})
export class BotStatsGraphComponent  {
}
