import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import {ProxyServer} from "@dashboard/src/app/service/user/proxyServers/proxyServer";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {Utils} from "@common/lib/utils";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {MatDialog} from "@angular/material/dialog";
import {CustomProxy} from "@dashboard/src/app/service/user/proxyServers/proxyServer/customProxy";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-proxy-server",
  templateUrl: "./proxy-server.component.html",
  styleUrls: ["./proxy-server.component.scss"]
})
export class ProxyServerComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @Input() public proxyServer: ProxyServer;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns: string[] = ["url", "actualIp", "upTime", "interfaceName", "ssid", "wifiPassword", "operator", "state", "actions"];
  public dataSource: MatTableDataSource<CustomProxy>;

  constructor(public dialog: MatDialog, public userService: UserService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.proxyServer && changes.proxyServer.currentValue !== changes.proxyServer.previousValue) {
      this.updateData();
    }
  }

  addProxy() {

  }

  copyProxy(proxy: CustomProxy) {

  }

  autoDetect() {
    this.proxyServer.autoDetect();
  }

  autoConfig() {
    this.proxyServer.autoConfig();
  }

  ngAfterViewInit(): void {
    this.updateData();
    this.monitorPackets();
    this.sort.sort({
      disableClear: false,
      id: "ssid",
      start: "asc"
    });
    this.updateData();
  }

  sortData(sort: Sort) {
    const data = this.proxyServer.proxies.slice();
    if (!sort.active || sort.direction === "") {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        default:
          // @ts-ignore
          return Utils.compare(a[sort.active], b[sort.active], isAsc);
      }
    });
  }

  renameSSID(proxy: CustomProxy) {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Rename the ssid of this proxy",
        forms: [{
          label: "New ssid name",
          name: "name",
          type: "string",
          required: true,
          defaultValue: "4G-UFI-XX"
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        proxy.renameSSID(result.name);
      }
    });
  }

  changeWifiPassword(proxy: CustomProxy) {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Change Wifi Password",
        forms: [{
          label: "New password",
          name: "password",
          type: "string",
          required: true,
          defaultValue: "Mastermag00"
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        proxy.changeWifiPassword(result.password);
      }
    });
  }

  restartAll() {
    for (const proxy of this.proxyServer.proxies) {
      proxy.rebootProxy();
    }
  }

  checkAllIp() {
    for (const proxy of this.proxyServer.proxies) {
      proxy.checkIp();
    }
  }

  setProxyServer() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Edit proxy",
        forms: [{
          label: "Url of linkProxy",
          name: "name",
          type: "string",
          required: true,
          defaultValue: this.proxyServer.linkProxy
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result && result.name != this.proxyServer.linkProxy) {
        this.proxyServer.setLinkProxy(result.name);
      }
    });
  }

  updateIpAll() {
    for (const proxy of this.proxyServer.proxies) {
      proxy.updateIp();
    }
  }

  private monitorPackets() {
    const wrapper = this.userService.user.wrap();
    wrapper.addMonitorPacket("ProxiesUpdated", () => {
      this.updateData();
    });
  }

  private updateData() {
    this.dataSource = new MatTableDataSource<CustomProxy>(this.proxyServer.proxies);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sortData(this.sort);
  }
}
