import {GameContextCreateMessage} from "@protocol/messages/GameContextCreateMessage";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

export class GameCharacterState {
  private bot: Bot;
  public context: number = 0;
  public states: any[] = [];

  public constructor(bot: Bot, gameCharacterState: GameCharacterState) {
    this.bot = bot;
    this.states = gameCharacterState.states ? gameCharacterState.states : [];
    this.context = gameCharacterState.context ? gameCharacterState.context : 0;
    this.monitorPackets();
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("GameContextCreateMessage", (packet: GameContextCreateMessage) => {
      this.context = packet.context;
    });
  }
}
