import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ActivatedRoute} from "@angular/router";
import {UserConfig} from "@dashboard/src/app/service/user/config";
import {debounceTime, distinctUntilChanged} from "rxjs";


@Component({
  selector: "app-user-config",
  templateUrl: "./user-config.component.html",
  styleUrls: ["./user-config.component.scss"]
})
export class UserConfigComponent implements OnChanges {
  @Input() config: UserConfig;
  public formControlScript: UntypedFormControl;

  editorOptions = {theme: "vs-dark", language: "json"};

  constructor(private userService: UserService, private route: ActivatedRoute) {
  }


  ngOnChanges(changes: SimpleChanges) {
    this.formControlScript = new UntypedFormControl(this.config.configContent);
    this.formControlScript.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((newValue: string) => {
      if (this.config.configContent !== newValue) {
        try {
          JSON.parse(newValue);
          this.config.configContent = newValue;
          // this.userService.user.scripts.updateScript(this.script);
        } catch (e) {
          console.log(`Script ${this.config.configContent} not a valid json`);
        }
      }
    });
  }
}
