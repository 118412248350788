import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";

@Injectable({
  providedIn: "root"
})
export class BotResolver implements Resolve<Bot> {
  constructor(private userService: UserService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bot> {
    this.userService.componentSelected = ComponentTypeSelectedEnum.BOT;
    const controller = this.userService.user.controllers.controllers.find(
      elt => elt.id === route.params.controllerId);
    // @ts-ignore
    this.userService.controllerSelected = controller;
    this.userService.teamSelected = null;
    this.userService.botSelected = controller.bots.find(elt => elt.login == route.params.botId);
    this.userService.user.emit("ControllerSelectedUpdated");
    // @ts-ignore
    return of(this.userService.botSelected);
  }
}
