import {Component, Input} from "@angular/core";
import {CharacterCharacteristicDetailed} from "@protocol/types/CharacterCharacteristicDetailed";
import {
  getStatsNameById,
  StatIdsEnum
} from "@dashboard/src/app/service/user/controllers/controller/bot/game/character/stats/StatIdsEnum";

@Component({
  selector: 'app-view-characteristic-detailed',
  standalone: true,
  imports: [],
  templateUrl: './view-characteristic-detailed.component.html',
  styleUrl: './view-characteristic-detailed.component.scss'
})
export class ViewCharacteristicDetailedComponent {
  @Input()  public stat: CharacterCharacteristicDetailed;
  protected readonly JSON = JSON;
  protected readonly StatIdsEnum = StatIdsEnum;
  protected readonly getStatsNameById = getStatsNameById;
}
