<div (click)="clickOnBot($event)" [ngClass]="{'selected': userService.botSelected?.login === bot.login}"
     class="bot-infos">
  <!--  <div [style.background-color]="bot.isBan ? 'yellow' : bot.connected ? 'green':'red'"-->
  <!--       class="connected-state mat-elevation-z8"></div>-->
  <mat-icon *ngIf="bot.game.character.state.context == 2" class="bot-state-fight mat-elevation-z8">
    local_dining
  </mat-icon>
  <!--  <mat-icon *ngIf="bot.state == 'NOT_LOADED'" class="not-loaded mat-elevation-z8">hide_source</mat-icon>-->

  <mat-icon *ngIf="userService.controllerSelected.type == 'NEZU_TOUCH'"
            [style.color]="bot.network.instanceConnected? 'darkgreen' : 'darkred'"
            class="state-instance mat-elevation-z8"> android
  </mat-icon>

  <div *ngIf="!bot.isBan" [style.background-color]="bot.network.connected? 'green' : 'red'"
       class="state mat-elevation-z8"></div>
  <div *ngIf="bot.isBan" [style.background-color]="'yellow'" class="state mat-elevation-z8"></div>

  <mat-icon *ngIf="bot.accountState && bot.accountState != 'NONE'"
            [className]="'account-state account-state-' + bot.accountState"
            class=" mat-elevation-z8">
    {{ bot.accountState == 'GOOD' ? "thumb_up" : "thumb_down" }}
  </mat-icon>


  <mat-icon (click)="editComment()" *ngIf="bot.config.comment" class="comment mat-elevation-z8">
    email
  </mat-icon>


  <div class="level">
    Lvl {{ bot.game.character.level > 0 ? bot.game.character.level : '?' }}
  </div>
  <div class="position">
    {{
      bot.game.map.position + ' ' + (bot.game.map.subAreaName.length > 24 ?
        (bot.game.map.subAreaName | slice:0:24) + '..'
        : bot.game.map.subAreaName)
    }}
  </div>
  <div class="kamas">
    {{ (bot.game.character.inventory.kamas | number) }}
  </div>
  <button (click)="onContextMenu($event)" class="right-button" mat-button></button>
  <button (click)="selectThisBot()" (contextmenu)="onContextMenu($event)"
          class="select-bot"
          mat-button>
    {{ bot.game.character.name ? bot.game.character.name : bot.alias }}
  </button>
</div>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu>
  <ng-template let-item="item" matMenuContent>
    <button (click)="bot.game.authentication.connect()" mat-menu-item>Connect</button>
    <button (click)="bot.game.authentication.disconnect()" mat-menu-item>Disconnect</button>
    <mat-divider></mat-divider>
    <button (click)="copyPseudo()" mat-menu-item>Copier Pseudo</button>
    <button (click)="copyLoginPassword()" mat-menu-item>Copier Ids</button>
    <button (click)="editComment()" mat-menu-item>Edit Comment</button>
    <mat-divider></mat-divider>
    <button (click)="createCharacter()" mat-menu-item>Create Character</button>
    <button (click)="createFromConfig()" mat-menu-item>Create Character From Config</button>
    <mat-divider></mat-divider>
    <button (click)="remove()" class="action-dangerous" mat-menu-item>Remove</button>
    <button (click)="removeAndSave()" class="action-dangerous" mat-menu-item>Remove and Save</button>
    <mat-divider></mat-divider>
    <button (click)="setState('LOST')" mat-menu-item>Set LOST</button>
    <button (click)="setState('GOOD')" mat-menu-item>Set GOOD</button>
    <button (click)="setState('NONE')" mat-menu-item>Set NONE</button>
  </ng-template>
</mat-menu>
