import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {ScriptInformations} from '@common/lib/models/user/scripts/script';
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: 'app-script-element',
  templateUrl: './script-element.component.html',
  styleUrls: ['./script-element.component.scss']
})
export class ScriptElementComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: '0px', y: '0px'};
  public base: ['dir1', 'dir2', 'dir3'];
  @Input() public script: ScriptInformations;

  public linesCount = 0;

  constructor(public userService: UserService) {
  }


  ngOnInit(): void {
    this.linesCount = this.script.content !== '' ? (this.script.content.match(/\n/g) || '').length + 1 : 0;
  }

  onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }



  deleteScript() {

  }
}
