<div class="stat-container">
  <div class="stat-header">
    <div class="stat stat-effects">{{ item._name }}</div>
  </div>
  <div *ngFor="let effect of effects" class="stat-row">
    <div [ngClass]="{
        'stat-green': effect.name && effect.value > 0 && effect.operator == '+',
        'stat-red': effect.name && effect.value > 0 && effect.operator == '-',
        'stat-grey': effect.name && effect.value == 0,
        'stat-blue':!effect.name || effect.isExtraEffect
    }" class="stat stat-effects">
      {{ effect.text }}
    </div>
  </div>
</div>
