import {Bot} from "../..";
import {GameCharacterInventory} from "./inventory";
import {EntityLook} from "@common/lib/dofustouch/packets/EntityLook";
import {CharacterSelectedSuccessMessage} from "@protocol/messages/CharacterSelectedSuccessMessage";
import {GameCharacterStats} from "@dashboard/src/app/service/user/controllers/controller/bot/game/character/stats";
import {GameCharacterState} from "@dashboard/src/app/service/user/controllers/controller/bot/game/character/state";

export class GameCharacter {
  public id: number;
  public level: number = 0;
  public sex: boolean;
  public breed: number;
  public entityLook: EntityLook;
  public inventory: GameCharacterInventory;
  public stats: GameCharacterStats;
  public state: GameCharacterState;
  private bot: Bot;

  public constructor(bot: Bot, gameCharacter: GameCharacter) {
    this.bot = bot;
    this.level = gameCharacter.level;
    this.name = gameCharacter.name;
    this.breed = gameCharacter.breed;
    this.id = gameCharacter.id;
    this.inventory = new GameCharacterInventory(bot, gameCharacter.inventory || {} as GameCharacterInventory);
    this.stats = new GameCharacterStats(bot, gameCharacter.stats || {} as GameCharacterStats);
    this.state = new GameCharacterState(bot, gameCharacter.state || {} as GameCharacterState);
    this.entityLook = gameCharacter.entityLook;
    this.monitorPackets();
  }

  public _name: string;

  public get name() {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }



  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("LevelUpdated", (packet: any) => {
      this.level = packet.level;
    });
    wrapper.addMonitorPacket("CharacterSelectedSuccessMessage", (packet: CharacterSelectedSuccessMessage) => {
      this.id = packet.infos.id;
      this.name = packet.infos.name;
      this.level = packet.infos.level;
      this.sex = packet.infos.sex;
      this.breed = packet.infos.breed;
      this.entityLook = packet.infos.entityLook;
    });
  }
}
