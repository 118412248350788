import {AfterViewInit, Component} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {CanvasJS, CanvasJSAngularChartsModule} from "@canvasjs/angular-charts";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";

@Component({
  selector: "app-kamas-by-hour",
  standalone: true,
  imports: [
    CanvasJSAngularChartsModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  templateUrl: "./kamas-by-hour.component.html",
  styleUrl: "./kamas-by-hour.component.scss"
})
export class KamasByHourComponent implements AfterViewInit {

  chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    theme: "dark2",
    title: {
      text: "Hourly Kamas Gain"
    },
    axisX: {
      valueFormatString: "DD/MM HH:mm"
    },
    axisY: {
      logarithmic: false, //change it to false
      title: "Kamas/hour",
      titleFontColor: "#6D78AD",
      lineColor: "#6D78AD",
      gridThickness: 0,
      lineThickness: 1,
      labelFormatter: this.addSymbols
    },
    legend: {
      verticalAlign: "top",
      fontSize: 16,
      dockInsidePlotArea: true
    },
    data: [{
      type: "line",
      markerType: "circle",
      markerSize: 8,
      connectNullData: true,
      xValueType: "dateTime",
      xValueFormatString: "DD/MM HH:mm:ss",
      name: "Hourly Gain",
      dataPoints: [] as any[]
    }],
    toolTip: {
      content: (e: any) => {
        const entryPoint = e.entries[0].dataPoint;
        const hourlyWin = Math.floor(entryPoint.y / 10000) / 100;

        return `${new Date(entryPoint.x).toLocaleTimeString()} ${hourlyWin} M/hour ${entryPoint.data.comment}`;
      }
    }
  };


  public bot: Bot;
  public form = new FormGroup({
    searchText: new FormControl("")
  });

  public chart: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.bot = userService.botSelected;
  }

  ngAfterViewInit() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("StatsUpdated", () => {
      console.log("StatsUpdated");
      this.updateData();
    });
    this.form.controls.searchText.valueChanges.subscribe(newValue => {
      console.log("test");
      this.updateData();
    });
  }

  onSearch() {

  }

  getChartInstance(chart: object) {
    this.chart = chart;
  }

  applyMovingAverage(hourlyPoints: { x: number, y: number, data: any }[], windowSize: number) {
    const smoothedHourlyDataPoints: { x: number, y: number, data: any }[] = [];
    for (let i = 0; i < hourlyPoints.length; i++) {
      let sum = 0;
      let count = 0;

      for (let j = i; j < i + windowSize && j < hourlyPoints.length; j++) {
        sum += hourlyPoints[j].y;
        count++;
      }

      smoothedHourlyDataPoints.push({
        x: hourlyPoints[i].x,
        y: sum / count,
        data: hourlyPoints[i].data
      });
    }
    return smoothedHourlyDataPoints;
  }

  private addSymbols(e: any) {
    if (e.value == 0) {
      return CanvasJS.formatNumber(0, "#,##0.##");
    }
    var suffixes = ["", "K", "M", "B", "T"];

    var order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);
    if (order > suffixes.length - 1)
      order = suffixes.length - 1;

    var suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order), "#,##0.##") + suffix;
  }

  private async updateData() {

    let datas = this.bot.stats.datas;
    if (this.form.controls.searchText.value != "") {
      datas = datas.filter(data => {
        return data.comment.toLowerCase().indexOf(this.form.controls.searchText.value.toLowerCase()) != -1;
      });
    }
    const points: { x: number, y: number, data: any }[] = [];
    for (let i = 1; i < datas.length; i++) {
      const previousPoint = datas[i - 1];
      const currentPoint = datas[i];
      const timeDiff = (new Date(currentPoint.date).getTime() - new Date(previousPoint.date).getTime()) / (1000 * 60 * 60); // Différence en heures
      const kamasDiff = currentPoint.kamas - previousPoint.kamas;
      const hourlyGain = kamasDiff / timeDiff;
      if (hourlyGain < -1000000) {
        console.log("test");
      }
      points.push({x: new Date(currentPoint.date).getTime(), y: hourlyGain, data: datas[i]});
    }
    console.log(points);
    const smoothedHourlyDataPoints = this.applyMovingAverage(points, 5);


    this.chartOptions.data[0].dataPoints = smoothedHourlyDataPoints;
    this.chart.render();

  }

}
