import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {MatTableDataSource} from "@angular/material/table";
import {environment} from "@dashboard/src/environments/environment";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-bot-fm-inventory",
  templateUrl: "./bot-fm-inventory.component.html",
  styleUrls: ["./bot-fm-inventory.component.scss"]
})
export class BotFmInventoryComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public bot: Bot;
  public displayedColumns: string[] = ["image", "name", "quantity"];
  public dataSource: MatTableDataSource<any>;
  public pathImages = `${environment.HTTP_SERVER_ADDRESS}/images/items/`;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.bot = userService.botSelected;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.bot.game.character.inventory.getObjectsByTypeId(78));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("InventoryUpdated", () => {
      this.updateData();
    });
  }

  private async updateData() {
    this.dataSource.data = this.bot.game.character.inventory.getObjectsByTypeId(78);
    this.dataSource.paginator = this.paginator;
  }
}
