export enum BreedEnum {
    UNDEFINED = 0,
    Feca = 1,
    Osamodas = 2,
    Enutrof = 3,
    Sram = 4,
    Xelor = 5,
    Ecaflip = 6,
    Eniripsa = 7,
    Iop = 8,
    Cra = 9,
    Sadida = 10,
    Sacrieur = 11,
    Pandawa = 12,
    Roublard = 13,
    Zobal = 14,
    Steamer = 15,
    Eliotrope = 16,
    Huppermage = 17,
    Ouginak = 18,
    Forgelance = 20,
    SUMMONED = -1,
    MONSTER = -2,
    MONSTER_GROUP = -3,
    NPC = -4,
    TAX_COLLECTOR = -5,
    MUTANT = -6,
    MUTANT_IN_DUNGEON = -7,
    MOUNT_OUTSIDE = -8,
    PRISM = -9,
    INCARNATION = -10
}
