import {Component, ViewChild} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormControl, FormGroup} from "@angular/forms";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-bot-bank",
  templateUrl: "./bot-bank.component.html",
  styleUrl: "./bot-bank.component.scss"
})
export class BotBankComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public bot: Bot;
  public displayedColumns: string[] = ["image", "quantity", "name", "gid"];
  public dataSource: MatTableDataSource<any>;
  public pathImages = `http://server.myeasydev.be:7998/images/items/`;
  public formGroupSearch = new FormGroup({
    name: new FormControl()
  });
  public items: ObjectItem[] = [];
  protected readonly JSON = JSON;
  private userWrapper: CustomWrapper;
  private botWrapper: CustomWrapper;


  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.userWrapper = userService.user.wrap();
    this.bot = userService.botSelected;
    this.formGroupSearch.controls.name.valueChanges.subscribe(change => {
      this.updateData();
    });
    this.userWrapper.addMonitorPacket(["BotSelectedUpdated", "BotBankUpdated"], () => {
      this.updateData();
    });
  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.bot.game.exchange.itemsBank);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("BotBankUpdated", () => {
      this.updateData();
    });
  }

  ngOnDestroy(): void {
    this.userWrapper.done();
  }

  getTooltipContent(element: ObjectItem) {
    return `
      <div>
        <strong>Nom:</strong> 0 <br>
        <strong>Puissance:</strong> 1 <br>
        <!-- Autres caractéristiques de l'objet -->
      </div>
    `;
  }

  private updateData() {
    const searchText = this.formGroupSearch.controls.name.value;
    const items = searchText?.length > 0 ?
      this.bot.game.exchange.itemsBank?.filter((elt: any) => elt._name.toLowerCase().indexOf(searchText.toLowerCase()) != -1)
      : this.bot.game.exchange.itemsBank|| [];
    this.dataSource.data = items;
    this.dataSource.paginator = this.paginator;
  }

}
