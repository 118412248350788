import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {ControllerGroupSchema} from "@dashboard/src/app/service/user/controllers/ControllerSchema";
import {CdkDragDrop} from "@angular/cdk/drag-drop";

@Component({
  selector: "app-group-controller-element",
  templateUrl: "./group-controller-element.component.html",
  styleUrls: ["./group-controller-element.component.scss"]
})
export class GroupControllerElementComponent implements OnInit {
  @Input() schema: ControllerGroupSchema;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};
  public isOpen: boolean = false;

  constructor(public userService: UserService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  clickOnContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    // this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }


  rename() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Rename the directory",
        forms: [{
          label: "Name of the directory",
          name: "name",
          type: "string",
          required: true,
          defaultValue: this.schema.name
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.schema.name = result.name;
        this.userService.user.controllers.sendUpdateSchema();
      }
    });
  }


  dropElement(event: CdkDragDrop<any[]>) {
    const elements = this.schema.elements;
    elements.splice(event.currentIndex, 0, elements.splice(event.previousIndex, 1)[0]);
    this.userService.user.controllers.sendUpdateSchema();
  }

  remove() {
    this.userService.user.controllers.schema.removeGroupByName(this.schema.name);
  }
}
