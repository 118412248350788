import {Bot} from "../..";
import {GameMapActors} from "@dashboard/src/app/service/user/controllers/controller/bot/game/map/actors";
import {InteractiveElement} from "@protocol/types/InteractiveElement";
import {MapObstacle} from "@protocol/types/MapObstacle";

export class GameMap {
  public actors: GameMapActors;
  public interactiveElements: InteractiveElement[];
  public mapData: any;
  public mapId: number = 0;
  public position: string;
  public area: string;
  public subAreaId: number = 0;
  public subAreaName: string = "?";
  public worldId: number = 0;
  public worldName: string = "?";
  public mapName: string = "?";
  public areaId: number = 0;
  public obstacles: MapObstacle[] = [];
  private bot: Bot;

  public constructor(bot: Bot, gameMap: GameMap) {
    this.bot = bot;
    this.mapId = gameMap.mapId;
    this.position = gameMap.position || "[]";
    this.area = gameMap.area || "?";
    this.subAreaId = gameMap.subAreaId || -1;
    this.areaId = gameMap.areaId || -1;
    this.subAreaName = gameMap.subAreaName || "";
    this.worldId = gameMap.worldId || -1;
    this.worldName = gameMap.worldName || "?";
    this.mapName = gameMap.mapName || "?";
    this.actors = new GameMapActors(this.bot, gameMap.actors || {} as GameMapActors);
    this.monitorPackets();
  }

  moveToCell(cellId: number) {
    this.bot.send("MoveToCellRequestMessage", {
      cellId
    });
  }

  changeMap(direction: string, cellId: number) {
    this.bot.send("ChangeMapRequestMessage", {
      direction,
      cellId
    });
  }

  moveAndUseElementOnCellId(cellId: number) {
    this.bot.send("MoveAndUseElementOnCellId", {
      cellId
    });
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("MapPositionUpdated", (packet: any) => {
      this.mapId = packet.mapId;
      this.position = packet.position;
      this.area = packet.area;
      this.subAreaId = packet.subAreaId;
      this.subAreaName = packet.subAreaName;
      this.worldId = packet.worldId;
      this.worldName = packet.worldName;
      this.mapName = packet.mapName;
    });
    wrapper.addMonitorPacket("MapDataInformationsMessage", (packet: any) => {
      this.mapData = packet.mapData;
      this.obstacles = packet.obstacles || [];
      this.actors.entities = packet.entities;
      this.interactiveElements = packet.interactiveElements;
      this.bot.emit("MapDataUpdated");
    });
  }
}
