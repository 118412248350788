// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // WS_SERVER_ADDRESS: "ws://192.168.0.119:7999",
  // HTTP_SERVER_ADDRESS: "http://192.168.0.119:7998"

  WS_SERVER_ADDRESS: "ws://185.41.152.136:7999",
  HTTP_SERVER_ADDRESS: "http://185.41.152.136:7998",
  token: ""
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
