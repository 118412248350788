import {NezuUser} from "@dashboard/src/app/service/user";
import {ProxyServer} from "@dashboard/src/app/service/user/proxyServers/proxyServer";

export class ProxyServersManager {
  public user: NezuUser;
  public proxyServers: ProxyServer[] = [];


  constructor(proxyServers: ProxyServersManager, user: NezuUser) {
    this.user = user;
    for (const proxyServer of proxyServers.proxyServers || []) {
      this.proxyServers.push(new ProxyServer(proxyServer, this.user));
    }
    this.monitorPackets();
  }


  private monitorPackets() {

  }
}
