import {DirectionCellEnum} from "@dashboard/src/app/utils/DirectionCellEnum";

export class MapPoint {
  public static cells: MapPoint[];
  public x: number;
  public y: number;
  public cellId: number;

  public static MAP_WIDTH = 14;

  public static MAP_HEIGHT = 20;
  static INVALID_CELL_ID: string = null;

  constructor(cellId: number, x: number, y: number) {
    this.cellId = cellId;
    this.x = x;
    this.y = y;
  }

  public static init() {
    this.cells = Array(560).fill(null);
    for (let i = 0; i < 560; i++) {
      const row = (i % 14) - ~~(i / 28);
      const x = row + 19;
      const y = row + ~~(i / 14);
      this.cells[i] = new MapPoint(i, x, y);
    }
  }

  public static getMapPointByDirection(myMapPoint: MapPoint, direction: DirectionCellEnum, distance: number = 1) {
    direction = direction > 7 ? direction % 7 - 1 : direction;
    switch (direction) {
      case DirectionCellEnum.RIGHT:
        return MapPoint.fromCoords(myMapPoint.x + distance, myMapPoint.y + distance);
      case DirectionCellEnum.BOTTOM:
        return MapPoint.fromCoords(myMapPoint.x - distance, myMapPoint.y + distance);
      case DirectionCellEnum.LEFT:
        return MapPoint.fromCoords(myMapPoint.x - distance, myMapPoint.y - distance);
      case DirectionCellEnum.TOP:
        return MapPoint.fromCoords(myMapPoint.x + distance, myMapPoint.y - distance);
      case DirectionCellEnum.BOTTOM_RIGHT:
        return MapPoint.fromCoords(myMapPoint.x, myMapPoint.y + distance);
      case DirectionCellEnum.TOP_RIGHT:
        return MapPoint.fromCoords(myMapPoint.x + distance, myMapPoint.y);
      case DirectionCellEnum.BOTTOM_LEFT:
        return MapPoint.fromCoords(myMapPoint.x - distance, myMapPoint.y);
      case DirectionCellEnum.TOP_LEFT:
        return MapPoint.fromCoords(myMapPoint.x, myMapPoint.y - distance);
    }
  }

  public static fromCellId(cellId: number): MapPoint {
    return this.cells[cellId];
  }

  public static fromCoords(x: number, y: number): MapPoint {
    const cell = this.cells.find(c => c.x === x && c.y === y);
    return cell === undefined ? null : cell;
  }

  public static getNeighbourCells(
    cellId: number,
    allowDiagonal: boolean
  ): MapPoint[] {
    const neighbours: Array<MapPoint | null> = [];
    const coord = this.fromCellId(cellId);
    if (!coord) {
      return neighbours.filter((elt) => elt !== null && elt !== undefined);
    }

    neighbours.push(this.fromCoords(coord.x, coord.y + 1));
    neighbours.push(this.fromCoords(coord.x - 1, coord.y));
    neighbours.push(this.fromCoords(coord.x, coord.y - 1));
    neighbours.push(this.fromCoords(coord.x + 1, coord.y));

    if (allowDiagonal) {
      neighbours.push(this.fromCoords(coord.x + 1, coord.y + 1));
      neighbours.push(this.fromCoords(coord.x - 1, coord.y + 1));
      neighbours.push(this.fromCoords(coord.x - 1, coord.y - 1));
      neighbours.push(this.fromCoords(coord.x + 1, coord.y - 1));
    }

    return neighbours.filter((elt) => elt !== null && elt !== undefined);
  }

  public static getDiagonalCells(cellId: number): MapPoint[] {
    const neighbours: Array<MapPoint | null> = [];
    const coord = this.fromCellId(cellId);
    if (!coord) {
      return neighbours.filter((elt) => elt !== null && elt !== undefined);
    }
    neighbours.push(this.fromCoords(coord.x + 1, coord.y + 1));
    neighbours.push(this.fromCoords(coord.x - 1, coord.y + 1));
    neighbours.push(this.fromCoords(coord.x - 1, coord.y - 1));
    neighbours.push(this.fromCoords(coord.x + 1, coord.y - 1));

    return neighbours.filter((elt) => elt !== null && elt !== undefined);
  }

  public getMapPointByDirection(direction: DirectionCellEnum, distance: number = 1) {
    return MapPoint.getMapPointByDirection(this, direction, distance);
  }

  public isEqual(mapPoint: MapPoint) {
    return mapPoint && mapPoint.x === this.x && mapPoint.y === this.y;
  }

  public distanceTo(destination: MapPoint): number {
    return Math.round(
      Math.sqrt(
        Math.pow(this.x - destination.x, 2) +
        Math.pow(this.y - destination.y, 2)
      )
    );
  }

  public distanceToCell(destination: MapPoint): number {
    return Math.abs(this.x - destination.x) + Math.abs(this.y - destination.y);
  }

  static isInMap(x: number, y: number): boolean {
    return !!MapPoint.fromCoords(x, y);
  }

  static getDistance(startCellId: number, endCellId: number) {
    let num = Math.floor(startCellId / 14.0);
    let num2 = Math.floor((num + 1) / 2.0);
    let num3 = startCellId - num * 14;
    let num4 = num2 + num3;
    let num5 = Math.floor(startCellId / 14.0);
    let num6 = Math.floor((num5 + 1) / 2.0);
    let num7 = num5 - num6;
    let num8 = startCellId - num5 * 14;
    let num9 = num8 - num7;
    let num10 = Math.floor(endCellId / 14.0);
    let num11 = Math.floor((num10 + 1) / 2.0);
    let num12 = endCellId - num10 * 14;
    let num13 = num11 + num12;
    let num14 = Math.floor(endCellId / 14.0);
    let num15 = Math.floor((num14 + 1) / 2.0);
    let num16 = num14 - num15;
    let num17 = endCellId - num14 * 14;
    let num18 = num17 - num16;
    return Math.floor(Math.abs(num13 - num4) + Math.abs(num18 - num9));
  }

  static getCellIdByCoord(x: number, y: number) {
    const mapPoint = MapPoint.fromCoords(x, y);
    return mapPoint ? mapPoint.cellId : null;
  }

  static isValidCoord(param1: number, param2: number) {
    return this.isInMap(param1, param2);
  }

  static getCellIdXCoord(cellId: number) {
    return MapPoint.fromCellId(cellId).x;
  }

  static getCellIdYCoord(cellId: number) {
    return MapPoint.fromCellId(cellId).y;
  }

  static getLookDirection8Exact(param1: number, param2: number) {
    return MapPoint.getLookDirection8ExactByCoord(MapPoint.getCellCoordById(param1), MapPoint.getCellCoordById(param2));
  }


  public static getCellCoordById(cellId: number): MapPoint {
    return MapPoint.fromCellId(cellId);
  }

  private static getLookDirection8ExactByCoord(param1: MapPoint, param2: MapPoint) {
    let num = MapPoint.getLookDirection4ExactByCoord(param1, param2);
    const flag: boolean = !MapPoint.isValidDirection(num);
    if (flag) {
      num = MapPoint.getLookDirection4DiagExactByCoord(param1, param2);
    }
    return num;
  }

  private static getLookDirection4ExactByCoord(param1: MapPoint, param2: MapPoint) {
    const flag: boolean = param1 == null || param2 == null || !MapPoint.isValidCoord(param1.x, param1.y) || !MapPoint.isValidCoord(param2.x, param2.y);
    let result;
    if (flag) {
      result = -1;
    } else {
      const num = param2.x - param1.x;
      const num2 = param2.y - param1.y;
      const flag2: boolean = num2 == 0;
      if (flag2) {
        const flag3: boolean = num < 0;
        if (flag3) {
          result = 5;
        } else {
          result = 1;
        }
      } else {
        const flag4: boolean = num == 0;
        if (flag4) {
          const flag5: boolean = num2 < 0;
          if (flag5) {
            result = 3;
          } else {
            result = 7;
          }
        } else {
          result = -1;
        }
      }
    }
    return result;
  }

  private static isValidDirection(param1: any) {
    return param1 > -1 && param1 < 8;
  }

  private static getLookDirection4DiagExactByCoord(param1: MapPoint, param2: MapPoint) {
    const flag: boolean = param1 == null || param2 == null || !MapPoint.isValidCoord(param1.x, param1.y) || !MapPoint.isValidCoord(param2.x, param2.y);
    let result: number;
    if (flag) {
      result = -1;
    } else {
      const num = param2.x - param1.x;
      const num2 = param2.y - param1.y;
      const flag2: boolean = num == -num2;
      if (flag2) {
        const flag3: boolean = num < 0;
        if (flag3) {
          result = 6;
        } else {
          result = 2;
        }
      } else {
        const flag4: boolean = num == num2;
        if (flag4) {
          const flag5: boolean = num < 0;
          if (flag5) {
            result = 4;
          } else {
            result = 0;
          }
        } else {
          result = -1;
        }
      }
    }
    return result;
  }

  public orientationTo(point: MapPoint) {
    const num = (point.x > this.x) ? 1 : ((point.x < this.x) ? -1 : 0);
    const num2 = (point.x > this.x) ? 1 : ((point.x < this.x) ? -1 : 0);
    const flag = num == 1 && num2 == 1;
    let result;
    if (flag) {
      result = 0;
    } else {
      if (num == 1 && num2 == 0) {
        result = 1;
      } else {
        if (num == 1 && num2 == -1) {
          result = 2;
        } else {
          if (num == 0 && num2 == -1) {
            result = 3;
          } else {
            if (num == -1 && num2 == -1) {
              result = 4;
            } else {
              if (num == -1 && num2 == 0) {
                result = 5;
              } else {
                if (num == -1 && num2 == 1) {
                  result = 6;
                } else {
                  if (num == 0 && num2 == 1) {
                    result = 7;
                  } else {
                    result = -1;
                  }
                }
              }
            }
          }
        }
      }
    }
    return result;
  }

  static orientationTo(origin: number, target: number) {
    const prev = MapPoint.fromCellId(origin);
    const coord = MapPoint.fromCellId(target);
    if (coord.y === prev.y) {
      // move horizontaly
      return coord.x > prev.x ? 7 : 3;
    } else if (coord.x === prev.x) {
      // move verticaly
      return coord.y > prev.y ? 1 : 5;
    } else {
      // move in diagonal
      if (coord.x > prev.x) {
        return coord.y > prev.y ? 0 : 6;
      } else {
        return coord.y > prev.y ? 2 : 4;
      }
    }
  }
}
