<form [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'scripts.addScriptName' | translate }}</mat-label>
    <input formControlName="scriptName" matInput placeholder="{{ 'scripts.addScriptName' | translate }}">
  </mat-form-field>
  <div class="actions">
    <button (click)="cancel()" class="action-button" color="primary" mat-raised-button>
      {{ 'other.cancel' | translate }}
    </button>
    <button (click)="confirm()" class="action-button" color="primary" mat-raised-button
            type="submit">
      {{ 'other.confirm' | translate }}
    </button>
  </div>
</form>
