<div class="history mat-elevation-z8">
  <div #listLog class="list-log">
    <div *ngFor="let log of bot.fm.logs"
         [className]="'history-element-state-'+ log.magicPoolStatus +' history-element'">
      <!--          <span>{{'[' + (log.timestamp * 1000 | date:'hh:mm:ss.SSS':'fr') + '] '}}</span>-->
      <img [src]="pathImages + log.iconId + '.png'">
      {{ log.content }}
    </div>
  </div>
</div>
