<div class="packets-reader">
  <div class="actions">
    <button (click)="clearPackets()" color="warn" mat-raised-button>Clear</button>
    <mat-slide-toggle [formControl]="formListen" color="warn">Enable listen packets</mat-slide-toggle>
  </div>

  <div class="list-packets">
    <app-bot-network-packet-line (click)="packetSelected = packet"
                                 *ngFor="let packet of bot.network.packets"
                                 [packet]="packet"
                                 [selected]="packetSelected == packet"></app-bot-network-packet-line>

  </div>
  <div class="packet-content">
    <ngx-json-viewer [json]="packetSelected.data"></ngx-json-viewer>
  </div>
</div>
