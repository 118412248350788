import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

@Component({
  selector: "app-instance",
  templateUrl: "./controller.component.html",
  styleUrls: ["./controller.component.scss"]
})
export class ControllerComponent implements OnInit {
  public instance: Controller;
  public activeLink = "console";

  public tabs = [
    {label: "Console", link: "console"},
    {label: "Config", link: "config"}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.data.subscribe(data => {
      this.instance = data.instance;
    });
  }

  ngOnInit(): void {
    const childrenTabs = this.route.children.find(elt => elt.outlet == "tabs");
    if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
      this.activeLink = childrenTabs.snapshot.url[0].path;
    } else {
      this.activeLink = this.tabs[0].link;
    }
  }

  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {tabs: path}}], {relativeTo: this.route});
  }

}
