/* tslint:disable */
import {EventEmitter} from "events";
import Debug from "debug";
import {Utils} from "./index";

let error = Debug(`app:error`);

export class CustomDispatcher {
    public functions: any;
    public listPackets: { data: any, callName: string, packet: any }[] = [];
    private localEventEmitter: EventEmitter = new EventEmitter();
    private nameLog: string;
    private _isDone = false;

    constructor(nameLog: string) {
        this.functions = {};
        this.nameLog = nameLog;
        error = nameLog ? Debug(`app:${nameLog}:error`) : error;
        this.unstackFunction();
    }

    public isDone() {
        return this._isDone;
    }

    public wrap() {
        return new CustomWrapper(this);
    }

    public done() {
        this.functions = {};
        this.listPackets = [];
        this.localEventEmitter.removeAllListeners();
        this._isDone = true;
    }

    public addMonitorPacket(packets: string | string[], callFunction: Function, priority: number = 1000): string {
        packets = Array.isArray(packets) ? packets : [packets];
        const id = Utils.generateRandomUID();
        for (let packetName of packets) {
            packetName = packetName.replace("packet::", "");
            if (this.functions[packetName] === null || this.functions[packetName] === undefined) {
                this.functions[packetName] = [];
            }
            this.functions[packetName].push({callFunction, priority, id});
            this.functions[packetName].sort(function (a: any, b: any) {
                return a.priority - b.priority;
            });
        }
        return id;
    }

    public removeListenerPacket(packets: string | string[], id: string) {
        packets = Array.isArray(packets) ? packets : [packets];
        for (let packetName of packets) {
            packetName = packetName.replace("packet::", "");
            if (this.functions[packetName] !== null
                && this.functions[packetName] !== undefined) {
                this.functions[packetName] = this.functions[packetName].filter((elt: any) => elt.id !== id);
            }
        }
    }

    public emit(callName: string, data: any = null, packet: any = null) {
        callName = callName.replace("packet::", "");
        this.listPackets.push({callName, data, packet});
        this.localEventEmitter.emit("queueUpdated");
    }

    public async forceCallEventFunctions(callName: string, data: any, packet?: any) {
        if (this.functions[callName]) {
            if (this.functions[callName].length > 100) {
                console.log("Lot of function on this callName : " + callName);
            }
            for (const func of this.functions[callName]) {
                try {
                    await func.callFunction(data, packet);
                } catch (e: any) {
                    error(`[ERROR] impossible to execute a function with callName: ${callName} : \n[${e.stack}]`);
                }
            }
        }
    }

    private async waitNextPaquet(): Promise<{ callName: string, data: any, packet: any }> {
        return new Promise<{ callName: string, data: any, packet: any }>((resolve, reject) => {
            if (this.listPackets.length > 0) {
                return resolve(this.listPackets.shift());
            }
            this.localEventEmitter.on("queueUpdated", () => {
                this.localEventEmitter.removeAllListeners("queueUpdated");
                return resolve(this.listPackets.shift());
            });
        });
    }

    private async unstackFunction() {
        while (!this._isDone) {
            const packet = await this.waitNextPaquet();
            if (this.functions[packet.callName]) {
                for (const func of this.functions[packet.callName]) {
                    try {
                        await func.callFunction(packet.data, packet.packet);
                    } catch (e: any) {
                        error(`[ERROR] impossible to execute a function with callName: ${packet.callName} : \n[${e.stack}]`);
                    }
                }
            }
        }
    }
}


export class CustomWrapper {
    private dispatcher: CustomDispatcher;
    private listeners: { id: string, packets: string[] }[] = [];
    private _done = false;

    constructor(dispatcher: CustomDispatcher) {
        this.dispatcher = dispatcher;
    }

    public addMonitorPacket(packets: string | string[], callFunction: Function, priority: number = 1000): string {
        packets = Array.isArray(packets) ? packets : [packets];
        const id = this.dispatcher.addMonitorPacket(packets, callFunction, priority);
        this.listeners.push({id, packets});
        return id;
    }

    public done() {
        if (this.listeners) {
            for (const listener of this.listeners) {
                this.dispatcher.removeListenerPacket(listener.packets, listener.id);
            }
        }
        this.listeners = null;
        this.dispatcher = null;
        this._done = true;
    }

    public isDone(): boolean {
        return this._done;
    }
}
