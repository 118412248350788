<mat-card>
  <mat-card-title>
    <img class="nezu-icon" src="../../../assets/img/common/nezu.png"/>
  </mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="connect()" [formGroup]="form">
      <p>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'login.login' | translate }}</mat-label>
          <input formControlName="login" matInput type="text">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'login.password' | translate }}</mat-label>
          <input formControlName="password" matInput type="password">
        </mat-form-field>
      </p>
      <p *ngIf="error" class="error">
        {{ 'login.error.' + error | translate }}
      </p>
      <p *ngIf="userService.socket.reason" class="error">
        {{ 'login.error.' + userService.socket.reason | translate }}
      </p>
      <div class="button">
        <button color="primary" mat-raised-button type="submit">{{ 'login.connect' | translate }}</button>
      </div>
      <p class="version">Version {{version}}</p>
    </form>
  </mat-card-content>
</mat-card>
