import {Routes} from "@angular/router";
import {UserComponent} from "@dashboard/src/app/pages/user/user.component";
import {IsSignedInGuard} from "@dashboard/src/app/security/IsSignedInGuard";
import {UserResolver} from "@dashboard/src/app/pages/user/user.resolver";
import {ScriptsComponent} from "@dashboard/src/app/pages/user/scripts/scripts.component";
import {ProxiesComponent} from "@dashboard/src/app/pages/user/proxies/proxies.component";
import {ProxyServersComponent} from "@dashboard/src/app/pages/user/proxy-servers/proxy-servers.component";
import {UserConfigComponent} from "@dashboard/src/app/pages/user/user-config/user-config.component";
import {TDMViewerComponent} from "@dashboard/src/app/pages/user/tdmviewer/tdmviewer.component";


export const ROUTES_USER: Routes = [
  {
    path: "user",
    component: UserComponent,
    canActivate: [
      IsSignedInGuard
    ],
    resolve: {
      user: UserResolver
    },
    children: [
      {
        path: "",
        redirectTo: "scripts",
        pathMatch: "full",
        outlet: "tabs"
      },
      {
        path: "scripts",
        outlet: "tabs",
        component: ScriptsComponent
      },
      {
        path: "proxies",
        outlet: "tabs",
        component: ProxiesComponent
      },
      {
        path: "tdm",
        outlet: "tabs",
        component: TDMViewerComponent
      },
      {
        path: "proxiesServer",
        outlet: "tabs",
        component: ProxyServersComponent
      },
      {
        path: "config",
        outlet: "tabs",
        component: UserConfigComponent
      }
    ]
  }
];
