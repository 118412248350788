<button (contextmenu)="onContextMenu($event)" class="button-router" mat-button>
  <mat-icon>folder</mat-icon>
  <span class="label">Incarnam</span>
  <span class="fake-div"></span>
</button>
<div class="script-count">9 scripts</div>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="onContextMenuAction1()" mat-menu-item>Supprimer le dossier</button>
    <mat-divider></mat-divider>
    <button (click)="onContextMenuAction1()" mat-menu-item>Ajouter un script</button>
    <button (click)="onContextMenuAction2()" mat-menu-item>Ajouter un dossier</button>
  </ng-template>
</mat-menu>
