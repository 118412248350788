import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ScriptInformations} from "@common/lib/models/user/scripts/script";
import {FormControl} from "@angular/forms";
import {UserService} from "@dashboard/src/app/service/user.service";
import {debounceTime, distinctUntilChanged} from "rxjs";


@Component({
  selector: "app-script-editor",
  templateUrl: "./script-editor.component.html",
  styleUrls: ["./script-editor.component.scss"]
})
export class ScriptEditorComponent implements OnChanges {
  @Input() script: ScriptInformations;
  public formControlScript: FormControl;

  editorOptions = {theme: "vs-dark", language: "json"};

  constructor(private userService: UserService, private route: ActivatedRoute) {
  }


  ngOnChanges(changes: SimpleChanges) {
    this.formControlScript = new FormControl(this.script.content);
    this.formControlScript.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((newValue: string) => {
      if (this.script.content !== newValue) {
        try {
          JSON.parse(newValue);
          this.script.content = newValue;
          this.userService.user.scripts.updateScript(this.script);
        } catch (e) {
          console.log(`Script ${this.script.name} not a valid json`);
        }
      }
    });
  }

  convertScript(textArea: HTMLTextAreaElement) {
    let script = textArea.value;
    script = script.replace(new RegExp("incarnam: true, ", "g"), "");
    script = script.replace(new RegExp(`{map = "`, "g"), `{"map":"[`);
    script = script.replace(new RegExp(`", path = "`, "g"), `]","action":{"name":"changeMap","args":["`);
    script = script.replace(new RegExp(`"}`, "g"), `"]}}`);

    script = script.replace(new RegExp(`{ map: "`, "g"), `{"map":"[`);
    script = script.replace(new RegExp(`", path: "`, "g"), `]","action":{"name":"changeMap","args":["`);
    script = script.replace(new RegExp(`" }`, "g"), `"]}}`);

    script = script.replace(new RegExp(`", fight = true }`, "g"), `"]}}`);
    script = script.replace(new RegExp(`{ map = "`, "g"), `{"map":"[`);

    textArea.value = script;
  }
}
