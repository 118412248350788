<form (ngSubmit)="submit()" [formGroup]="formGroup">
  <h3 class="title" mat-dialog-title>{{ data.title }}</h3>

  <div mat-dialog-content>
    <mat-form-field *ngFor="let form of data.forms" appearance="outline">
      <mat-label>{{ form.label }}</mat-label>
      <input (keydown.enter)="submit()" *ngIf="form.type == 'string'" [formControlName]="form.name" matInput>
      <textarea (keydown.enter)="submit()" *ngIf="form.type == 'textarea'" [formControlName]="form.name"
                matInput></textarea>

      <mat-select *ngIf="form.type == 'selector'" [formControlName]="form.name">
        <mat-option *ngFor="let possibleValue of form.possibleValues" [value]="possibleValue.value">
          {{ possibleValue.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="actions" mat-dialog-actions>
    <button cdkFocusInitial class="action-button" color="primary" mat-raised-button type="submit">Valid</button>
    <button (click)="close()" class="action-button" color="primary" mat-dialog-close mat-raised-button>Cancel</button>
  </div>
</form>
