<div class="console-content mat-elevation-z8">
  <div #listLog class="list-log">
    <div *ngFor="let log of logs" [className]="'channel-'+ log.channel" [style.color]="log.color">
      <span *ngIf="canShow(log)">
          <span *ngIf="showDate">{{'[' + (log.timestamp * 1000 | date:'dd/MM':'fr') + '] '}}</span>
          <span *ngIf="showHour">{{'[' + (log.timestamp * 1000 | date:'HH:mm:ss.SSS':'fr') + '] '}}</span>
      <b>{{log.senderName}}</b>
        {{ log.content }}
      </span>
    </div>
  </div>
  <form [formGroup]="formConsole" class="console-form" name="consoleForm">
    <button [matMenuTriggerFor]="menu" color="warn" mat-icon-button>
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <section>
        <mat-checkbox (change)="showDate = !showDate"
                      (click)="$event.stopPropagation()"
                      [checked]="showDate"
                      class="checkbox-config"
                      color="warn">
          Date
        </mat-checkbox>
      </section>
      <section>
        <mat-checkbox (change)="showHour = !showHour"
                      (click)="$event.stopPropagation()"
                      [checked]="showHour"
                      class="checkbox-config"
                      color="warn">
          Heure
        </mat-checkbox>
      </section>
      <section *ngFor="let channel of showChannel">
        <mat-checkbox (change)="changeShowOrigin(channel.channel,$event)"
                      (click)="$event.stopPropagation();"
                      [checked]="channel.show"
                      class="checkbox-config"
                      color="warn">
          {{channel.name}}
        </mat-checkbox>
      </section>
    </mat-menu>


    <input (keydown.enter)="submit()" class="input-console" formControlName="input" name="text" type="text"/>
    <button (click)="submit()" class="send-button" mat-flat-button>Envoyer</button>
  </form>
</div>
