import {NezuUser} from "@dashboard/src/app/service/user";
import {WebSocketAngular} from "@dashboard/src/app/utils/WebSocketAngular";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {Utils} from "@common/lib/utils";
import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";
import {ControllerConsole} from "@dashboard/src/app/service/user/controllers/controller/console";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {ControllerConfig} from "@dashboard/src/app/service/user/controllers/controller/config";

const log = Utils.createDebug("app:controller");

export class Controller extends CustomDispatcher {
  public id: string;
  public login: string;
  public url: string;
  public name: string;
  public type: string;
  public user: NezuUser;

  public bots: Bot[] = [];
  public botsSolo: Bot[] = [];
  public teams: Team[] = [];

  public console: ControllerConsole;
  public config: ControllerConfig;

  public socket: WebSocketAngular;
  public isLoaded = false;
  public countBots: number = 0;
  public countBotsConnected: number = 0;
  private actualRoute: string = "";

  public constructor(controller: Controller, user: NezuUser) {
    super("controller");
    this.id = controller.id;
    this.user = user;
    this.id = controller.id;
    this.login = controller.login;
    this.url = controller.url;
    this.name = controller.name;
    this.console = new ControllerConsole(this, controller.console || {} as ControllerConsole);
    this.config = new ControllerConfig(this, controller.config || {} as ControllerConfig);
    this.countBots = controller.countBots || 0;
    this.countBotsConnected = controller.countBotsConnected || 0;
    this.monitorPackets();
    this.monitorPacketsUser();
  }

  connect() {
    let goodUrl = this.url.replace("http://", "");
    goodUrl = goodUrl.replace("ws://", "");
    goodUrl = "ws://" + goodUrl;
    console.log("Connection to " + goodUrl);
    this.socket = new WebSocketAngular(goodUrl);
    this.socket.otherDispatcher = this;
    this.socket.connect();
  }

  addBot(bot: any, teamId?: string) {
    this.socket.send({
      alias: bot.alias,
      login: bot.login,
      password: bot.password,
      teamId
    }, "AddBotRequestMessage");
  }

  addBots(bots: any[], teamId?: string) {
    this.socket.send({
      bots: bots.map(elt => {
        return {alias: elt.alias, login: elt.login, password: elt.password};
      }),
      teamId
    }, "AddBotsRequestMessage");
  }

  addTeam(team: Team, config?: any) {
    this.socket.send({
      name: team.name,
      config
    }, "AddTeamRequestMessage");
  }

  public updateRoute(url: string, force = false) {
    // const parts = url.split("/");
    // let newRoute = "";
    // if (parts.length >= 3 && parts[1] == "controller" && parts[2] == this.id) {
    //   newRoute = url;
    // }
    if (this.actualRoute != url || force) {
      this.actualRoute = url;
      this.socket.send({route: url}, "UpdateActualRoute");
    }
  }

  public removeBot(login: string) {
    this.socket.send({
      login: login
    }, "RemoveBotRequestMessage");
  }

  public changeTeamBot(login: string, newTeamId: string) {
    this.socket.send({
      login: login,
      newTeamId
    }, "ChangeTeamBotRequestMessage");
  }

  public removeTeam(id: string, withBots: boolean) {
    this.socket.send({
      id: id,
      withBots
    }, "RemoveTeamRequestMessage");
  }

  public send(callName: string, data: any) {
    this.socket.send({
      callName,
      data,
      cible: "CONTROLLER"
    }, "PacketWithCible");
  }

  public moveTeamInArray(previousIndex: number, currentIndex: number) {
    this.send("MoveTeamInTeamsList", {
      previousIndex,
      currentIndex
    });
  }

  public moveBotInArray(previousIndex: number, currentIndex: number) {
    this.send("MoveBotInBotsListSolo", {
      previousIndex,
      currentIndex
    });
  }

  public moveBotBetweenMultipleTeam(sourceTeamId: string, targetTeamId: string, previousIndex: number, currentIndex: number) {
    this.send("MoveBotBetweenMultipleTeam", {
      sourceTeamId,
      targetTeamId,
      previousIndex,
      currentIndex
    });
  }

  restart() {
    this.send("RestartControllerRequestMessage", {});
  }

  removeAndSaveBot(login: string) {
    this.socket.send({
      login: login
    }, "RemoveAndSaveBotRequestMessage");
  }

  fillAccounts() {
    this.socket.send({}, "FillAccounts");
  }

  fillOldAccounts() {
    this.socket.send({}, "FillOldAccounts");

  }

  rename(name: string) {
    this.send("UpdateNameRequestMessage", {
      name
    });
  }

  private monitorPackets() {
    const wrapper = this.wrap();
    wrapper.addMonitorPacket("IdentificationSuccessMessage", (packet: any) => {
      this.id = packet.controllerId;
      this.updateRoute(this.user.userService.actualRoute, true);
    });
    wrapper.addMonitorPacket("CountBotsConnectedUpdatedMessage", (packet: any) => {
      this.countBots = packet.countBots;
      this.countBotsConnected = packet.countBotsConnected;
    });
    wrapper.addMonitorPacket("MoveTeamInTeamsList", (packet: any) => {
      this.teams.splice(packet.currentIndex, 0, this.teams.splice(packet.previousIndex, 1)[0]);
    });
    wrapper.addMonitorPacket("MoveBotInBotsListSolo", (packet: any) => {
      this.botsSolo.splice(packet.currentIndex, 0, this.botsSolo.splice(packet.previousIndex, 1)[0]);
    });
    wrapper.addMonitorPacket("MoveBotBetweenMultipleTeam", (packet: any) => {
      const source = packet.sourceTeamId == "solo" ? this.botsSolo : this.teams.find(elt => elt.id == packet.sourceTeamId).bots;
      const target = packet.targetTeamId == "solo" ? this.botsSolo : this.teams.find(elt => elt.id == packet.targetTeamId).bots;
      const bot = source.splice(packet.previousIndex, 1)[0];
      if (!bot) return;
      bot.teamId = packet.targetTeamId == "solo" ? null : packet.targetTeamId;
      bot.team = !bot.teamId ? null : this.teams.find(elt => elt.id == bot.teamId);
      target.splice(packet.currentIndex, 0, bot);
      for (let i = 0; i < source.length; i++) {
        source[i].teamIndex = i;
      }
      for (let i = 0; i < target.length; i++) {
        target[i].teamIndex = i;
      }
    });
    wrapper.addMonitorPacket("ControllerWebsocketInformations", (packet: any) => {
      if (this.socket) this.socket.disconnect();
      this.url = packet.url;
      this.connect();
    });


    wrapper.addMonitorPacket("ControllerInformationsMessage", (packet: any) => {
      this.id = packet.controller.id;
      this.type = packet.controller.type;
      this.teams = packet.controller.teams.map((elt: Team) => new Team(this, elt));
      this.bots = packet.controller.bots.map((elt: Bot) => new Bot(this, elt));
      this.config.configContent = packet.controller.config.configContent;
      this.countBots = packet.controller.countBots;
      this.countBotsConnected = packet.controller.countBotsConnected;
      try {
        this.config.configData = JSON.parse(this.config.configContent);
      } catch (e) {

      }
      this.botsSolo = [];
      for (const bot of this.bots) {
        if (bot.team) {
          bot.team.bots.push(bot);
        } else {
          this.botsSolo.push(bot);
        }
      }
      this.botsSolo.sort((a, b) => a.teamIndex - b.teamIndex);
      for (let i = 0; i < this.botsSolo.length; i++) {
        this.botsSolo[i].teamIndex = i;
      }
      for (const team of this.teams) {
        team.bots.sort((a, b) => a.teamIndex - b.teamIndex);
      }
      this.teams.sort((a, b) => a.teamIndex - b.teamIndex);
      for (let i = 0; i < this.teams.length; i++) {
        this.teams[i].teamIndex = i;
      }
      this.isLoaded = true;
      if (this.user.userService.controllerSelected
        && this.user.userService.controllerSelected.id == this.id) {
        this.user.userService.controllerSelected = this;
        log("ControllerSelected Updated ! ");
        this.user.userService.refreshRoute();
      }
    });
    wrapper.addMonitorPacket("PacketWithCible", (packet: any) => {
      switch (packet.cible) {
        case "BOT":
          const bot = this.bots.find(elt => elt.login === packet.login);
          if (bot) {
            bot.emit(packet.callName, packet.data);
          }
          break;
        case "TEAM":
          const team = this.teams.find(elt => elt.id == packet.id);
          if (team) {
            team.emit(packet.callName, packet.data);
          }
          break;
        case "CONTROLLER":
          this.emit(packet.callName, packet.data);
          break;
      }
    });
    wrapper.addMonitorPacket("BotAddedMessage", (packet: any) => {
      const bot = new Bot(this, packet.bot);
      this.bots.push(bot);
      if (bot.team) {
        bot.team.bots.push(bot);
      } else {
        this.botsSolo.push(bot);
      }
    });
    wrapper.addMonitorPacket("TeamAddedMessage", (packet: any) => {
      const team = new Team(this, packet.team);
      this.teams.push(team);
    });

    wrapper.addMonitorPacket("RemoveBotMessage", (packet: any) => {
      const bot = this.bots.find(elt => elt.login == packet.login);
      if (bot) {
        this.bots = this.bots.filter(elt => elt.login !== packet.login);
        this.botsSolo = this.botsSolo.filter(elt => elt != bot);
        for (const team of this.teams) {
          team.bots = team.bots.filter(elt => elt != bot);
        }
        if (this.user.userService.botSelected == bot) {
          this.user.userService.refreshRoute("/controller/" + this.id);
        }
      }
    });

    wrapper.addMonitorPacket("RemoveTeamMessage", (packet: any) => {
      const team = this.teams.find(elt => elt.id == packet.id);
      if (team) {
        this.teams = this.teams.filter(elt => elt.id !== packet.id);
        if (this.user.userService.teamSelected == team) {
          this.user.userService.refreshRoute("/controller/" + this.id);
        }
      }
    });
  }

  private monitorPacketsUser() {
    const wrapper = this.user.wrap();
    wrapper.addMonitorPacket("ActualRouteUpdated", (url: string) => {
      this.updateRoute(url);
    });
  }
}
