import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserComponent} from "./user.component";
import {RouterModule} from "@angular/router";
import {MatTabsModule} from "@angular/material/tabs";
import {ScriptsModule} from "@dashboard/src/app/pages/user/scripts/scripts.module";
import {IasModule} from "@dashboard/src/app/pages/user/ias/ias.module";
import {UserConfigModule} from "@dashboard/src/app/pages/user/user-config/user-config.module";
import {UserResolver} from "@dashboard/src/app/pages/user/user.resolver";
import {ROUTES_USER} from "@dashboard/src/app/pages/user/user.routes";
import {ProxiesComponent} from "./proxies/proxies.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import { ProxyServersComponent } from './proxy-servers/proxy-servers.component';
import {ProxyServersModule} from "@dashboard/src/app/pages/user/proxy-servers/proxy-servers.module";
import {MatDividerModule} from "@angular/material/divider";
import { TDMViewerComponent } from './tdmviewer/tdmviewer.component';

@NgModule({
  declarations: [UserComponent, ProxiesComponent, TDMViewerComponent],
  exports: [UserComponent],
    imports: [
        CommonModule,
        MatTabsModule,
        ScriptsModule,
        IasModule,
        RouterModule.forChild(ROUTES_USER),
        MatPaginatorModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        UserConfigModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        ProxyServersModule,
        MatDividerModule
    ],
  providers: [
    UserResolver
  ]
})
export class UserModule {
}
