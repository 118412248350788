import {LOCALE_ID, NgModule} from "@angular/core";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LoginModule} from "@dashboard/src/app/features/login/login.module";
import {ToolbarModule} from "@dashboard/src/app/features/toolbar/toolbar.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CommonModule, registerLocaleData} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {CustomSidenavModule} from "@dashboard/src/app/features/sidenav/custom-sidenav.module";
import {TeamModule} from "@dashboard/src/app/pages/controller/team/team.module";
import {ControllerModule} from "@dashboard/src/app/pages/controller/controller.module";
import {BotModule} from "@dashboard/src/app/pages/controller/bot/bot.module";
import localeFr from "@angular/common/locales/fr";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../environments/environment";
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    CanvasJSAngularChartsModule,
    BrowserAnimationsModule,
    LoginModule,
    MatProgressSpinnerModule,
    CustomSidenavModule,
    MatIconModule,
    ReactiveFormsModule,
    ToolbarModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BotModule,
    TeamModule,
    ControllerModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000"
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "fr-FR"}
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
