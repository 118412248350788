import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ScriptsComponent} from "./scripts.component";
import {ScriptModule} from "@dashboard/src/app/pages/user/scripts/scripts-manager/script/script.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTabsModule} from "@angular/material/tabs";
import {ScriptsManagerModule} from "@dashboard/src/app/pages/user/scripts/scripts-manager/scripts-manager.module";
import {MatDividerModule} from "@angular/material/divider";

@NgModule({
  declarations: [ScriptsComponent],
  exports: [ScriptsComponent],
  imports: [
    CommonModule,
    ScriptModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    ScriptsManagerModule,
    MatDividerModule
  ]
})
export class ScriptsModule {
}
