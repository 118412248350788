import {Component, OnDestroy, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {environment} from "@dashboard/src/environments/environment";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {JobExperience} from "@protocol/types/JobExperience";

@Component({
  selector: "app-bot-fm-exchange",
  templateUrl: "./bot-fm-exchange.component.html",
  styleUrls: ["./bot-fm-exchange.component.scss"]
})
export class BotFmExchangeComponent implements OnInit, OnDestroy {
  public bot: Bot;
  public pathImages = `${environment.HTTP_SERVER_ADDRESS}/images/items/`;
  public actualItem: ObjectItem;
  public actualRune: ObjectItem;
  public actualItemImg: string;
  public actualRuneImg: string;
  public effects: any;
  public actualJob: JobExperience;
  private wrapper: CustomWrapper;
  public actualJobPourcent= 0;

  constructor(private userService: UserService) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
    this.wrapper = this.bot.wrap();
    this.wrapper.addMonitorPacket([
      "ExchangeObjectModifiedMessage",
      "ExchangeObjectAddedMessage",
      "ExchangeContentMessage",
      "ExchangeObjectModifiedMessage",
      "ObjectModifiedMessage",
      "JobExperienceUpdateMessage",
      "JobExperienceMultiUpdateMessage",
      "JobsInformationsMessage"
    ], () => {
      this.updateData();
    }, 2000);
    this.updateData();
  }

  ngOnDestroy(): void {
    this.wrapper.done();
  }

  private updateData() {
    // @ts-ignore
    this.actualItem = this.bot.game.exchange.listExchangeItems.find(elt => elt["_typeId"] !== 78);
    // @ts-ignore
    this.actualRune = this.bot.game.exchange.listExchangeItems.find(elt => elt["_typeId"] == 78);
    // @ts-ignore
    this.actualItemImg = this.actualItem ? `${this.pathImages + this.actualItem._iconId}.png` : "";
    // @ts-ignore
    this.actualRuneImg = this.actualRune ? `${this.pathImages + this.actualRune._iconId}.png` : "";
    if (this.bot.game.exchange.skillParentJobId) {
      this.actualJob = this.bot.game.jobs.jobs.find(elt => elt.jobId == this.bot.game.exchange.skillParentJobId);
    }
  }
}
