import {AuthenticationService} from "@dashboard/src/app/service/authentication.service";
import {UserService} from "@dashboard/src/app/service/user.service";
import {IdentificationReasonFailedEnum} from "@common/lib/network/enums/IdentificationReasonFailedEnum";
// import { version } from '../../../../package.json';
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "@dashboard/src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  public version: string = "1.0.0";
  public form: FormGroup;
  public error: string;


  constructor(public authenticationService: AuthenticationService,
              public userService: UserService) {
    this.initFormGroup();
  }

  ngOnInit() {
  }

  initFormGroup() {
    this.form = new FormGroup({
      login: new FormControl(),
      password: new FormControl()
    });
  }

  public connect() {
    const login: string = this.form.get("login").value.toLocaleLowerCase();
    const password = this.form.get("password").value;
    this.authenticationService.getToken(login, password).subscribe(
      (response: { error?: number, token?: string }) => {
        if (response.token) {
          environment.token = response.token;
          localStorage.setItem("nezudash-token", response.token);
          this.userService.socket.connect();
        } else this.error = IdentificationReasonFailedEnum[response.error];
      });

    // this.userService.connect(login, password).then((response) => {
    //   if (response.result) {
    //     localStorage.setItem('login', login);
    //     localStorage.setItem('password', password);
    //   } else {
    //     this.error = response.reason;
    //   }
    // });
  }

}
