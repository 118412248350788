import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-bot-console",
  templateUrl: "./bot-console.component.html",
  styleUrls: ["./bot-console.component.scss"]
})
export class BotConsoleComponent implements OnInit {
  public bot: Bot;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dt: ChangeDetectorRef
  ) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
    console.log("Console init");
  }

  public submit(command: string) {
    this.bot.console.sendCommand(command);
  }

}
