import {CharacterStatsListMessage} from "@protocol/messages/CharacterStatsListMessage";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {StatIdsEnum} from "@dashboard/src/app/service/user/controllers/controller/bot/game/character/stats/StatIdsEnum";
import {CharacterCharacteristicsInformations} from "@protocol/types/CharacterCharacteristicsInformations";
import {CharacterCharacteristicValue} from "@protocol/types/CharacterCharacteristicValue";
import {CharacterCharacteristicDetailed} from "@protocol/types/CharacterCharacteristicDetailed";

export class GameCharacterStats {
  public stats: CharacterCharacteristicsInformations;
  public regenTimer: any;
  public regenRate: number = 1;
  public pourcentLife = 0;
  public pourcentExperience = 0;
  public pourcentEnergy = 0;
  private bot: Bot;

  constructor(bot: Bot, stats: GameCharacterStats) {
    this.bot = bot;
    this.stats = stats.stats || null;
    this.monitorPackets();
  }

  public get maxLifePoints(): number {
    return this.getStatsValue(StatIdsEnum.LIFE_POINTS)
      + this.getStatsValue(StatIdsEnum.VITALITY);
  }

  public get lifePoints(): number {
    return this.getStatsValue(StatIdsEnum.LIFE_POINTS)
      + this.getStatsValue(StatIdsEnum.CUR_LIFE)
      + this.getStatsValue(StatIdsEnum.VITALITY);
  }

  public get energy(): number {
    if (!this.stats?.characteristics) return 0;
    const stats = this.stats.characteristics.find(
      elt => elt.characteristicId == StatIdsEnum.ENERGY_POINTS) as CharacterCharacteristicValue;
    return stats ? stats.total : 0;
  }

  public get maxEnergy(): number {
    if (!this.stats?.characteristics) return 0;
    const stats = this.stats.characteristics.find(elt => elt.characteristicId == StatIdsEnum.MAX_ENERGY_POINTS) as CharacterCharacteristicValue;
    return stats ? stats.total : 0;
  }

  public getStatsValue(statId: StatIdsEnum) {
    if (!this.stats || !this.stats.characteristics) return 0;
    const stats = this.stats.characteristics.find(
      elt => elt.characteristicId == statId) as any;
    if (stats) {
      switch (stats._messageType) {
        case "CharacterUsableCharacteristicDetailed":
          return stats.base + stats.additional + stats.objectsAndMountBonus + stats.alignGiftBonus + stats.contextModif;
        case "CharacterCharacteristicValue":
          return stats.total;
        case "CharacterCharacteristicDetailed":
          return stats.base + stats.additional + stats.objectsAndMountBonus + stats.contextModif;
      }
    } else {
      return 0;
    }
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("CharacterStatsListMessage", (packet: CharacterStatsListMessage) => {
      console.log();
      this.stats = packet.stats;
      this.pourcentLife = Math.floor(Math.min(this.lifePoints / this.maxLifePoints * 100, 100));
      console.log(`% life : ${this.pourcentLife}`);
      if (this.maxEnergy && this.energy) {
        this.pourcentEnergy = Math.floor(Math.min(this.energy / this.maxEnergy * 100, 100));
      } else this.pourcentEnergy = 0;

      if (this.stats && this.stats.experience && this.stats.experienceLevelFloor) {
        const experience = this.stats.experience - this.stats.experienceLevelFloor;
        const experienceMax = this.stats.experienceNextLevelFloor - this.stats.experienceLevelFloor;
        this.pourcentExperience = parseFloat(Math.min(experience / experienceMax * 100, 100).toFixed(2));
      } else this.pourcentExperience = 0;
    });
  }
}
