<div class="group-infos">
  <button (click)="isOpen = !isOpen"
          (contextmenu)="clickOnContextMenu($event)"
          class="close-open-group"
          mat-button>
    {{schema.name | slice:0:8}}
  </button>
</div>
<div *ngIf="isOpen" class="group-open">
  <div (cdkDropListDropped)="dropElement($event)"
       cdkDropList
       class="example-list">

    <div *ngFor="let element of schema.elements" [cdkDragData]="element" [cdkDragDisabled]="userService.isMobile"
         cdkDrag>
      <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
      <app-controller-element
        *ngIf="element._type == 'ControllerSchema'"
        [controller]="element.controller"
        [inGroup]="true"
        [name]="element.controller?.config?.configData?.name || element.name"
        [ngClass]="{'controller-selected':
          userService.controllerSelected?.id === element.controller.id}"
        [schema]="element"
        routerLink="/controller/{{element.id}}">
      </app-controller-element>
    </div>
  </div>
</div>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="rename()" mat-menu-item>Rename</button>
    <button (click)="remove()" mat-menu-item>Remove</button>
  </ng-template>
</mat-menu>
