import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-bot-network-packet-line",
  templateUrl: "./bot-network-packet-line.component.html",
  styleUrls: ["./bot-network-packet-line.component.scss"]
})
export class BotNetworkPacketLineComponent implements OnInit {

  @Input() public packet: any;
  @Input() public selected: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
