<mat-toolbar class="toolbar">
  <div class="toolbar-left">
    <button (click)="emitEventToSidenav()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <button class="toolbar-app-name" mat-button routerLink="/home">
      <img class="toolbar-nezu" src="../../../assets/img/common/nezu.png"/>
    </button>
    <span *ngIf="userService.keys[67]" class="icon-touche">CREATE</span>
    <span *ngIf="userService.keys[71]" class="icon-touche">SET GOOD</span>
    <span *ngIf="userService.keys[76]" class="icon-touche">SET LOST</span>
    <span *ngIf="userService.keys[83]" class="icon-touche">SAVE & REMOVE</span>
    <span *ngIf="userService.keys[65]" class="icon-touche">ADD ACCOUNTS</span>
  </div>
  <div>
    {{version}}
  </div>
  <div class="toolbar-right">
    <button (click)="disconnect()" color="secondary" mat-raised-button>Disconnect</button>
  </div>
</mat-toolbar>
