import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {JobExperience} from "@protocol/types/JobExperience";
import {JobExperienceUpdateMessage} from "@protocol/messages/JobExperienceUpdateMessage";
import {JobExperienceMultiUpdateMessage} from "@protocol/messages/JobExperienceMultiUpdateMessage";

export class BotGameJobs {
  public jobs: JobExperience[] = [];
  private bot: Bot;

  public constructor(bot: Bot, exchange: BotGameJobs) {
    this.bot = bot;
    this.monitorPackets();
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("JobExperienceMultiUpdateMessage", (packet: JobExperienceMultiUpdateMessage) => {
      this.jobs = packet.experiencesUpdate;
    });
    wrapper.addMonitorPacket("JobExperienceUpdateMessage", (packet: JobExperienceUpdateMessage) => {
      const jobIndex = this.jobs.findIndex(elt => elt.jobId === packet.experiencesUpdate.jobId);
      if (jobIndex > -1) this.jobs.splice(jobIndex, 1);
      this.jobs.push(packet.experiencesUpdate);
    });
    wrapper.addMonitorPacket("JobsInformationsMessage", (packet: any) => {
      this.jobs = packet.jobs;
    });
  }
}
