import {Component, Input} from "@angular/core";
import {CharacterCharacteristicValue} from "@protocol/types/CharacterCharacteristicValue";
import {
  getStatsNameById
} from "@dashboard/src/app/service/user/controllers/controller/bot/game/character/stats/StatIdsEnum";

@Component({
  selector: "app-view-characteristic-value",
  standalone: true,
  imports: [],
  templateUrl: "./view-characteristic-value.component.html",
  styleUrl: "./view-characteristic-value.component.scss"
})
export class ViewCharacteristicValueComponent {
  @Input()  public stat: CharacterCharacteristicValue;

  protected readonly getStatsNameById = getStatsNameById;
  protected readonly JSON = JSON;
}
