import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {TeamConfig} from "@dashboard/src/app/service/user/controllers/controller/team/config";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

export class Team extends CustomDispatcher {
  public controller: Controller;
  public controllerId: string;

  public id: string;
  public config: TeamConfig;
  public bots: Bot[] = [];
  public _open: any = false;
  public teamIndex: number = 0;
  public lastWinFightDate: number = null;

  public constructor(controller: Controller, team: Team) {
    super("team");
    this.controller = controller;
    this.controllerId = controller.id;
    this.id = team.id;
    this.name = team.name;
    this.config = new TeamConfig(this, team.config || {} as TeamConfig);
    this.teamIndex = team.teamIndex || 0;
    this.lastWinFightDate = team.lastWinFightDate;
    this.monitorPackets();
  }

  public _name: string;

  public get name() {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get chef() {
    return this.bots.length > 0 ? this.bots[0] : null;
  }

  public get kamas(): number {
    if (this.bots.length == 0) return 0;
    return this.bots
      .map(elt => elt.game.character.inventory.kamas)
      .reduce((a, b) => a + b);
  }

  public send(callName: string, data?: any) {
    this.controller.socket.send({
      callName,
      data,
      cible: "TEAM",
      id: this.id
    }, "PacketWithCible");
  }


  public rename(name: string) {
    this.send("UpdateNameRequestMessage", {name});
  }

  moveBotInArray(previousIndex: number, currentIndex: number) {
    this.send("MoveBotInBotsList", {
      previousIndex,
      currentIndex
    });
  }

  connectBotsDisconnected() {
    this.send("ConnectBots", {justIfDisconnected: true});
  }

  connectBots() {
    this.send("ConnectBots", {});
  }

  disconnectBots() {
    this.send("DisconnectBots", {});
  }

  addBotsFromBDD(count: number) {
    if (count > 0) {
      this.send("addBotsFromBDD", {count: count});
    }
  }

  createFromConfig() {
    this.send("CreateCharactersFromConfig", {});
  }

  private monitorPackets() {
    const wrapper = this.wrap();
    wrapper.addMonitorPacket("NameUpdateMessage", (packet: any) => {
      this.name = packet.name;
    });
    wrapper.addMonitorPacket("MoveBotInBotsList", (packet: any) => {
      this.bots.splice(packet.currentIndex, 0, this.bots.splice(packet.previousIndex, 1)[0]);
    });
    wrapper.addMonitorPacket("LastWinFightDateUpdated", (packet: any) => {
      this.lastWinFightDate = packet.lastWinFightDate;
    });
  }
}
