import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ScriptsManagerComponent} from "@dashboard/src/app/pages/user/scripts/scripts-manager/scripts-manager.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {FolderElementComponent} from "./folder-element/folder-element.component";
import {ScriptElementComponent} from "./script-element/script-element.component";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ScriptEditorComponent} from "./script-editor/script-editor.component";
import {MonacoEditorModule} from "@materia-ui/ngx-monaco-editor";
import {MatTabsModule} from "@angular/material/tabs";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AddScriptModule} from "@dashboard/src/app/pages/user/scripts/scripts-manager/add-script/add-script.module";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [ScriptsManagerComponent, FolderElementComponent, ScriptElementComponent, ScriptEditorComponent],
  exports: [ScriptsManagerComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    BrowserModule,
    MatTabsModule,
    MatDialogModule,
    MonacoEditorModule,
    AddScriptModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ScriptsManagerModule {
}
