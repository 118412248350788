<mat-divider></mat-divider>
<nav mat-tab-nav-bar backgroundColor="primary" color="warn">
  <a mat-tab-link *ngFor="let tab of tabs"
     (click)="navigate(tab.link)"
     [active]="activeLink == tab.link">
    {{tab.label}}
  </a>
</nav>
<div class="outlet">
  <router-outlet name="tabs"></router-outlet>
</div>
