import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-script',
  templateUrl: './add-script.component.html',
  styleUrls: ['./add-script.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddScriptComponent implements OnInit {
  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddScriptComponent>
  ) {
    this.formGroup = new FormGroup({
      scriptName: new FormControl(),
    });
  }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close({name: this.formGroup.get('scriptName').value});
  }

  cancel() {
    this.dialogRef.close({name: null});
  }
}
