import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UserService} from '@dashboard/src/app/service/user.service';
import {Observable, of} from 'rxjs';
import {NezuUser} from '@dashboard/src/app/service/user';
import {ComponentTypeSelectedEnum} from '@dashboard/src/app/enums/ComponentTypeSelectedEnum';

@Injectable()
export class UserResolver implements Resolve<NezuUser> {

  constructor(private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NezuUser> {
    this.userService.componentSelected = ComponentTypeSelectedEnum.USER;
    this.userService.teamSelected = null;
    this.userService.botSelected = null;
    return of(this.userService.user);
  }
}
