import {Component, OnInit} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

@Component({
  selector: "app-bot-fm",
  templateUrl: "./bot-fm.component.html",
  styleUrls: ["./bot-fm.component.scss"]
})
export class BotFmComponent implements OnInit {
  public bot: Bot;

  constructor(private userService: UserService) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
  }

}
