import { Component, OnInit } from '@angular/core';
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UntypedFormControl} from "@angular/forms";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ActivatedRoute} from "@angular/router";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

@Component({
  selector: 'app-controller-config',
  templateUrl: './controller-config.component.html',
  styleUrls: ['./controller-config.component.scss']
})
export class ControllerConfigComponent implements OnInit {

  public controller: Controller;
  public formControlConfig: UntypedFormControl;
  public editorOptions = {theme: "vs-dark", automaticLayout: true, language: "json"};


  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.controller = this.userService.controllerSelected;
  }

  ngOnInit(): void {
    const wrapper = this.controller.wrap();
    wrapper.addMonitorPacket("ControllerConfigUpdatedMessage", (packet: any) => {
      if (packet.force) {
        this.initData();
      }
    }, 5000);
    wrapper.addMonitorPacket("ControllerInformationsMessage",(packet: any) => {
      this.initData();
    },5000)
    console.log("init form test");
    this.initData();
  }


  public initData() {
    this.initFormControl(this.controller);
  }

  private initFormControl(controller: Controller) {
    this.formControlConfig = new UntypedFormControl(controller.config.configContent);
    this.formControlConfig.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((newValue: string) => {
      if (controller.config.configContent !== newValue) {
        try {
          JSON.parse(newValue);
          controller.config.configContent = newValue;
          controller.config.updateConfig(newValue);
        } catch (e) {
          console.log(`Config not a valid json`);
        }
      }
    });
  }


  updateConfig() {

  }

  reformatCode() {

  }

  configContentChange($event: any) {

  }
}
