import {NezuUser} from "@dashboard/src/app/service/user";
import {ScriptUpdatedMessage} from "@common/lib/network/packets/ScriptUpdatedMessage";
import {ScriptInformations} from "@common/lib/models/user/scripts/script";

export class ScriptsManager {
  private user: NezuUser;
  public scripts: ScriptInformations[];
  public directories: string[];

  constructor(scripts: ScriptsManager, user: NezuUser) {
    this.user = user;
    this.scripts = scripts.scripts || [];
    this.directories = scripts.directories || [];
    for (const script of this.scripts) {
      try {
        script.contentData = JSON.parse(script.content);
      } catch (e) {
        script.contentData = null;
      }
    }
    this.monitorPackets();
  }

  addScript(name: string) {
    this.user.send({
      name
    }, "AddScriptRequestMessage");
  }

  public updateScript(script: ScriptInformations) {
    this.user.send({
      id: script.id,
      content: script.content
    }, "UpdateScriptRequestMessage");
  }

  private monitorPackets() {
    const wrapper = this.user.socket.wrap();
    wrapper.addMonitorPacket("ScriptUpdatedMessage", (packet: ScriptUpdatedMessage) => {
      const script = this.scripts.find(elt => elt.id === packet.script.id);
      if (script) {
        script.content = packet.script.content;
        script.modifiedDate = packet.script.modifiedDate;
      } else this.scripts.push(packet.script);
    });
    wrapper.addMonitorPacket("ScriptDeletedMessage", (packet: any) => {
      const script = this.scripts.find(elt => elt.id == packet.id);
      if (script) {
        this.scripts = this.scripts.filter(elt => elt != script);
      }
    });
  }

  deleteScript(script: ScriptInformations) {
    this.user.send({
      id: script.id
    }, "DeleteScriptRequestMessage");
  }
}
