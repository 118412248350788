import {ChatServerMessage} from "@common/lib/network/packets/ChatServerMessage";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

export class ControllerConsole {
  private controller: Controller;
  public logs: ChatServerMessage[] = [];

  constructor(controller: Controller, controllerConsole: ControllerConsole) {
    this.controller = controller;
    this.logs = controllerConsole.logs || [];
    this.monitorPackets();
  }

  private monitorPackets() {
    const wrapper = this.controller.wrap();
    wrapper.addMonitorPacket("packet::ChatServerMessage", (packet: ChatServerMessage) => {
      this.addLog(packet);
    });
    wrapper.addMonitorPacket("LogsUpdateMessage", (packet: any) => {
      this.logs = packet.logs;
    });
  }

  public addLog(log: ChatServerMessage) {
    this.logs.push(log);
    if (this.logs.length > 150) {
      this.logs = this.logs.slice(this.logs.length - 101, this.logs.length - 1);
    }
  }

  sendCommand(command: string) {
    this.controller.send("CommandRequestMessage", {
      command
    });
  }
}
