import {AfterViewInit, Component, OnDestroy, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatPaginator} from "@angular/material/paginator";
import {environment} from "@dashboard/src/environments/environment";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {FormControl, FormGroup} from "@angular/forms";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";

@Component({
  selector: "app-bot-inventory",
  templateUrl: "./bot-inventory.component.html",
  styleUrls: ["./bot-inventory.component.scss"]
})
export class BotInventoryComponent  {





}
