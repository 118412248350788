import { Component, OnInit } from '@angular/core';
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";

@Component({
  selector: 'app-team-content',
  templateUrl: './team-content.component.html',
  styleUrls: ['./team-content.component.scss']
})
export class TeamContentComponent implements OnInit {
  public team: Team;
  public activeLink = "config";

  public tabs = [
    {label: "Config", link: "config"}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
    this.team = this.userService.teamSelected;
  }

  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {tabs: path}}], {relativeTo: this.route});
  }

  ngOnInit(): void {
    const childrenTabs = this.route.children.find(elt => elt.outlet == "tabs");
    if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
      this.activeLink = childrenTabs.snapshot.url[0].path;
    } else {
      this.activeLink = this.tabs[0].link;
    }
  }

  getSelectedIndex() {

  }
}
