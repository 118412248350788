import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

@Component({
  selector: "app-controller-console",
  templateUrl: "./controller-console.component.html",
  styleUrls: ["./controller-console.component.scss"]
})
export class ControllerConsoleComponent implements OnInit {
  public controller: Controller;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dt: ChangeDetectorRef
  ) {
    this.controller = this.userService.controllerSelected;
  }

  ngOnInit(): void {
    console.log("Console init");
  }

  public submit(command: string) {
    this.controller.console.sendCommand(command);
  }
}
