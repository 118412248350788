import {Injectable} from "@angular/core";
import {environment} from "@dashboard/src/environments/environment";
import {WebSocketAngular} from "@dashboard/src/app/utils/WebSocketAngular";
import {NezuUser} from "@dashboard/src/app/service/user";
import {IdentificationSuccessMessage} from "@common/lib/network/packets/IdentificationSuccessMessage";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {NavigationEnd, Router} from "@angular/router";
import {Utils} from "@common/lib/utils";

const log = Utils.createDebug("app:userService");

@Injectable({
  providedIn: "root"
})
export class UserService {
  public user!: NezuUser;
  public socket: WebSocketAngular = new WebSocketAngular(environment.WS_SERVER_ADDRESS);
  public componentSelected = ComponentTypeSelectedEnum.NOTHING;
  public actualRoute = "";
  public isMobile: boolean;
  public keys: any[] = [];

  constructor(private router: Router) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    this.monitorPackets();
  }

  private _controllerSelected?: any;
  get controllerSelected(): any {
    return this._controllerSelected;
  }

  public set controllerSelected(value: any) {
    if (value != this._controllerSelected) {
      if (this._controllerSelected && this._controllerSelected.emit) {
        this._controllerSelected.emit("StopLooking");
      }
      this._controllerSelected = value;
      if (value) {
        this._controllerSelected.emit("StartLooking");
      }
      this.user.emit("ControllerSelectedUpdated", this._controllerSelected);
    }
  }

  private _botSelected?: any;

  get botSelected(): any {
    return this._botSelected;
  }

  public set botSelected(value: any) {
    if (value != this._botSelected) {
      if (this._botSelected && this._botSelected.emit) {
        this._botSelected.emit("StopLooking");
      }
      this._botSelected = value;
      if (value) {
        this._botSelected.emit("StartLooking");
      }
      log("BotSelectedUpdated");
      this.user.emit("BotSelectedUpdated", this._botSelected);
    }
  }

  private _teamSelected?: any;

  get teamSelected(): any {
    return this._teamSelected;
  }

  public set teamSelected(value: any) {
    if (value != this._teamSelected) {
      if (this._teamSelected && this._teamSelected.emit) {
        this._teamSelected.emit("StopLooking");
      }
      this._teamSelected = value;
      if (value) {
        this._teamSelected.emit("StartLooking");
      }
      this.user.emit("TeamSelectedUpdated", this._teamSelected);
    }
  }

  async refreshRoute(route: string = this.router.url) {
    if (route != "/") {
      log("Navigate to " + route);
      const tmpRoute = "/controller/" + this.controllerSelected.id;
      await this.router.navigateByUrl(tmpRoute);
      if (tmpRoute != route) {
        await this.router.navigateByUrl(route);
      }
    }
  }

  private monitorPackets() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.actualRoute = event.urlAfterRedirects;
        this.user.emit("ActualRouteUpdated", this.actualRoute);
        console.log("Route change detected : " + event.urlAfterRedirects);
      }
    });


    // this.keys = [];
    // window.addEventListener("keydown", (e) => {
    //   console.log("keydown " + e.keyCode);
    //   this.keys[e.keyCode] = e.keyCode;
    // }, false);
    // window.addEventListener("keyup", (e) => {
    //   console.log("keyup " + e.keyCode);
    //   this.keys[67] = false;
    //   this.keys[e.keyCode] = false;
    // }, false);


    const wrapper = this.socket.wrap();
    wrapper.addMonitorPacket("IdentificationSuccessMessage", (packet: IdentificationSuccessMessage) => {
      this.socket.send({}, "UserDataInformationsRequestMessage");
      this.socket.send({}, "ControllerInformationsRequestMessage");
    }, 2000);
    wrapper.addMonitorPacket("UserDataInformationsMessage", (packet: any) => {
      this.user = new NezuUser(packet.user, this.socket);
      this.user.userService = this;
      this.user.init();
    });
    wrapper.addMonitorPacket("PacketWithCible", (packet: any) => {
      switch (packet.cible) {
        case "PROXY_SERVER":
          const proxyServer = this.user.proxyServers.proxyServers.find(elt => elt.id == packet.id);
          if (proxyServer) {
            proxyServer.emit(packet.callName, packet.data, packet);
          }
          break;
        case "CONTROLLER":
          const controller = this.user.controllers.controllers.find(elt => elt.id == packet.id);
          if (controller) {
            controller.emit(packet.callName, packet.data);
          }
          break;
      }
    });
  }
}
