<mat-sidenav-container autosize class="sidenav-container">
  <mat-sidenav #sidenav [mode]="sidenavModeIsSide ? 'side':'over'" [opened]="true">
    <div class="sidenav-user-container">
      <app-user-element></app-user-element>
    </div>
    <div class="separate-sidenav">
      <div class="controllers">
        <div (click)="clickOnContextMenu($event)"
             (contextmenu)="clickOnContextMenu($event)"
             [matMenuTriggerFor]="contextMenu"
             class="select-controller-directory">
          <span class="three-dot">...</span>
        </div>
        <div (cdkDropListDropped)="dropElement($event)" cdkDropList class="example-list">
          <div *ngFor="let element of userService.user.controllers.schema.elements"
               [cdkDragDisabled]="userService.isMobile" cdkDrag>
            <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
            <app-controller-element
              *ngIf="element._type == 'ControllerSchema'"
              [controller]="element.controller"
              [inGroup]="false"
              [name]="element.controller?.config?.configData?.name || element.name"
              [ngClass]="{'controller-selected':
          userService.controllerSelected?.id === element.controller.id}"
              [schema]="element"
              routerLink="/controller/{{element.id}}">
            </app-controller-element>

            <app-group-controller-element
              *ngIf="element._type == 'ControllerGroupSchema'"
              [schema]="element">
            </app-group-controller-element>
          </div>
        </div>
      </div>
      <app-controller-content
        *ngIf="userService.controllerSelected && userService.controllerSelected.socket"
        [controller]="userService?.controllerSelected">
      </app-controller-content>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="mat-sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="addDirectory()" mat-menu-item>Add directory</button>
  </ng-template>
</mat-menu>

