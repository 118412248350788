import {ExchangeTypeEnum} from "@protocol/enums/ExchangeTypeEnum";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ExchangeOkMultiCraftMessage} from "@protocol/messages/ExchangeOkMultiCraftMessage";
import {ExchangeIsReadyMessage} from "@protocol/messages/ExchangeIsReadyMessage";
import {
  ExchangeStartOkCraftWithInformationMessage
} from "@protocol/messages/ExchangeStartOkCraftWithInformationMessage";
import {ExchangeStartOkMulticraftCustomerMessage} from "@protocol/messages/ExchangeStartOkMulticraftCustomerMessage";
import {ExchangeStartOkMulticraftCrafterMessage} from "@protocol/messages/ExchangeStartOkMulticraftCrafterMessage";
import {NpcDialogCreationMessage} from "@protocol/messages/NpcDialogCreationMessage";
import {ExchangeStartOkMountMessage} from "@protocol/messages/ExchangeStartOkMountMessage";
import {DocumentReadingBeginMessage} from "@protocol/messages/DocumentReadingBeginMessage";
import {LeaveDialogMessage} from "@protocol/messages/LeaveDialogMessage";
import {InteractiveUsedMessage} from "@protocol/messages/InteractiveUsedMessage";
import {StorageKamasUpdateMessage} from "@protocol/messages/StorageKamasUpdateMessage";
import {ExchangeStartedWithStorageMessage} from "@protocol/messages/ExchangeStartedWithStorageMessage";
import {StorageInventoryContentMessage} from "@protocol/messages/StorageInventoryContentMessage";
import {ExchangeStartOkNpcTradeMessage} from "@protocol/messages/ExchangeStartOkNpcTradeMessage";
import {ExchangeStartedMessage} from "@protocol/messages/ExchangeStartedMessage";
import {ExchangeStartedWithPodsMessage} from "@protocol/messages/ExchangeStartedWithPodsMessage";
import {ExchangeStartOkNpcShopMessage} from "@protocol/messages/ExchangeStartOkNpcShopMessage";
import {ExchangeStartedBidSellerMessage} from "@protocol/messages/ExchangeStartedBidSellerMessage";
import {ExchangeStartedBidBuyerMessage} from "@protocol/messages/ExchangeStartedBidBuyerMessage";
import {ExchangeLeaveMessage} from "@protocol/messages/ExchangeLeaveMessage";
import {ExchangeObjectAddedMessage} from "@protocol/messages/ExchangeObjectAddedMessage";
import {ExchangeObjectRemovedMessage} from "@protocol/messages/ExchangeObjectRemovedMessage";
import {ExchangeKamaModifiedMessage} from "@protocol/messages/ExchangeKamaModifiedMessage";
import {StorageObjectRemoveMessage} from "@protocol/messages/StorageObjectRemoveMessage";
import {StorageObjectUpdateMessage} from "@protocol/messages/StorageObjectUpdateMessage";
import {StorageObjectsUpdateMessage} from "@protocol/messages/StorageObjectsUpdateMessage";
import {StorageObjectsRemoveMessage} from "@protocol/messages/StorageObjectsRemoveMessage";
import {ExchangeObjectModifiedMessage} from "@protocol/messages/ExchangeObjectModifiedMessage";
import {ObjectModifiedMessage} from "@protocol/messages/ObjectModifiedMessage";

export class BotGameExchange {
  public isCrafter = false;
  public paymentPrice = 0;
  public isMultipleCraft: boolean;
  public countReplay: any;
  public exchangeReady: any = {};
  public initiatorId: number;
  public otherId: number;
  public type: ExchangeTypeEnum;
  public replayCount: number;
  public autoCraftRemaining: number;
  public listExchangeItemsRemote: ObjectItem[] = [];
  public listExchangeItems: ObjectItem[] = [];
  public listExchangeItemsStorage: ObjectItem[] = [];
  public lastUsedElementID: number;
  public kamasExchangeRemote = 0;
  public kamasExchange = 0;
  public step: number;
  public itemsVendor: ObjectItem [] = [];
  public otherCharacterId: number;
  public sourceExchange: number;
  public kamasBank: number;
  public itemsBank: ObjectItem[] = [];
  public exchangeWithBank = false;
  public kamasStorage = 0;
  public secondCharacterWeight: number;
  public secondCharacterMaxWeight: number;
  public startExchange: number;
  public skillId: number;
  public skillName: string;
  public skillParentJobId: number;
  private bot: Bot;

  public constructor(bot: Bot, exchange: BotGameExchange) {
    this.bot = bot;
    this.monitorPackets();
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("BankContentMessage", (packet: any) => {
      this.itemsBank = packet.itemsBank;
      this.kamasBank = packet.kamasBank;
    });
    wrapper.addMonitorPacket("ExchangeContentMessage", (packet: any) => {
      this.listExchangeItemsRemote = packet.listExchangeItemsRemote;
      this.listExchangeItems = packet.listExchangeItems;
      this.listExchangeItemsStorage = packet.listExchangeItemsStorage;
      this.kamasExchangeRemote = packet.kamasExchangeRemote;
      this.kamasExchange = packet.kamasExchange;
      this.step = packet.step;
      this.type = packet.type;
      this.skillId = packet.skillId;
      this.skillName = packet.skillName;
      this.skillParentJobId = packet.skillParentJobId;
    });


    wrapper.addMonitorPacket([
      "ExchangeObjectAddedMessage",
      "ExchangeObjectModifiedMessage",
      "ExchangeObjectRemovedMessage",
      "ExchangeGoldPaymentForCraftMessage",
      "ExchangeItemPaymentForCraftMessage",
      "ExchangeModifiedPaymentForCraftMessage",
      "ExchangeReadyMessage",
      "ExchangeRemovedPaymentForCraftMessage",
      "ExchangeClearPaymentForCraftMessage",
      "ExchangeKamaModifiedMessage"
    ], () => {
      this.step++;
    }, 200);
    wrapper.addMonitorPacket("ExchangeOkMultiCraftMessage", (packet: ExchangeOkMultiCraftMessage) => {
      this.initiatorId = packet.initiatorId;
      this.otherId = packet.otherId;
    });
    wrapper.addMonitorPacket("ExchangeIsReadyMessage", (packet: ExchangeIsReadyMessage) => {
      this.exchangeReady[packet.id] = packet.ready;
    });
    wrapper.addMonitorPacket("ExchangeStartOkCraftWithInformationMessage", (packet: ExchangeStartOkCraftWithInformationMessage) => {
      this.step = 0;
      this.paymentPrice = 0;
      this.countReplay = 1;
      this.isCrafter = true;
      this.exchangeReady = {};
      this.isMultipleCraft = false;
      this.type = ExchangeTypeEnum.CRAFT;
      this.listExchangeItems = [];

    });
    wrapper.addMonitorPacket("ExchangeStartOkMulticraftCustomerMessage", (packet: ExchangeStartOkMulticraftCustomerMessage) => {
      this.step = 0;
      this.paymentPrice = 0;
      this.countReplay = 1;
      this.isCrafter = false;
      this.exchangeReady = {};
      this.isMultipleCraft = true;
      this.type = ExchangeTypeEnum.CRAFT;

    });
    wrapper.addMonitorPacket("ExchangeReplayCountModifiedMessage", (packet: any) => {
      this.countReplay = packet.count;

    });
    wrapper.addMonitorPacket("ExchangeStartOkMulticraftCrafterMessage", (packet: ExchangeStartOkMulticraftCrafterMessage) => {
      this.step = 0;
      this.paymentPrice = 0;
      this.isCrafter = true;
      this.exchangeReady = {};
      this.countReplay = 1;
      this.isMultipleCraft = true;
      this.type = ExchangeTypeEnum.CRAFT;

    });
    wrapper.addMonitorPacket("ExchangeGoldPaymentForCraftMessage", (packet: any) => {
      this.paymentPrice = packet.goldSum;

    });

    wrapper.addMonitorPacket("NpcDialogCreationMessage", (packet: NpcDialogCreationMessage) => {
      this.type = ExchangeTypeEnum.NPC_TRADE;
    });

    wrapper.addMonitorPacket("ExchangeStartOkMountMessage", (packet: ExchangeStartOkMountMessage) => {
      this.type = ExchangeTypeEnum.MOUNT_STABLE;
    });
    wrapper.addMonitorPacket("DocumentReadingBeginMessage", (packet: DocumentReadingBeginMessage) => {
      // this.type = ExchangeTypeEnum.DOCUMENT_READING;
    });
    wrapper.addMonitorPacket("LeaveDialogMessage", (packet: LeaveDialogMessage) => {
      this.type = ExchangeTypeEnum.UNKNOWN_TRADE_TYPE;
    });
    wrapper.addMonitorPacket([
      "ExchangeStartOkMountWithOutPaddockMessage",
      "ExchangeStartOkMountMessage"
    ], () => {
      this.type = ExchangeTypeEnum.MOUNT_STABLE;
    });

    wrapper.addMonitorPacket("InteractiveUsedMessage", (packet: InteractiveUsedMessage) => {
      if (packet.entityId === this.bot.game.character.id) {
        this.lastUsedElementID = packet.elemId;
      }
    });
    wrapper.addMonitorPacket("StorageKamasUpdateMessage", (packet: StorageKamasUpdateMessage) => {
      this.kamasStorage = packet.kamasTotal;
      if (this.exchangeWithBank) {
        this.kamasBank = packet.kamasTotal;
      }

    });

    wrapper.addMonitorPacket("ExchangeStartedWithStorageMessage", (packet: ExchangeStartedWithStorageMessage) => {
      this.exchangeWithBank = packet.storageMaxSlot > 1000;
      this.type = packet.exchangeType;
      this.replayCount = 1;
      this.step = 0;
      this.listExchangeItems = [];
      this.listExchangeItemsRemote = [];
      this.listExchangeItemsStorage = [];
    });

    wrapper.addMonitorPacket("StorageInventoryContentMessage", (packet: StorageInventoryContentMessage) => {
      this.listExchangeItemsStorage = packet.objects;
      this.kamasStorage = packet.kamas;
      if (this.exchangeWithBank) {
        this.itemsBank = packet.objects;
        this.kamasBank = packet.kamas;
      }
    });
    wrapper.addMonitorPacket("ExchangeStartOkNpcTradeMessage", (packet: ExchangeStartOkNpcTradeMessage) => {
      this.type = ExchangeTypeEnum.NPC_TRADE;
      this.replayCount = 1;
      this.step = 0;
      this.listExchangeItems = [];
      this.listExchangeItemsRemote = [];
      this.listExchangeItemsStorage = [];
    });
    wrapper.addMonitorPacket("ExchangeStartedMessage", (packet: ExchangeStartedMessage) => {
      this.type = ExchangeTypeEnum.PLAYER_TRADE;
      this.kamasExchange = 0;
      this.replayCount = 1;
      this.step = 0;
      this.exchangeReady = {};
      this.listExchangeItems = [];
      this.listExchangeItemsRemote = [];
      this.listExchangeItemsStorage = [];

    });
    wrapper.addMonitorPacket("ExchangeStartedWithPodsMessage", (packet: ExchangeStartedWithPodsMessage) => {
      this.type = ExchangeTypeEnum.PLAYER_TRADE;
      this.startExchange = Date.now();
      this.kamasExchange = 0;
      this.replayCount = 1;
      this.step = 0;
      this.exchangeReady = {};
      this.listExchangeItems = [];
      this.listExchangeItemsRemote = [];
      this.listExchangeItemsStorage = [];
      this.sourceExchange = packet.firstCharacterId;
      if ((packet.firstCharacterId === this.bot.game.character.id)) {
        this.otherCharacterId = packet.secondCharacterId;
        this.secondCharacterWeight = packet.secondCharacterCurrentWeight;
        this.secondCharacterMaxWeight = packet.secondCharacterMaxWeight;
      } else {
        this.otherCharacterId = packet.firstCharacterId;
        this.secondCharacterWeight = packet.firstCharacterCurrentWeight;
        this.secondCharacterMaxWeight = packet.firstCharacterMaxWeight;
      }
    });
    wrapper.addMonitorPacket("ExchangeStartOkNpcShopMessage", (packet: ExchangeStartOkNpcShopMessage) => {
      this.type = ExchangeTypeEnum.NPC_SHOP;
    });

    wrapper.addMonitorPacket("ExchangeStartedBidSellerMessage", (packet: ExchangeStartedBidSellerMessage) => {
      this.type = ExchangeTypeEnum.BIDHOUSE_SELL;
    });
    wrapper.addMonitorPacket("ExchangeStartedBidBuyerMessage", (packet: ExchangeStartedBidBuyerMessage) => {
      this.type = ExchangeTypeEnum.BIDHOUSE_BUY;
    });
    wrapper.addMonitorPacket("ExchangeLeaveMessage", (packet: ExchangeLeaveMessage) => {
      this.exchangeWithBank = false;
      this.type = ExchangeTypeEnum.UNKNOWN_TRADE_TYPE;
      this.replayCount = -1;
      this.step = 0;
      this.listExchangeItems = [];
      this.listExchangeItemsRemote = [];
      this.listExchangeItemsStorage = [];
      this.kamasStorage = 0;
      this.sourceExchange = null;
      this.otherCharacterId = null;

    });
    wrapper.addMonitorPacket("ExchangeObjectAddedMessage", (packet: ExchangeObjectAddedMessage) => {
      if (!this.listExchangeItems) {
        this.listExchangeItems = [];
      }
      if (packet.remote) {
        this.listExchangeItemsRemote.push(packet.object);
      } else {
        this.listExchangeItems.push(packet.object);
      }
    });
    wrapper.addMonitorPacket("ExchangeObjectRemovedMessage", (packet: ExchangeObjectRemovedMessage) => {
      if (packet.remote) {
        const itemIndex = this.listExchangeItemsRemote.findIndex(item => item.objectUID === packet.objectUID);
        if (itemIndex > -1) {
          this.listExchangeItemsRemote.splice(itemIndex, 1);
        }
      } else {
        const itemIndex = this.listExchangeItems.findIndex(item => item.objectUID === packet.objectUID);
        if (itemIndex > -1) {
          this.listExchangeItems.splice(itemIndex, 1);
        }
      }

    });
    wrapper.addMonitorPacket("ObjectModifiedMessage", (packet: ObjectModifiedMessage) => {
      let item = this.listExchangeItemsRemote.find(elt => elt.objectUID == packet.object.objectUID);
      if (!item) item = this.listExchangeItems.find(elt => elt.objectUID == packet.object.objectUID);
      if (item) {
        item.effects = packet.object.effects;
        item.quantity = packet.object.quantity;
      }
    });
    wrapper.addMonitorPacket("ExchangeObjectModifiedMessage", (packet: ExchangeObjectModifiedMessage) => {
      if (packet.remote) {
        const itemIndex = this.listExchangeItemsRemote.findIndex(item => item.objectUID === packet.object.objectUID);
        if (itemIndex > -1) {
          this.listExchangeItemsRemote.splice(itemIndex, 1);
        }
        this.listExchangeItemsRemote.push(packet.object);
      } else {
        const itemIndex = this.listExchangeItems.findIndex(item => item.objectUID === packet.object.objectUID);
        if (itemIndex > -1) {
          this.listExchangeItems.splice(itemIndex, 1);
        }
        this.listExchangeItems.push(packet.object);
      }
    });


    wrapper.addMonitorPacket("ExchangeKamaModifiedMessage", (packet: ExchangeKamaModifiedMessage) => {
      if (packet.remote) {
        this.kamasExchangeRemote = packet.quantity;
      } else {
        this.kamasExchange = packet.quantity;
      }
    });

    wrapper.addMonitorPacket("StorageObjectRemoveMessage", (packet: StorageObjectRemoveMessage) => {
      if (this.exchangeWithBank) {
        this.itemsBank = this.itemsBank.filter((elt) => elt.objectUID !== packet.objectUID);
      }
      this.listExchangeItemsStorage = this.listExchangeItemsStorage.filter((elt) => elt.objectUID !== packet.objectUID);
    });

    wrapper.addMonitorPacket("StorageObjectUpdateMessage", (packet: StorageObjectUpdateMessage) => {
      if (this.exchangeWithBank) {
        this.itemsBank = this.itemsBank.filter((elt) => elt.objectUID !== packet.object.objectUID);
        this.itemsBank.push(packet.object);
      }
      this.listExchangeItemsStorage = this.listExchangeItemsStorage.filter((elt) => elt.objectUID !== packet.object.objectUID);
      this.listExchangeItemsStorage.push(packet.object);
    });

    wrapper.addMonitorPacket("StorageObjectsUpdateMessage", (packet: StorageObjectsUpdateMessage) => {
      if (this.exchangeWithBank) {
        this.itemsBank = this.itemsBank.filter(
          (elt) => !packet.objectList.find((eltPacket) => eltPacket.objectUID === elt.objectUID));
        this.itemsBank.push(...packet.objectList);
      }
      this.listExchangeItemsStorage = this.listExchangeItemsStorage.filter(
        (elt) => !packet.objectList.find((eltPacket) => eltPacket.objectUID === elt.objectUID));
      this.listExchangeItemsStorage.push(...packet.objectList);
    });

    wrapper.addMonitorPacket("StorageObjectsRemoveMessage", (packet: StorageObjectsRemoveMessage) => {
      if (this.exchangeWithBank) {
        this.itemsBank = this.itemsBank.filter(
          (elt) => !packet.objectUIDList.find((eltPacket) => eltPacket === elt.objectUID));
      }
      this.listExchangeItemsStorage = this.listExchangeItemsStorage.filter(
        (elt) => !packet.objectUIDList.find((eltPacket) => eltPacket === elt.objectUID));
    });

  }
}
