import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";


export class BotNetwork {
  public connected: boolean;
  public instanceConnected: boolean;
  public packets: {
    date: Date,
    data: any,
    source: string
  }[] = [];
  public listen: boolean;
  private bot: Bot;

  public constructor(bot: Bot, botNetwork: BotNetwork) {
    this.bot = bot;
    this.connected = botNetwork.connected;
    this.instanceConnected = botNetwork.instanceConnected;
    this.listen = botNetwork.listen;
    this._activeListenPackets = botNetwork.activeListenPackets;
    this.monitorPackets();
  }

  private _activeListenPackets: boolean;

  get activeListenPackets(): boolean {
    return this._activeListenPackets;
  }

  set activeListenPackets(value: boolean) {
    if (this._activeListenPackets != value) {
      this._activeListenPackets = value;
    }
  }

  setListenPackets(listen: boolean) {
    this.bot.send("ListenNetworkRequestMessage", {
      listen
    });
  }

  clearPackets() {
    this.bot.send("ClearNetworkPacketsRequestMessage", {});
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("PacketNetworkInformationsMessage", (packet: any) => {
      this.packets = packet.packets;
    });
    wrapper.addMonitorPacket("PacketNetworkAddedMessage", (packet: any) => {
      this.packets.push(packet.packet);
      if (this.packets.length > 300) {
        this.packets.shift();
      }
    });
    wrapper.addMonitorPacket("BotSocketStateUpdatedMessage", (packet: any) => {
      this.connected = packet.connected;
      this.bot.isBan = packet.isBan;
    });
    wrapper.addMonitorPacket("BotInstanceSocketStateUpdated", (packet: any) => {
      this.instanceConnected = packet.connected;
    });

    wrapper.addMonitorPacket("ListenNetworkUpdatedMessage", (packet: any) => {
      this.listen = packet.listen;
    });
  }
}
