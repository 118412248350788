<button (contextmenu)="onContextMenu($event)" class="button-router" mat-button>
  <mat-icon>description</mat-icon>
  <span class="label">{{script.name}}</span>
  <span class="fake-div"></span>
</button>
<div class="line-count">{{linesCount }} lignes</div>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="userService.user.scripts.deleteScript(script)" mat-menu-item>Supprimer le script</button>
  </ng-template>
</mat-menu>


<!--<mat-menu #moveScript="matMenu" [overlapTrigger]="false">-->
<!--  <span *ngFor="let child of script">-->
<!--    &lt;!&ndash; Handle branch node menu items &ndash;&gt;-->
<!--    <span *ngIf="child.children && child.children.length > 0">-->
<!--      <button [matMenuTriggerFor]="menu.childMenu" color="primary" mat-menu-item>-->
<!--        <mat-icon>{{child.iconName}}</mat-icon>-->
<!--        <span>{{child.displayName}}</span>-->
<!--      </button>-->
<!--      <app-menu-item #menu [items]="child.children"></app-menu-item>-->
<!--    </span>-->
<!--    &lt;!&ndash; Handle leaf node menu items &ndash;&gt;-->
<!--    <span *ngIf="!child.children || child.children.length === 0">-->
<!--      <button [routerLink]="child.route" mat-menu-item>-->
<!--        <mat-icon>{{child.iconName}}</mat-icon>-->
<!--        <span>{{child.displayName}}</span>-->
<!--      </button>-->
<!--    </span>-->
<!--  </span>-->
<!--</mat-menu>-->
