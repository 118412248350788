import {Component} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-add-bot-instance-dialog",
  templateUrl: "./add-bot-instance-dialog.component.html",
  styleUrls: ["./add-bot-instance-dialog.component.scss"]
})
export class AddBotInstanceDialogComponent {

  public formGroup = new UntypedFormGroup({
    alias: new UntypedFormControl("", []),
    login: new UntypedFormControl("", [Validators.required]),
    password: new UntypedFormControl("", [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<AddBotInstanceDialogComponent>) {

  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        alias: this.formGroup.get("alias").value,
        login: this.formGroup.get("login").value,
        password: this.formGroup.get("password").value
      });
    }
  }

  public close() {
    this.dialogRef.close(null);
  }
}
