import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {InventoryContentMessage} from "@protocol/messages/InventoryContentMessage";
import {InventoryWeightMessage} from "@protocol/messages/InventoryWeightMessage";
import {ObjectAddedMessage} from "@protocol/messages/ObjectAddedMessage";
import {ObjectDeletedMessage} from "@protocol/messages/ObjectDeletedMessage";
import {ObjectModifiedMessage} from "@protocol/messages/ObjectModifiedMessage";
import {ObjectMovementMessage} from "@protocol/messages/ObjectMovementMessage";
import {ObjectQuantityMessage} from "@protocol/messages/ObjectQuantityMessage";
import {ObjectsAddedMessage} from "@protocol/messages/ObjectsAddedMessage";
import {ObjectsDeletedMessage} from "@protocol/messages/ObjectsDeletedMessage";
import {ObjectsQuantityMessage} from "@protocol/messages/ObjectsQuantityMessage";
import {KamasUpdateMessage} from "@protocol/messages/KamasUpdateMessage";
import {ObjectItem} from "@protocol/types/ObjectItem";

export class GameCharacterInventory {
  public objects: ObjectItem[] = [];
  public kamas: number;
  public weightMax: number;
  public weight: number;
  public pourcentPods = 0;
  private bot: Bot;

  public constructor(bot: Bot, gameCharacterInventory: GameCharacterInventory) {
    this.bot = bot;
    this.objects = gameCharacterInventory.objects || [];
    this.kamas = gameCharacterInventory.kamas || 0;
    this.weight = gameCharacterInventory.weight || 0;
    this.weightMax = gameCharacterInventory.weightMax || 0;
    this.monitorPackets();
  }

  public getObjectsByTypeId(typeId: number) {
    // @ts-ignore
    return this.objects.filter(elt => elt["_typeId"] === typeId);
  }

  public deleteItem(objectUID: number) {
    this.bot.send("DeleteItemRequestMessage", {
      objectUID
    });
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket([
      "InventoryContentMessage",
      "InventoryContentAndPresetMessage"
    ], (packet: InventoryContentMessage) => {
      this.objects = packet.objects;
      this.kamas = packet.kamas;
      this.bot.emit("InventoryUpdated");
      this.bot.controller.user.emit("BotInventoryUpdated");
    });
    wrapper.addMonitorPacket("InventoryWeightMessage", (packet: InventoryWeightMessage) => {
      this.weightMax = packet.weightMax;
      this.weight = packet.inventoryWeight;
      if (this.weightMax && this.weight) {
        this.pourcentPods = parseFloat(Math.min(this.weight / this.weightMax * 100, 100).toFixed(2));
      } else this.pourcentPods = 0;
    });
    wrapper.addMonitorPacket("KamasUpdateMessage", (packet: KamasUpdateMessage) => {
      this.kamas = packet.kamasTotal;
    });
    wrapper.addMonitorPacket("ObjectAddedMessage", (packet: ObjectAddedMessage) => {
      this.objects.push(packet.object);
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectDeletedMessage", (packet: ObjectDeletedMessage) => {
      for (const i in this.objects) {
        const object = this.objects[i];
        if (object.objectUID === packet.objectUID) {
          this.objects.splice(parseInt(i), 1);
          break;
        }
      }
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectModifiedMessage", (packet: ObjectModifiedMessage) => {
      const index = this.objects.findIndex(elem => elem.objectUID === packet.object.objectUID);
      this.objects[index] = packet.object;
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectMovementMessage", (packet: ObjectMovementMessage) => {
      const index = this.objects.findIndex(elem => elem.objectUID === packet.objectUID);
      this.objects[index].position = packet.position;
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectQuantityMessage", (packet: ObjectQuantityMessage) => {
      const index = this.objects.findIndex(elem => elem.objectUID === packet.objectUID);
      this.objects[index].quantity = packet.quantity;
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectsAddedMessage", (packet: ObjectsAddedMessage) => {
      for (const object of packet.object) {
        this.objects.push(object);
      }
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectsDeletedMessage", (packet: ObjectsDeletedMessage) => {
      for (const UID of packet.objectUID) {
        const index = this.objects.findIndex(elem => elem.objectUID === UID);
        this.objects.splice(index, 1);
      }
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("ObjectsQuantityMessage", (packet: ObjectsQuantityMessage) => {
      for (const object of packet.objectsUIDAndQty) {
        const index = this.objects.findIndex(elem => elem.objectUID === object.objectUID);
        this.objects[index].quantity = object.quantity;
      }
      this.bot.emit("InventoryUpdated");
    });
    wrapper.addMonitorPacket("KamasUpdateMessage", (packet: KamasUpdateMessage) => {
      this.kamas = packet.kamasTotal;
    });
  }
}
