import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {CustomDispatcher, CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {Utils} from "@common/lib/utils";
import {ChatServerMessage} from "@common/lib/network/packets/ChatServerMessage";
import {ChatServerTypeEnum} from "@common/lib/network/enums/ChatServerTypeEnum";

@Component({
  selector: "app-custom-console",
  templateUrl: "./custom-console.component.html",
  styleUrls: ["./custom-console.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CustomConsoleComponent implements OnInit, OnChanges {
  @ViewChild("listLog") listLog: any;
  @Output() submitEvent = new EventEmitter();
  @Input() public logs: ChatServerMessage[] = [];
  @Input() public customDispatcher: CustomDispatcher;
  public customWrapper: CustomWrapper;
  public formConsole: UntypedFormGroup;
  public showHour = true;
  public showDate = true;
  public showChannel = [
    {channel: 6, name: "Recrutement", show: false},
    {channel: 5, name: "Commerce", show: false},
    {channel: 7, name: "General", show: true},
    {channel: ChatServerTypeEnum.INFO, name: "Informations", show: true},
    {channel: ChatServerTypeEnum.DEBUG, name: "Debug", show: true}
  ];


  constructor(private cd: ChangeDetectorRef) {
    // this.cd.detach();
  }

  ngOnInit() {
    this.formConsole = new UntypedFormGroup({
      input: new UntypedFormControl()
    });
    this.cd.detectChanges();
    // this.formConsole.get('showHour').valueChanges.subscribe((value) => this.showHour = value);
    Utils.sleep(100).then(() => {
      this.listLog.nativeElement.scrollTop = this.listLog.nativeElement.scrollHeight + 200;
    });
  }

  public canShow(log: ChatServerMessage) {
    const channel = this.showChannel.find((elt) => elt.channel === log.channel);
    if (!channel) {
      return true;
    } else {
      return channel && channel.show;
    }
  }

  public changeShowOrigin(channelId: number, event: MatCheckboxChange) {
    const channel = this.showChannel.find((elt) => elt.channel === channelId);
    if (channel) {
      channel.show = event.checked;
    }
  }

  public submit() {
    const command = this.formConsole.get("input").value;
    this.formConsole.reset();
    this.submitEvent.emit(command);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customWrapper) this.customWrapper.done();
    this.customWrapper = this.customDispatcher.wrap();
    this.customWrapper.addMonitorPacket("NewLog", () => {
      this.cd.detectChanges();
      console.log("detect change");
      this.listLog.nativeElement.scrollTop = this.listLog.nativeElement.scrollHeight;
    });
    if (this.listLog) this.listLog.nativeElement.scrollTop = this.listLog.nativeElement.scrollHeight + 200;
  }
}
