<div>
  <app-bot-fm-history></app-bot-fm-history>
</div>
<div class="bot-fm-middle">
  <app-bot-fm-exchange></app-bot-fm-exchange>
  <app-bot-fm-effects></app-bot-fm-effects>
</div>
<div>
  <app-bot-fm-inventory></app-bot-fm-inventory>
</div>
