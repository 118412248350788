import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewContainerRef} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {FormControl, FormGroup} from "@angular/forms";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";

@Component({
  selector: "app-bot-inventory-content",
  templateUrl: "./bot-inventory-content.component.html",
  styleUrls: ["./bot-inventory-content.component.scss"]
})
export class BotInventoryContentComponent implements AfterViewInit, OnDestroy {
  public currentItem: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public bot: Bot;
  public displayedColumns: string[] = ["image", "quantity", "name", "gid", "actions"];
  public dataSource: MatTableDataSource<any>;
  public pathImages = `http://server.myeasydev.be:7998/images/items/`;
  public formGroupSearch = new FormGroup({
    name: new FormControl()
  });
  public items: ObjectItem[] = [];
  protected readonly JSON = JSON;
  private overlayRef: OverlayRef;
  private userWrapper: CustomWrapper;
  private botWrapper: CustomWrapper;
  private hideTimeout: any; // Pour stocker le timeout


  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {
    this.userWrapper = userService.user.wrap();
    this.bot = userService.botSelected;
    this.formGroupSearch.controls.name.valueChanges.subscribe(change => {
      this.updateData();
    });
    this.userWrapper.addMonitorPacket(["BotSelectedUpdated", "BotInventoryUpdated"], () => {
      this.updateData();
    });
  }

  ngOnInit(): void {
  }

  showTooltip(event: MouseEvent, item: any, tooltipTemplate: TemplateRef<any>) {
    this.currentItem = item;

    // Annuler le timeout de masquage s'il y en a un en cours
    clearTimeout(this.hideTimeout);

    // Détacher tout tooltip existant
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    // Stratégie de positionnement flexible avec gestion des bords du viewport
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(event.target as HTMLElement)
      .withFlexibleDimensions(false) // Ne redimensionne pas le tooltip
      .withViewportMargin(8) // Ajoute une marge pour éviter les bords de la fenêtre
      .withPush(true) // Pousse le tooltip dans le viewport s'il est hors écran
      .withPositions([
        {originX: "center", originY: "top", overlayX: "center", overlayY: "bottom", offsetY: -10}, // Affichage au-dessus
        {originX: "center", originY: "bottom", overlayX: "center", overlayY: "top", offsetY: 10} // Affichage en dessous si manque d'espace
      ]);

    // Créer l'overlay s'il n'existe pas encore
    this.overlayRef = this.overlay.create({positionStrategy});

    // Attacher le tooltip au nouvel overlay
    const tooltipPortal = new TemplatePortal(tooltipTemplate, this.viewContainerRef);
    this.overlayRef.attach(tooltipPortal);
  }


  hideTooltip() {
    // Ajouter un délai avant de cacher le tooltip
    this.hideTimeout = setTimeout(() => {
      if (this.overlayRef) {
        this.overlayRef.detach();
      }
    }, 200); // Ajuster ce délai selon tes besoins
  }


  clearHideTimeout() {
    // Annuler le timeout de masquage
    clearTimeout(this.hideTimeout);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.bot.game.character.inventory.objects);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("InventoryUpdated", () => {
      this.updateData();
    });
  }

  ngOnDestroy(): void {
    this.userWrapper.done();
  }

  getTooltipContent(element: ObjectItem) {
    return `
      <div>
        <strong>Nom:</strong> 0 <br>
        <strong>Puissance:</strong> 1 <br>
        <!-- Autres caractéristiques de l'objet -->
      </div>
    `;
  }

  private updateData() {
    const searchText = this.formGroupSearch.controls.name.value;
    const items = searchText?.length > 0 ?
      this.bot.game.character.inventory.objects?.filter((elt: any) => elt._name.toLowerCase().indexOf(searchText.toLowerCase()) != -1)
      : this.bot.game.character.inventory.objects || [];
    this.dataSource.data = items;
    this.dataSource.paginator = this.paginator;
  }

}
