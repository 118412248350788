import {Component} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";
import {DatePipe, NgIf} from "@angular/common";

@Component({
  selector: "app-bot-stats-fight",
  standalone: true,
  imports: [
    DatePipe,
    NgIf
  ],
  templateUrl: "./bot-stats-fight.component.html",
  styleUrl: "./bot-stats-fight.component.scss"
})
export class BotStatsFightComponent {
  public bot: Bot;

  constructor(
    private userService: UserService
  ) {
    this.bot = userService.botSelected;
  }

}
