import {Component, OnDestroy, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";
import {FormControl} from "@angular/forms";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";

@Component({
  selector: "app-bot-network",
  templateUrl: "./bot-network.component.html",
  styleUrls: ["./bot-network.component.scss"]
})
export class BotNetworkComponent implements OnInit, OnDestroy {
  public bot: Bot;
  public packetSelected = {data: {}};
  public formListen = new FormControl();
  public wrapper: CustomWrapper;

  constructor(private userService: UserService) {
    this.bot = userService.botSelected;
    this.wrapper = this.bot.wrap();
  }

  ngOnInit(): void {
    this.formListen.setValue(this.bot.network.listen);
    this.formListen.valueChanges.subscribe(elt => {
      this.bot.network.setListenPackets(this.formListen.value);
    });
    this.wrapper.addMonitorPacket("ListenNetworkUpdatedMessage", () => {
      if (this.bot.network.listen != this.formListen.value) {
        this.formListen.setValue(this.bot.network.listen);
      }
    }, 5000);
  }

  ngOnDestroy() {
    this.wrapper.done();
  }

  selectPacket(packet: any) {
    this.packetSelected = packet;
  }

  clearPackets() {
    this.bot.network.clearPackets();
  }
}
