import {Component, Input, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {AuthenticationService} from "@dashboard/src/app/service/authentication.service";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Router} from "@angular/router";
import packageInfo from "../../../../package.json";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent implements OnInit {
  public version: string = packageInfo.version;

  @Input() toggleSidenavEventObservable: Subject<void>;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              public userService: UserService) {
  }

  ngOnInit(): void {
  }

  async disconnect() {
    localStorage.removeItem("nezudash-token");
    this.userService.socket.disconnect();
    await this.router.navigateByUrl("");
  }

  emitEventToSidenav() {
    this.toggleSidenavEventObservable.next();
  }
}
