import {Component, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProxyInformations} from "@common/lib/models/user/proxies/proxy";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: "app-proxies",
  templateUrl: "./proxies.component.html",
  styleUrls: ["./proxies.component.scss"]
})
export class ProxiesComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public displayedColumns: string[] = ["url", "actualIp", "upTime", "operator", "state", "actions"];
  public dataSource: MatTableDataSource<any>;

  public form = new FormGroup({
    proxy: new FormControl("", Validators.required)
  });

  constructor(
    private route: ActivatedRoute,
    public userService: UserService,
    private clipboard: Clipboard
  ) {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.userService.user.proxies.proxies);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const wrapper = this.userService.user.wrap();
    wrapper.addMonitorPacket("ProxiesUpdated", () => {
      this.updateData();
    });
  }

  private async updateData() {
    this.dataSource.data = this.userService.user.proxies.proxies;
    this.dataSource.paginator = this.paginator;
  }

  addProxy() {
    if (this.form.valid) {
      const proxy = this.form.get("proxy").value;
      this.userService.user.proxies.addProxy(proxy);
    }
  }

  public copyProxy(proxy: ProxyInformations) {
    this.clipboard.copy(proxy.url);
  }
}
