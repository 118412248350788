import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import { TextFieldModule } from "@angular/cdk/text-field";


@NgModule({
  declarations: [
    DialogQuestionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    TextFieldModule

  ]
})
export class DialogQuestionModule {
}
