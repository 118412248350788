import {ProxyServer} from "@dashboard/src/app/service/user/proxyServers/proxyServer";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {NezuUser} from "@dashboard/src/app/service/user";

export enum CustomProxyTypeEnum {
  "ETH" = "ETH",
  "USB" = "USB"
}

export class CustomProxy extends CustomDispatcher {
  public id: string;
  public type: CustomProxyTypeEnum;
  public ssid: string;
  public wifiPassword: string;
  public publicIp: string;
  public localIp: string;
  public interfaceName: string;
  public infos: any;
  public netMode: string;
  public netStatus: string;
  public oper: string;
  public rssi: null;

  private proxyServer: ProxyServer;
  private user: NezuUser;

  public constructor(proxy: CustomProxy, proxyServer: ProxyServer, user: NezuUser) {
    super("CustomProxy");
    this.proxyServer = proxyServer;
    this.id = proxy.id;
    this.type = proxy.type;
    this.ssid = proxy.ssid;
    this.infos = proxy.infos;
    this.wifiPassword = proxy.wifiPassword;
    this.interfaceName = proxy.interfaceName;
    this.publicIp = proxy.publicIp;
    this.localIp = proxy.localIp;

    this.netMode = proxy.netMode;
    this.netStatus = proxy.netStatus;
    this.oper = proxy.oper;
    this.rssi = proxy.rssi;

    this.user = user;
  }

  public sendToSupervisor(callName: string, data: any) {
    this.user.socket.send({
      cible: "CUSTOM_PROXY",
      id: this.proxyServer.id,
      customProxyId: this.id,
      callName,
      data,
      directToCible: false
    }, "PacketWithCibleProxyServers");
  }

  public sendToProxyServer(callName: string, data: any) {
    this.user.socket.send({
      cible: "CUSTOM_PROXY",
      id: this.proxyServer.id,
      customProxyId: this.id,
      callName,
      data,
      directToCible: true
    }, "PacketWithCibleProxyServers");
  }

  renameSSID(ssid: string) {
    this.sendToProxyServer("ChangeSSIDRequestMessage", {
      ssid
    });
  }

  changeWifiPassword(wifiPassword: string) {
    this.sendToProxyServer("ChangeWifiPasswordRequestMessage", {
      wifiPassword
    });
  }

  public refreshIp() {
    this.sendToProxyServer("RefreshIpRequestMessage", {});
  }

  public rebootProxy() {
    this.sendToProxyServer("RebootRequestMessage", {});
  }

  public checkIp() {
    this.sendToProxyServer("CheckIpRequestMessage", {});
  }

  public checkInformations() {
    this.sendToProxyServer("CheckInformationsRequestMessage", {});
  }

  public enableDebugMode() {
    this.sendToProxyServer("EnableDebugModeRequestMessage", {});
  }

  updateIp() {
    this.sendToProxyServer("UpdateIpRequestMessage", {});
  }
}
