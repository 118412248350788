export enum ExchangeTypeEnum {
    UNKNOWN_TRADE_TYPE = -1,
    NPC_SHOP = 0,
    PLAYER_TRADE = 1,
    NPC_TRADE = 2,
    CRAFT = 3,
    STORAGE = 4,
    SHOP_STOCK = 5,
    TAXCOLLECTOR = 6,
    NPC_MODIFY_TRADE = 7,
    BIDHOUSE_SELL = 8,
    BIDHOUSE_BUY = 9,
    MULTICRAFT_CRAFTER = 10,
    MULTICRAFT_CUSTOMER = 11,
    JOB_INDEX = 12,
    MOUNT = 13,
    MOUNT_STABLE = 14,
    NPC_RESURECT_PET = 15,
    NPC_TRADE_DRAGOTURKEY = 16,
    REALESTATE_HOUSE = 17,
    REALESTATE_FARM = 18,
    RUNES_TRADE = 19,
    RECYCLE_TRADE = 20,
    BANK = 21,
    TRASHBIN = 22,
    ALLIANCE_PRISM = 23,
    HAVENBAG = 24,
    NPC_TRADE_SEEMYOOL = 25,
    NPC_TRADE_RHINEETLE = 26,
    EVOLUTIVE_OBJECT_ELEMENTARY_RECYCLE = 27,
    NPC_RIDE_CAPABILITY_TRADE = 28,
    GUILD_CHEST = 29
}
