import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@dashboard/src/environments/environment";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {

  constructor(private http: HttpClient,
              private userService: UserService,
              private route: ActivatedRoute) {

  }

  public getToken(login: string, password: string) {
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);
    return this.http.post(`${environment.HTTP_SERVER_ADDRESS}/login`, formData);
  }
}
