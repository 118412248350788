import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

@Component({
  selector: "app-bot-content",
  templateUrl: "./bot-content.component.html",
  styleUrls: ["./bot-content.component.scss"]
})
export class BotContentComponent implements OnInit {
  public bot: Bot;
  public activeLink = "console";

  public tabs = [
    {label: "Console", link: "console"},
    {label: "Character", link: "character"},
    {label: "Map", link: "map"},
    {label: "Network", link: "network"},
    {label: "Config", link: "config"},
    {label: "Stats", link: "stats"},
    {label: "FM", link: "fm"}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
    this.bot = this.userService.botSelected;
  }

  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {tabs: path}}], {relativeTo: this.route});
  }

  ngOnInit(): void {
    const childrenTabs = this.route.children.find(elt => elt.outlet == "tabs");
    if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
      this.activeLink = childrenTabs.snapshot.url[0].path;
    } else {
      this.activeLink = this.tabs[0].link;
    }
    this.route.data.subscribe(data => {
      this.bot = data.bot;
      // this.activeTabIndex = data.tab.indexOf("_") != -1 ? data.tab.split("_")[0] : data.tab.split;
      // this.selectedIndex = data.tab1 ? Number(data.tab1) : 0;
    });
  }

  getSelectedIndex() {

  }

}
