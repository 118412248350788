import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from "@angular/material/dialog";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";

@Component({
  selector: "app-custom-sidenav",
  templateUrl: "./custom-sidenav.component.html",
  styleUrls: ["./custom-sidenav.component.scss"]
})
export class CustomSidenavComponent implements OnInit {
  public componentTypeSelectedEnum = ComponentTypeSelectedEnum;
  @Input() toggleSidenavEventObservable: Observable<void>;
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};
  public ComponentTypeSelectedEnum = ComponentTypeSelectedEnum;
  public sidenavModeIsSide = true;
  private eventsSubscription: any;

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    public router: Router,
    private ref: ChangeDetectorRef
  ) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.sidenavModeIsSide = false;
    }
  }

  ngOnInit(): void {
    const wrapper = this.userService.user.wrap();
    wrapper.addMonitorPacket([
      "ControllerAdded",
      "ControllersInit",
      "SidenavUpdated"
    ], () => {
      console.log("SidenavUpdated");
      // this.ref.detectChanges();
    });
    this.eventsSubscription = this.toggleSidenavEventObservable.subscribe(() => {
      this.sidenav.toggle();
    });
    wrapper.addMonitorPacket("ControllerGroupSchemaUpdated", () => {
      // this.ref.detectChanges();
    });
  }

  clickOnContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    // this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  dropElement(event: CdkDragDrop<any[]>) {
    const elements = this.userService.user.controllers.schema.elements;
    elements.splice(event.currentIndex, 0, elements.splice(event.previousIndex, 1)[0]);
    this.userService.user.controllers.schema.elements = elements;
    this.userService.user.controllers.sendUpdateSchema();
  }

  addDirectory() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Add directory",
        forms: [{
          label: "Name of the directory",
          name: "name",
          type: "string",
          required: true,
          defaultValue: ""
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.userService.user.controllers.addDirectory(result.name);
      }
    });
  }
}
