import {BehaviorSubject} from "rxjs";
import {SocketAngularStateEnum} from "@dashboard/src/app/enums/SocketAngularStateEnum";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {Utils} from "@common/lib/utils";
import {IdentificationSuccessMessage} from "@common/lib/network/packets/IdentificationSuccessMessage";
import {IdentificationFailedMessage} from "@common/lib/network/packets/IdentificationFailedMessage";
import {IdentificationReasonFailedEnum} from "@common/lib/network/enums/IdentificationReasonFailedEnum";
import {BasicPingMessage} from "@common/lib/network/packets/BasicPingMessage";
import {environment} from "@dashboard/src/environments/environment";

const log = Utils.createDebug("app:dash");
const logSNT = Utils.createDebug("app:dash:SNT");
const logRCV = Utils.createDebug("app:dash:RCV");

export class WebSocketAngular extends CustomDispatcher {
  public id: string;
  public url: string;
  public socket: any;
  public state = new BehaviorSubject(SocketAngularStateEnum.NOT_CONNECTED);
  public reason: string;
  public tentative = 0;
  public otherDispatcher: CustomDispatcher;

  constructor(url: string) {
    super("socket");
    this.url = url;
    this.id = Utils.generateRandomUID();
    this.monitorPackets();
  }

  public get connected() {
    return this.socket && this.socket.readyState === 1;
  }

  public async connect(isReconnect = false) {
    log("Demande de connexion");
    if (this.connected) this.close();
    if (isReconnect) {
      this.state.next(SocketAngularStateEnum.RE_CONNECTING);
      this.tentative += 1;
    } else this.state.next(SocketAngularStateEnum.CONNECTING);
    this.socket = new WebSocket(this.url);
    this.monitorPacketsSocket();
  }

  public disconnect() {
    this.state.next(SocketAngularStateEnum.NOT_CONNECTED);
    this.close();
  }

  public send(data: any, call: string, cible?: string) {
    // logSNT(`SNT ${call} ${}`, data);
    this.socket.send(JSON.stringify({call, data}));
  }

  public close() {
    if (this.socket) this.socket.close();
    if (this.socket) this.socket.removeAllListeners();
  }

  private monitorPacketsSocket() {
    this.socket.onopen = () => {
      this.emit("open");
      if (this.otherDispatcher) {
        this.otherDispatcher.emit("open");
      }
    };
    this.socket.onclose = (close: any) => {
      this.socket.removeAllListeners();
      this.emit("close", close);
      if (this.otherDispatcher) {
        this.otherDispatcher.emit("close");
      }
    };
    this.socket.onerror = () => this.emit("error");
    this.socket.onmessage = (data: MessageEvent) => {
      const packet = JSON.parse(data.data);
      // logRCV(`RCV ${packet.call} `, packet.data);
      this.emit(packet.call, packet.data);
      if (this.otherDispatcher) {
        this.otherDispatcher.emit(packet.call, packet.data);
      }
    };
  }

  private monitorPackets() {
    const wrapper = this.wrap();
    wrapper.addMonitorPacket("BasicPingMessage", (packet: BasicPingMessage) => {
      this.send({}, "BasicPongMessage");
    });
    wrapper.addMonitorPacket("IdentificationSuccessMessage", (packet: IdentificationSuccessMessage) => {
      this.state.next(SocketAngularStateEnum.IDENTIFIED);
    });
    wrapper.addMonitorPacket("IdentificationFailedMessage", (packet: IdentificationFailedMessage) => {
      this.state.next(SocketAngularStateEnum.NOT_CONNECTED);
      this.reason = IdentificationReasonFailedEnum[packet.reason];
      localStorage.removeItem("nezudash-token");
      this.close();
    });
    wrapper.addMonitorPacket("open", () => {
      this.tentative = 0;
      const token = environment.token;
      if (!token) {
        this.state.next(SocketAngularStateEnum.NOT_CONNECTED);
        this.reason = IdentificationReasonFailedEnum[IdentificationReasonFailedEnum.ERROR_WITH_TOKEN];
        this.close();
      } else {
        this.state.next(SocketAngularStateEnum.IDENTIFICATION);
        this.send({
          token,
          type: "DASHBOARD"
        }, "IdentificationRequestMessage");
      }
    });
    wrapper.addMonitorPacket("close", (reason: any) => {
      if (environment.token && this.state.value != SocketAngularStateEnum.NOT_CONNECTED) {
        this.connect(true);
      }
    });
  }
}
