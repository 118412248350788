<div class="scripts-container">
  <div class="scripts-list">
    <div (contextmenu)="onContextMenu($event)" class="scripts-list-content">
      <mat-divider></mat-divider>
      <button class="back-button" disabled mat-button>
        ...
      </button>
      <mat-divider></mat-divider>
      <app-script-element (click)="scriptSelected = script"
                          *ngFor="let script of userService.user.scripts.scripts"
                          [script]="script">
      </app-script-element>
    </div>
  </div>
  <div *ngIf="scriptSelected" class="script-content">
    <app-script-editor [script]="scriptSelected"></app-script-editor>
  </div>
</div>
<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>
<!--<div class="scripts-footer"></div>-->
<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="addScript()" mat-menu-item>Ajouter un script</button>
  </ng-template>
</mat-menu>
