export enum StatIdsEnum {
    UNKNOWN = -1,
    LIFE_POINTS = 0,
    ACTION_POINTS = 1,
    STATS_POINTS = 3,
    SPELL_POINTS = 4,
    LEVEL = 5,
    STRENGTH = 10,
    VITALITY = 11,

    WISDOM = 12,

    CHANCE = 13,

    AGILITY = 14,

    INTELLIGENCE = 15,

    ALL_DAMAGES_BONUS = 16,

    DAMAGES_FACTOR = 17,

    CRITICAL_HIT = 18,

    RANGE = 19,

    DAMAGES_PHYSICAL_REDUCTION = 21,

    EXPERIENCE_BOOST = 22,

    MOVEMENT_POINTS = 23,

    INVISIBILITY = 24,

    DAMAGES_PERCENT = 25,

    MAX_SUMMONED_CREATURES_BOOST = 26,

    DODGE_PA_LOST_PROBABILITY = 27,

    DODGE_PM_LOST_PROBABILITY = 28,

    ENERGY_POINTS = 29,

    ALIGNMENT_VALUE = 30,

    WEAPON_DAMAGES_PERCENT = 31,

    PHYSICAL_DAMAGES_BONUS = 32,

    EARTH_ELEMENT_RESIST_PERCENT = 33,

    FIRE_ELEMENT_RESIST_PERCENT = 34,

    WATER_ELEMENT_RESIST_PERCENT = 35,

    AIR_ELEMENT_RESIST_PERCENT = 36,

    NEUTRAL_ELEMENT_RESIST_PERCENT = 37,

    DIFFERENT_LOOK = 38,

    CRITICAL_MISS = 39,

    WEIGHT = 40,

    RESTRICTION_ON_MYSELF = 41,

    RESTRICTION_ON_OTHER = 42,

    ALIGNMENT_SIDE = 43,

    INITIATIVE = 44,

    SHOP_REDUCTION_PERCENTAGE = 45,

    ALIGNMENT_RANK = 46,

    MAX_ENERGY_POINTS = 47,

    MAGIC_FIND = 48,

    HEAL_BONUS = 49,

    REFLECT_DAMAGE = 50,

    ENERGY_LOOSE = 51,

    HONOUR_POINTS = 52,

    DISHOUNOUR_POINTS = 53,

    EARTH_ELEMENT_REDUCTION = 54,

    FIRE_ELEMENT_REDUCTION = 55,

    WATER_ELEMENT_REDUCTION = 56,

    AIR_ELEMENT_REDUCTION = 57,

    NEUTRAL_ELEMENT_REDUCTION = 58,

    PVP_EARTH_ELEMENT_RESIST_PERCENT = 59,

    PVP_FIRE_ELEMENT_RESIST_PERCENT = 60,

    PVP_WATER_ELEMENT_RESIST_PERCENT = 61,

    PVP_AIR_ELEMENT_RESIST_PERCENT = 62,

    PVP_NEUTRAL_ELEMENT_RESIST_PERCENT = 63,

    PVP_EARTH_ELEMENT_REDUCTION = 64,

    PVP_FIRE_ELEMENT_REDUCTION = 65,

    PVP_WATER_ELEMENT_REDUCTION = 66,

    PVP_AIR_ELEMENT_REDUCTION = 67,

    PVP_NEUTRAL_ELEMENT_REDUCTION = 68,

    TRAP_DAMAGE_BONUS_PERCENT = 69,

    TRAP_DAMAGE_BONUS = 70,

    FAKE_SKILL_FOR_STATES = 71,

    SOUL_CAPTURE_BONUS = 72,

    RIDE_XP_BONUS = 73,

    CONFUSION = 74,

    PERMANENT_DAMAGE_PERCENT = 75,

    UNLUCKY = 76,

    MAXIMIZE_ROLL = 77,

    TACKLE_EVADE = 78,

    TACKLE_BLOCK = 79,

    ALLIANCE_AUTO_AGGRESS_RANGE = 80,

    ALLIANCE_AUTO_AGGRESS_RESISTANCE = 81,

    AP_ATTACK = 82,

    MP_ATTACK = 83,

    PUSH_DAMAGE_BONUS = 84,

    PUSH_DAMAGE_REDUCTION = 85,

    CRITICAL_DAMAGE_BONUS = 86,

    CRITICAL_DAMAGE_REDUCTION = 87,

    EARTH_DAMAGE_BONUS = 88,

    FIRE_DAMAGE_BONUS = 89,

    WATER_DAMAGE_BONUS = 90,

    AIR_DAMAGE_BONUS = 91,

    NEUTRAL_DAMAGE_BONUS = 92,

    MAX_BOMB_SUMMON = 93,

    BOMB_COMBO_BONUS = 94,

    MAX_LIFE = 95,

    SHIELD = 96,

    CUR_LIFE = 97,

    DAMAGES_PERCENT_SPELL = 98,

    EXTRA_SCALE_FLAT = 99,

    PASS_TURN = 100,

    RESIST_PERCENT = 101,

    CUR_PERMANENT_DAMAGE = 102,

    WEAPON_POWER = 103,

    INCOMING_DAMAGE_PERCENT_MULTIPLICATOR = 104,

    INCOMING_DAMAGE_HEAL_PERCENT_MULTIPLICATOR = 105,

    GLYPH_POWER = 106,

    DEALT_DAMAGE_MULTIPLIER = 107,

    STOP_XP = 108,

    HUNTER = 109,

    RUNE_POWER = 110,

    DEALT_DAMAGE_MULTIPLIER_MELEE = 125,

    DEALT_DAMAGE_MULTIPLIER_DISTANCE = 120,

    DEALT_DAMAGE_MULTIPLIER_WEAPON = 122,

    RECEIVED_DAMAGE_MULTIPLIER_MELEE = 124,

    DEALT_DAMAGE_MULTIPLIER_SPELLS = 123,

    RECEIVED_DAMAGE_MULTIPLIER_DISTANCE = 121,

    RECEIVED_DAMAGE_MULTIPLIER_WEAPON = 142,

    RECEIVED_DAMAGE_MULTIPLIER_SPELLS = 141,

    AGILITY_INITIAL_PERCENT = 126,

    STRENGTH_INITIAL_PERCENT = 127,

    CHANCE_INITIAL_PERCENT = 128,

    INTELLIGENCE_INITIAL_PERCENT = 129,

    VITALITY_INITIAL_PERCENT = 130,

    WISDOM_INITIAL_PERCENT = 131,

    TACKLE_EVADE_INITIAL_PERCENT = 132,

    TACKLE_BLOCK_INITIAL_PERCENT = 133,

    ACTION_POINTS_INITIAL_PERCENT = 134,

    MOVEMENT_POINTS_INITIAL_PERCENT = 135,

    AP_ATTACK_INITIAL_PERCENT = 136,

    MP_ATTACK_INITIAL_PERCENT = 137,

    DODGE_PA_LOST_PROBABILITY_INITIAL_PERCENT = 138,

    DODGE_PM_LOST_PROBABILITY_INITIAL_PERCENT = 139,

    EXTRA_SCALE_PERCENT = 140,

    CHARAC_COUNT = 141,
}

export function getStatsNameById(value: number) {
  for (const key in StatIdsEnum) {
    if (StatIdsEnum[key].toString() === value.toString()) {
      return key;
    }
  }
  return "??";
}
