import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

export class BotStats {
  public datas: any[] = [];
  private bot: Bot;

  public constructor(bot: Bot, stats: BotStats) {
    this.bot = bot;
    this.monitorPackets();
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("BotStatsMessage", (packet: any) => {
      this.datas = packet.datas;
      this.bot.emit("StatsUpdated");
    });
    wrapper.addMonitorPacket("BotStatsAddedMessage", (packet: any) => {
      this.datas.push(packet.data);
      this.bot.emit("StatsUpdated");
    });
  }
}
