<div class="proxies-container">
  <div class="proxies-list">
    <div (contextmenu)="onContextMenu($event)" class="proxies-list-content">
      <app-proxy-server-element (click)="setProxyServer(proxyServer)"
                                [selected]="proxyServerSelected== proxyServer"
                                *ngFor="let proxyServer of userService.user.proxyServers.proxyServers"
                                [proxyServer]="proxyServer"
                                cdkDrag>
      </app-proxy-server-element>
    </div>
  </div>
  <div *ngIf="proxyServerSelected" class="proxy-content">
    <app-proxy-server [proxyServer]="proxyServerSelected"></app-proxy-server>
  </div>
</div>
<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>
<!--<div class="scripts-footer"></div>-->
<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <!--    <button (click)="addScript()" mat-menu-item>Ajouter un script</button>-->
  </ng-template>
</mat-menu>
