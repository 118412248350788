import {Component, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-bot-character-stats",
  templateUrl: "./bot-character-stats.component.html",
  styleUrls: ["./bot-character-stats.component.scss"]
})
export class BotCharacterStatsComponent implements OnInit {
  public bot: Bot;

  constructor(public userService: UserService) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
  }

  protected readonly JSON = JSON;
}
