<div *ngIf="userService.socket.state.value === SocketAngularStateEnum.IDENTIFIED
                                    && userService.user" class="app-container">
  <app-toolbar
    [toggleSidenavEventObservable]="toggleSidenavEventSubject">
  </app-toolbar>
  <app-custom-sidenav
    [toggleSidenavEventObservable]="toggleSidenavEventSubject.asObservable()"
    class="tree-sidenav">
  </app-custom-sidenav>
</div>


<app-login *ngIf="userService.socket.state.value === SocketAngularStateEnum.NOT_CONNECTED"></app-login>

<div *ngIf="userService.socket.state.value == SocketAngularStateEnum.RE_CONNECTING" class="loading">
  <div class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>
  <p class="label-spinner">{{ 'app.reconnection' | translate }} ({{userService.socket.tentative}}) </p>
</div>

<div *ngIf="userService.socket.state.value == SocketAngularStateEnum.CONNECTING" class="loading">
  <div class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>
  <p class="label-spinner">{{ 'app.connection' | translate }}</p>
</div>

<div *ngIf="userService.socket.state.value == SocketAngularStateEnum.IDENTIFICATION" class="loading">
  <div class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>
  <p class="label-spinner">{{ 'app.identification' | translate }}</p>
</div>

<div *ngIf="userService.socket.state.value == SocketAngularStateEnum.IDENTIFIED
              && !userService.user" class="loading">
  <div class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>
  <p class="label-spinner">{{ 'app.recuperation' | translate }}</p>
</div>
