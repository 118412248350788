import {Component, OnDestroy, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";

@Component({
  selector: "app-bot-character",
  templateUrl: "./bot-character.component.html",
  styleUrls: ["./bot-character.component.scss"]
})
export class BotCharacterComponent implements OnInit, OnDestroy {
  public bot: Bot;
  public activeLink = "inventory";
  public tabs = [
    {label: "Inventory", link: "inventory"},
    {label: "Bank", link: "bank"},
    {label: "Stats", link: "characterStats"}
  ];
  private wrapperUser: CustomWrapper = this.userService.user.wrap();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
    this.bot = this.userService.botSelected;

  }

  ngOnInit(): void {
    this.initActiveLink();
    this.wrapperUser.addMonitorPacket("ActualRouteUpdated", () => {
      this.initActiveLink();
    });
  }

  ngOnDestroy(): void {
    this.wrapperUser.done();
  }

  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {subTabs: path}}], {relativeTo: this.route});
  }

  private initActiveLink() {
    let actualRoute = this.userService.actualRoute;
    actualRoute = actualRoute.replace("%2B", "+").replace("%20", " ");
    if (actualRoute.indexOf("(subTabs:") != -1) {
      this.activeLink = actualRoute.split("(subTabs:")[1].split(")")[0];
      console.log("Active link " + this.activeLink);
    }
  }
}
