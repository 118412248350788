import debug from "debug";
import {BreedEnum} from "../dofusPC/BreedEnum";

export class Utils {
//Test
    private static logs: any = {};

    public static isEqual(tableau1: any, tableau2: any) {
        if (tableau1.length !== tableau2.length) return false;

        let matching = tableau1.filter((val: any) => tableau2.includes(val));

        if (matching.length == tableau1.length) return true;

        return false;
    }

    public static sansAccent(chaine: string) {
        const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
        const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        const str = chaine.split("");
        const strLen = str.length;
        let i, x;
        for (i = 0; i < strLen; i++) {
            if ((x = accents.indexOf(str[i])) != -1) {
                str[i] = accentsOut[x];
            }
        }
        return str.join("");
    }

    public static randomize(originalTab: any[]) {
        const tab = originalTab.filter(() => true);
        var i, j, tmp;
        for (i = tab.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            tmp = tab[i];
            tab[i] = tab[j];
            tab[j] = tmp;
        }
        return tab;
    }

    public static randomString(len: number, bits: number = 36) {
        let outStr = "";
        let newStr;
        while (outStr.length < len) {
            newStr = Math.random().toString(bits).slice(2);
            outStr += newStr.slice(0, Math.min(newStr.length, (len - outStr.length)));
        }
        return outStr;
    }

    public static createDebug(name: string): Function {
        const debugTmp = debug(name);
        return (msg: string) => {
            const actualDate = new Date();
            debugTmp(`[${actualDate.getHours()}:${actualDate.getMinutes()}:${actualDate.getSeconds()}] ${msg}`);
        };
    }

    public static createLoger(name: string): {
        warn: Function,
        info: Function,
        debug: Function,
        error: Function
    } {
        const debugTmp = debug(name);
        return {
            warn: (msg: string) => {
                const actualDate = new Date();
                debugTmp(`WARN [${actualDate.getHours()}:${actualDate.getMinutes()}:${actualDate.getSeconds()}] ${msg}`);
            },
            info: (msg: string) => {
                const actualDate = new Date();
                debugTmp(`INFO [${actualDate.getHours()}:${actualDate.getMinutes()}:${actualDate.getSeconds()}] ${msg}`);
            },
            debug: (msg: string) => {
                const actualDate = new Date();
                debugTmp(`DEBUG [${actualDate.getHours()}:${actualDate.getMinutes()}:${actualDate.getSeconds()}] ${msg}`);
            },
            error: (msg: string) => {
                const actualDate = new Date();
                debugTmp(`ERROR [${actualDate.getHours()}:${actualDate.getMinutes()}:${actualDate.getSeconds()}] ${msg}`);
            }
        };
    }

    static log(nameLog: string, msg: string, args?: any) {
        if (!this.logs[nameLog]) this.logs[nameLog] = debug(nameLog);
        if (args) {
            this.logs[nameLog](msg, args);
        } else {

        }
    }

    public static getRandomInt(min: any, max: any) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }


    public static replaceAll(str: string, searchValue: string, replaceValue: string): string {
        const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const replacedString = str.replace(new RegExp(escapedSearchValue, "g"), replaceValue);
        return replacedString;
    }

    static generateRandomUID(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    public static sleep(ms: number) {
        return new Promise(res => setTimeout(res, ms));
    }

    static moyArray(array: number[]): number {
        let count = 0;
        for (const elt of array) {
            count += elt;
        }
        return array.length > 0 ? count / array.length : 0;
    }

    static minValue(array: any, func: Function) {
        let value = null;
        for (const elt of array) {
            const eltValue = func(elt);
            if (value === null || eltValue < value) value = eltValue;
        }
        if (value === null) throw new Error("minValue can't return the min value of the array");
        return value;
    }

    public static flatDeep(arr: any[]): any [] {
        return arr.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? Utils.flatDeep(val) : val), []);
    };

    static getBreedEnumFromString(breedString: string) {
        for (const breed of Object.keys(BreedEnum)) {
            if (breed.toLowerCase().indexOf(breedString.toLowerCase()) != -1) {
                return BreedEnum[breed as any];
            }
        }
        return null;
    }

    public static compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean) {
        if (a === undefined) {
            a = 0;
        }
        if (b === undefined) {
            b = 0;
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };

    static genCrypto(length = 32) {
        const LETTRES = "abcdef";
        const NUMBERS = "0123456789";
        let str = "";
        for (let i = 0; i < length; i++) {
            if (Math.random() < 0.5) {
                str += LETTRES[Math.floor(Math.random() * LETTRES.length)];
            } else {
                str += NUMBERS[Math.floor(Math.random() * NUMBERS.length)];
            }
        }
        return "";
    }


    static generateString(length: number) {
        let key = "";
        for (let i = 0; i < length; i++) {
            key += this.getRandomChar();
        }
        return key + this.checksum(key);
    }

    public static getRandomChar() {
        let n = Math.ceil(Math.random() * 100);
        if (n <= 40) return String.fromCharCode(Math.floor(Math.random() * 26) + 65); // Majuscule
        if (n <= 80) return String.fromCharCode(Math.floor(Math.random() * 26) + 97); // Minuscule
        return String.fromCharCode(Math.floor(Math.random() * 10) + 48); // Numero
    }

    // From ankama's code
    public static checksum(str: string) {
        let r = 0;
        for (let i = 0; i < str.length; i++) {
            r += str.charCodeAt(i) % 16;
        }
        return (r % 16).toString(16).toUpperCase();
    }

    static dateDiffTotalSeconds(date1: number, date2: number): number {
        const tmp = date2 - date1;
        return Math.floor(tmp / 1000);
    }
}
