import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-bot-stats-table",
  templateUrl: "./bot-stats-table.component.html",
  styleUrls: ["./bot-stats-table.component.scss"]
})
export class BotStatsTableComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public bot: Bot;
  public displayedColumns: string[] = ["date", "comment", "level", "experience", "energy", "pods", "position", "mapid", "kamas"];
  public dataSource: MatTableDataSource<any>;

  public form = new FormGroup({
    searchText: new FormControl("")
  });

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.bot = userService.botSelected;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.bot.stats.datas);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("StatsUpdated", () => {
      console.log("StatsUpdated");
      this.updateData();
    });
    this.form.controls.searchText.valueChanges.subscribe(newValue => {
      console.log("test");
      this.updateData();
    });
  }

  onSearch() {

  }

  private async updateData() {
    let datas = this.bot.stats.datas;
    if (this.form.controls.searchText.value != "") {
      datas = datas.filter(data => {
        return data.comment.toLowerCase().indexOf(this.form.controls.searchText.value.toLowerCase()) != -1;
      });
    }
    this.dataSource.data = datas;
    this.dataSource.paginator = this.paginator;
  }
}
