<div class="inventory-container">


  <!-- Center character -->
  <div class="character">
<!--    <img alt="Character" class="character-image"-->
    <!--         src="https://www.dofusbook.net/flash/7b317c33302c323034342c333534392c3136372c3238317c313d234633424335452c323d234646464643452c333d233433333432312c343d234646443933362c353d234136363330397c3132307c3140303d7b3139357c7c7c36307d7d/full/1/250_325.png">-->
  </div>

  <!-- Additional slots around the character -->
  <div class="equipment-slot" style="grid-column: 1; grid-row: 1;">
    <img (mouseenter)="getElement(0)._showTooltipCharacter = true"
         (mouseleave)="getElement(0)._showTooltipCharacter = false"
         *ngIf="getElement(0)"
         [src]="pathImages + getElement(0)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(0)?._showTooltipCharacter" [item]="getElement(0)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 1; grid-row: 2;">
    <img (mouseenter)="getElement(4)._showTooltipCharacter = true"
         (mouseleave)="getElement(4)._showTooltipCharacter = false"
         *ngIf="getElement(4)"
         [src]="pathImages + getElement(4)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(4)?._showTooltipCharacter" [item]="getElement(4)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 1; grid-row: 3;">
    <img (mouseenter)="getElement(2)._showTooltipCharacter = true"
         (mouseleave)="getElement(2)._showTooltipCharacter = false"
         *ngIf="getElement(2)"
         [src]="pathImages + getElement(2)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(2)?._showTooltipCharacter" [item]="getElement(2)"></app-item-effects>
  </div>


  <!-- Additional slots around the character -->
  <div class="equipment-slot" style="grid-column: 7; grid-row: 1;">
    <img (mouseenter)="getElement(6)._showTooltipCharacter = true"
         (mouseleave)="getElement(6)._showTooltipCharacter = false"
         *ngIf="getElement(6)"
         [src]="pathImages + getElement(6)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(6)?._showTooltipCharacter" [item]="getElement(6)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 7; grid-row: 2;">
    <img (mouseenter)="getElement(7)._showTooltipCharacter = true"
         (mouseleave)="getElement(7)._showTooltipCharacter = false"
         *ngIf="getElement(7)"
         [src]="pathImages + getElement(7)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(7)?._showTooltipCharacter" [item]="getElement(7)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 7; grid-row: 3;">
    <img (mouseenter)="getElement(3)._showTooltipCharacter = true"
         (mouseleave)="getElement(3)._showTooltipCharacter = false"
         *ngIf="getElement(3)"
         [src]="pathImages + getElement(3)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(3)?._showTooltipCharacter" [item]="getElement(3)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 7; grid-row: 4;">
    <img (mouseenter)="getElement(5)._showTooltipCharacter = true"
         (mouseleave)="getElement(5)._showTooltipCharacter = false"
         *ngIf="getElement(5)"
         [src]="pathImages + getElement(5)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(5)?._showTooltipCharacter" [item]="getElement(5)"></app-item-effects>
  </div>

  <!-- Bottom row items -->
  <div class="equipment-slot" style="grid-column: 1; grid-row: 5;">
    <img (mouseenter)="getElement(1)._showTooltipCharacter = true"
         (mouseleave)="getElement(1)._showTooltipCharacter = false"
         *ngIf="getElement(1)"
         [src]="pathImages + getElement(1)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(1)?._showTooltipCharacter" [item]="getElement(1)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 2; grid-row: 5;">
    <img (mouseenter)="getElement(15)._showTooltipCharacter = true"
         (mouseleave)="getElement(15)._showTooltipCharacter = false"
         *ngIf="getElement(15)"
         [src]="pathImages + getElement(15)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(15)?._showTooltipCharacter" [item]="getElement(15)"></app-item-effects>
  </div>

  <!-- Right column items -->
  <div class="equipment-slot" style="grid-column: 6; grid-row: 5;">
    <img (mouseenter)="getElement(88)._showTooltipCharacter = true"
         (mouseleave)="getElement(88)._showTooltipCharacter = false"
         *ngIf="getElement(88)"
         [src]="pathImages + getElement(887)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(88)?._showTooltipCharacter" [item]="getElement(88)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 7; grid-row: 5;">
    <img (mouseenter)="getElement(8)._showTooltipCharacter = true"
         (mouseleave)="getElement(8)._showTooltipCharacter = false"
         *ngIf="getElement(8)"
         [src]="pathImages + getElement(8)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(8)?._showTooltipCharacter" [item]="getElement(8)"></app-item-effects>
  </div>

  <!-- Bottom row center items -->
  <div class="equipment-slot" style="grid-column: 1; grid-row: 6;">
    <img (mouseenter)="getElement(9)._showTooltipCharacter = true"
         (mouseleave)="getElement(9)._showTooltipCharacter = false"
         *ngIf="getElement(9)"
         [src]="pathImages + getElement(9)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(9)?._showTooltipCharacter" [item]="getElement(9)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 2; grid-row: 6;">
    <img (mouseenter)="getElement(10)._showTooltipCharacter = true"
         (mouseleave)="getElement(10)._showTooltipCharacter = false"
         *ngIf="getElement(10)"
         [src]="pathImages + getElement(10)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(10)?._showTooltipCharacter" [item]="getElement(10)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 3; grid-row: 6;">
    <img (mouseenter)="getElement(11)._showTooltipCharacter = true"
         (mouseleave)="getElement(11)._showTooltipCharacter = false"
         *ngIf="getElement(11)"
         [src]="pathImages + getElement(11)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(11)?._showTooltipCharacter" [item]="getElement(11)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 4; grid-row: 6;">
    <img (mouseenter)="getElement(12)._showTooltipCharacter = true"
         (mouseleave)="getElement(12)._showTooltipCharacter = false"
         *ngIf="getElement(12)"
         [src]="pathImages + getElement(12)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(12)?._showTooltipCharacter" [item]="getElement(12)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 5; grid-row: 6;">
    <img (mouseenter)="getElement(13)._showTooltipCharacter = true"
         (mouseleave)="getElement(13)._showTooltipCharacter = false"
         *ngIf="getElement(13)"
         [src]="pathImages + getElement(13)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(13)?._showTooltipCharacter" [item]="getElement(13)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 6; grid-row: 6;">
    <img (mouseenter)="getElement(14)._showTooltipCharacter = true"
         (mouseleave)="getElement(14)._showTooltipCharacter = false"
         *ngIf="getElement(14)"
         [src]="pathImages + getElement(14)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(14)?._showTooltipCharacter" [item]="getElement(14)"></app-item-effects>
  </div>
  <div class="equipment-slot" style="grid-column: 7; grid-row: 6;">
    <img (mouseenter)="getElement(888)._showTooltipCharacter = true"
         (mouseleave)="getElement(888)._showTooltipCharacter = false"
         *ngIf="getElement(888)"
         [src]="pathImages + getElement(888)._iconId + '.png'">
    <app-item-effects *ngIf="getElement(888)?._showTooltipCharacter" [item]="getElement(888)"></app-item-effects>
  </div>
</div>
