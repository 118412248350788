import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";

export class TeamConfig {
  public team: Team;
  public configContent: string;
  public comment: string;

  constructor(team: Team, teamConfig: TeamConfig) {
    this.team = team;
    this.configContent = teamConfig.configContent || "{}";
    this.comment = teamConfig.comment;
    this.monitorPackets();
  }

  updateConfig(newConfig: string) {
    this.team.send("TeamConfigUpdateRequestMessage", {
      config: newConfig
    });
  }

  public editComment(comment: string) {
    this.team.send("TeamCommentUpdateRequestMessage", {comment});
  }

  private monitorPackets() {
    const wrapper = this.team.wrap();
    wrapper.addMonitorPacket("TeamConfigUpdatedMessage", (packet: any) => {
      this.configContent = packet.config;
    });
    wrapper.addMonitorPacket("TeamCommentUpdatedMessage", (packet: any) => {
      this.comment = packet.comment;
    });
  }
}
