import {GameRolePlayActorInformations} from "@protocol/types/GameRolePlayActorInformations";
import {MapComplementaryInformationsDataMessage} from "@protocol/messages/MapComplementaryInformationsDataMessage";
import {MapNpcsQuestStatusUpdateMessage} from "@common/lib/dofustouch/packets/MapNpcsQuestStatusUpdateMessage";
import {GameRolePlayShowActorMessage} from "@protocol/messages/GameRolePlayShowActorMessage";
import {GameContextRemoveElementMessage} from "@protocol/messages/GameContextRemoveElementMessage";
import {GameContextRemoveMultipleElementsMessage} from "@protocol/messages/GameContextRemoveMultipleElementsMessage";
import {GameMapMovementMessage} from "@protocol/messages/GameMapMovementMessage";
import {TeleportOnSameMapMessage} from "@protocol/messages/TeleportOnSameMapMessage";
import {GameRolePlayNpcWithQuestInformations} from "@protocol/types/GameRolePlayNpcWithQuestInformations";
import {GameRolePlayNpcInformations} from "@protocol/types/GameRolePlayNpcInformations";
import {Utils} from "@common/lib/utils";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {GameRolePlayCharacterInformations} from "@protocol/types/GameRolePlayCharacterInformations";
import {GameRolePlayGroupMonsterInformations} from "@protocol/types/GameRolePlayGroupMonsterInformations";

export class GameMapActors {
  public entities: GameRolePlayActorInformations[] = [];
  public npcIdsWithQuest: any[] = [];
  private bot: Bot;

  constructor(bot: Bot, gameMapActors: GameMapActors) {
    this.bot = bot;
    this.monitorPackets();
  }

  public getActorById(contextualId: number) {
    return this.entities.find(entity => entity.contextualId === contextualId);
  }

  public getAccountActor() {
    return this.getActorById(this.bot.game.character.id);
  }

  public getOccupiedCells() {
    const occupiedCells: number[] = [];
    this.entities.forEach(entity => {
      occupiedCells.push(entity.disposition.cellId);
    });
    return occupiedCells;
  }

  public getActorsOnCellId(cellId: number): GameRolePlayActorInformations[] {
    const actors = [];
    for (const entity of this.entities) {
      if (entity.disposition.cellId === cellId) {
        actors.push(entity);
      }
    }
    return actors;
  }

  public async getPlayerActorsWithConditions(conditions: any[] = []): Promise<GameRolePlayActorInformations[]> {
    const actors: GameRolePlayActorInformations[] = [];
    const entities = Utils.randomize(this.entities);
    for (const actor of entities) {
      if (actor.contextualId === this.bot.game.character.id) continue;
      let good = true;
      for (const cond of conditions) {
        let value1: string | any = null;
        switch (cond.name.toLowerCase()) {
          case "type".toLowerCase():
            value1 = actor._type;
            break;
          case "cellid".toLowerCase():
            value1 = actor.disposition.cellId;
            break;
          case "isBot".toLowerCase():
            const player = actor as GameRolePlayCharacterInformations;
            // const result = await this.bot.controller.nezuUser.supervisor.serverV2.checkIfMyBot(
            //     player.name + ";" + this.bot.game.character.serverId);
            // value1 = result.result;
            break;
          default:
            // this.bot.console.logInfo("I don't understand your condition actor " + cond.name);
            await Utils.sleep(2000);
            return [];
        }
        if (value1.toString().toLowerCase() !== cond.value.toString().toLowerCase()) {
          good = false;
          break;
        }
      }
      if (good) return [actor];
    }
    return actors;
  }

  public getPlayerActors(): GameRolePlayCharacterInformations[] {
    return this.getActorsByType("GameRolePlayCharacterInformations");
  }

  public getPlayerActorByName(name: string): GameRolePlayCharacterInformations {
    return this.getPlayerActors().find((elt) => elt.name.toLowerCase() === name.toLowerCase());
  }

  public getPlayerActorById(id: number): GameRolePlayCharacterInformations {
    return this.getPlayerActors().find((elt) => elt.contextualId === id);
  }

  public getGroupsMonsterActor(): GameRolePlayGroupMonsterInformations[] {
    return this.getActorsByType("GameRolePlayGroupMonsterInformations");
  }

  public getNpcActors(): GameRolePlayNpcInformations[] {
    return this.getActorsByType("GameRolePlayNpcInformations");
  }

  public getNpcWithQuestActor(): GameRolePlayNpcWithQuestInformations[] {
    return this.getActorsByType("GameRolePlayNpcWithQuestInformations");
  }

  public getActorsByType(type: string) {
    return this.entities.filter(entity => entity._messageType === type);
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket([
      "packet::MapComplementaryInformationsDataMessage",
      "packet::MapComplementaryInformationsDataInHouseMessage",
      "packet::MapComplementaryInformationsWithCoordsMessage"
    ], async (packet: MapComplementaryInformationsDataMessage) => {
      this.entities = packet.actors;
    });
    wrapper.addMonitorPacket("MapNpcsQuestStatusUpdateMessage", (packet: MapNpcsQuestStatusUpdateMessage) => {
      this.npcIdsWithQuest = packet.npcsIdsWithQuest;
      this.bot.emit("MapDataUpdated");
    });
    wrapper.addMonitorPacket("GameRolePlayShowActorMessage", (packet: GameRolePlayShowActorMessage) => {
      this.entities = this.entities.filter((elt) => elt.contextualId !== packet.informations.contextualId);
      this.entities.push(packet.informations);
      // this.bot.emit("MapDataUpdated");
    });
    wrapper.addMonitorPacket("GameContextRemoveElementMessage", (packet: GameContextRemoveElementMessage) => {
      this.entities = this.entities.filter((elt) => elt.contextualId !== packet.id);
      // this.bot.emit("MapDataUpdated");
    });
    wrapper.addMonitorPacket("GameContextRemoveMultipleElementsMessage", (packet: GameContextRemoveMultipleElementsMessage) => {
      this.entities = this.entities.filter(entity => packet.elementsIds.indexOf(entity.contextualId) === -1);
      // this.bot.emit("MapDataUpdated");
    });
    wrapper.addMonitorPacket("GameMapMovementMessage", (packet: GameMapMovementMessage) => {
      const entity = this.entities.find(elt => elt.contextualId === packet.actorId);
      if (entity) entity.disposition.cellId = packet.keyMovements[packet.keyMovements.length - 1];
      // this.bot.emit("MapDataUpdated");
    });
    wrapper.addMonitorPacket("TeleportOnSameMapMessage", (packet: TeleportOnSameMapMessage) => {
      const entity = this.entities.find(elt => elt.contextualId === packet.targetId);
      if (entity) entity.disposition.cellId = packet.cellId;
      // this.bot.emit("MapDataUpdated");
    });
  }
}
