import {Component, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";

@Component({
  selector: "app-bot-stats",
  templateUrl: "./bot-stats.component.html",
  styleUrls: ["./bot-stats.component.scss"]
})
export class BotStatsComponent implements OnInit {
  public bot: Bot;
  public activeLink = "inventory";
  public tabs = [
    {label: "Table", link: "statsTable"},
    {label: "Graph", link: "statsGraph"},
    {label: "Fight", link: "statsFight"}
  ];
  private wrapperUser: CustomWrapper = this.userService.user.wrap();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
    this.bot = this.userService.botSelected;

  }

  ngOnInit(): void {
    this.initActiveLink();
    this.wrapperUser.addMonitorPacket("ActualRouteUpdated", () => {
      this.initActiveLink();
    });
  }

  ngOnDestroy(): void {
    this.wrapperUser.done();
  }

  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {subTabs: path}}], {relativeTo: this.route});
  }

  private initActiveLink() {
    let actualRoute = this.userService.actualRoute;
    actualRoute = actualRoute.replace("%2B", "+").replace("%20", " ");
    if (actualRoute.indexOf("(subTabs:") != -1) {
      this.activeLink = actualRoute.split("(subTabs:")[1].split(")")[0];
      console.log("Active link " + this.activeLink);
    }
  }
}
