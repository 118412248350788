import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CustomSidenavComponent} from "./custom-sidenav.component";
import {AppRoutingModule} from "@dashboard/src/app/app-routing.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UserElementComponent} from "./user-element/user-element.component";
import {UserModule} from "@dashboard/src/app/pages/user/user.module";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ControllerElementComponent} from "./controller-element/controller-element.component";
import {ControllerContentComponent} from "./controller-content/controller-content.component";
import {
  AddBotInstanceDialogComponent
} from "./controller-content/add-bot-instance-dialog/add-bot-instance-dialog.component";
import {MatTooltipModule} from "@angular/material/tooltip";

import {DialogQuestionModule} from "@dashboard/src/app/features/dialog-question/dialog-question.module";
import {GroupControllerElementComponent} from "./group-controller-element/group-controller-element.component";
import {
  AddTeamInstanceDialogComponent
} from "@dashboard/src/app/features/sidenav/controller-content/add-team-instance-dialog/add-team-instance-dialog.component";
import {
  BotElementComponent
} from "@dashboard/src/app/features/sidenav/controller-content/bot-element/bot-element.component";
import {
  TeamElementComponent
} from "@dashboard/src/app/features/sidenav/controller-content/team-element/team-element.component";
import {
  TeamSoloElementComponent
} from "@dashboard/src/app/features/sidenav/controller-content/team-solo-element/team-solo-element.component";

@NgModule({
  declarations: [
    CustomSidenavComponent,
    UserElementComponent,
    ControllerElementComponent,
    ControllerContentComponent,
    AddBotInstanceDialogComponent,
    AddTeamInstanceDialogComponent,
    BotElementComponent,
    TeamElementComponent,
    TeamSoloElementComponent,
    GroupControllerElementComponent
  ],
  exports: [
    CustomSidenavComponent
  ],
  imports: [
    MatTooltipModule,
    DialogQuestionModule,
    DragDropModule,
    CommonModule,
    AppRoutingModule,
    MatButtonModule,
    MatListModule,
    RouterModule,
    MatCheckboxModule,
    MatMenuModule,
    UserModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class CustomSidenavModule {
}
