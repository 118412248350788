import {Routes} from "@angular/router";
import {BotResolver} from "@dashboard/src/app/pages/controller/bot/bot.resolver";
import {BotComponent} from "@dashboard/src/app/pages/controller/bot/bot.component";
import {InstanceIsConnectedGuard} from "@dashboard/src/app/pages/controller/bot/InstanceIsConnectedGuard";
import {
  BotConsoleComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-console/bot-console.component";

import {
  BotNetworkComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-network/bot-network.component";
import {BotConfigComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-config/bot-config.component";
import {
  BotInventoryComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-inventory/bot-inventory.component";
import {BotMapComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-map/bot-map.component";
import {BotStatsComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats.component";
import {BotFmComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-fm/bot-fm.component";
import {
  BotCharacterComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-character.component";
import {
  BotCharacterStatsComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-character-stats/bot-character-stats.component";
import {
  BotStatsTableComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats-table/bot-stats-table.component";
import {
  BotStatsGraphComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats-graph/bot-stats-graph.component";
import {
  BotStatsFightComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats-fight/bot-stats-fight.component";
import {
  BotBankComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-bank/bot-bank.component";


export const ROUTES_BOT: Routes = [
  {
    path: "controller/:controllerId/bot/:botId",
    component: BotComponent,
    canActivate: [
      InstanceIsConnectedGuard
    ],
    resolve: {
      bot: BotResolver
    },
    children: [
      {
        path: "",
        redirectTo: "console",
        pathMatch: "full",
        outlet: "tabs"
      },
      {
        path: "console",
        outlet: "tabs",
        component: BotConsoleComponent
      },
      {
        path: "character",
        outlet: "tabs",
        component: BotCharacterComponent,
        children: [
          {
            path: "",
            redirectTo: "inventory",
            pathMatch: "full",
            outlet: "subTabs"
          },
          {
            path: "inventory",
            outlet: "subTabs",
            component: BotInventoryComponent
          },
          {
            path: "bank",
            outlet: "subTabs",
            component: BotBankComponent
          },
          {
            path: "characterStats",
            outlet: "subTabs",
            component: BotCharacterStatsComponent
          },
        ]
      },
      {
        path: "map",
        outlet: "tabs",
        component: BotMapComponent
      },
      {
        path: "network",
        outlet: "tabs",
        component: BotNetworkComponent
      },
      {
        path: "config",
        outlet: "tabs",
        component: BotConfigComponent
      },
      {
        path: "stats",
        outlet: "tabs",
        component: BotStatsComponent,
        children: [
          {
            path: "",
            redirectTo: "statsTable",
            pathMatch: "full",
            outlet: "subTabs"
          },
          {
            path: "statsTable",
            outlet: "subTabs",
            component: BotStatsTableComponent
          },
          {
            path: "statsGraph",
            outlet: "subTabs",
            component: BotStatsGraphComponent
          },
          {
            path:"statsFight",
            outlet: "subTabs",
            component: BotStatsFightComponent
          }
        ]
      },
      {
        path: "fm",
        outlet: "tabs",
        component: BotFmComponent
      }
    ]
  }
];
