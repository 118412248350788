import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {BreedEnum} from "@protocol/enums/BreedEnum";

export class GameAuthentication {
  private bot: Bot;

  public constructor(bot: Bot, gameAuthentication: GameAuthentication) {
    this.bot = bot;
  }

  public connect() {
    this.bot.send("BotConnectRequestMessage");
  }

  public connectAndCreate(serverIdentifier: string, breed: BreedEnum, name: string) {
    this.bot.send("BotConnectAndCreateRequestMessage", {
      serverIdentifier,
      breed,
      name
    });
  }

  disconnect() {
    this.bot.send("BotDisconnectRequestMessage");
  }
}
