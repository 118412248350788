import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AccountsService {

  constructor(private http: HttpClient) {
  }

  public getOldAccounts(serverId: string, count: number): Observable<string> {
    return this.http.get(`http://172.22.213.129:7900/giveBotsSaved/${serverId}/${count}`, {responseType: "text"});
  }
}
