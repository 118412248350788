import {Component} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";

@Component({
  selector: "app-bot-footer",
  templateUrl: "./bot-footer.component.html",
  styleUrls: ["./bot-footer.component.scss"]
})
export class BotFooterComponent {
  public bot: Bot;

  constructor(public userService: UserService) {
    this.bot = userService.botSelected;
  }

}
