import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

export class ControllerConfig {
  public controller: Controller;
  public configContent: string;
  public configData: any;

  constructor(controller: Controller, controllerConfig: ControllerConfig) {
    this.controller = controller;
    this.configContent = controllerConfig.configContent || "{}";
    try {
      this.configData = JSON.parse(this.configContent)
    }catch (e) {

    }
    this.monitorPackets();
  }

  updateConfig(newConfig: string) {
    this.controller.send("ControllerConfigUpdateRequestMessage", {
      config: newConfig
    });
  }

  private monitorPackets() {
    const wrapper = this.controller.wrap();
    wrapper.addMonitorPacket("ControllerConfigUpdatedMessage", (packet: any) => {
      this.configContent = packet.config;
      try {
        this.configData = JSON.parse(this.configContent)
      }catch (e) {

      }
    });
  }
}
