import {Bot} from "..";
import {GameCharacter} from "./character";
import {GameMap} from "./map";
import {GameTchat} from "./tchat";
import {GameAuthentication} from "@dashboard/src/app/service/user/controllers/controller/bot/game/authentication";
import {GameFight} from "@dashboard/src/app/service/user/controllers/controller/bot/game/fight";

import * as moment from "moment";
import {BotGameExchange} from "@dashboard/src/app/service/user/controllers/controller/bot/game/exchange";
import {BotGameJobs} from "@dashboard/src/app/service/user/controllers/controller/bot/game/jobs";

export class BotGame {
  public authentication: GameAuthentication;
  public character: GameCharacter;
  public tchat: GameTchat;
  public map: GameMap;
  public fight: GameFight;
  public jobs: BotGameJobs;
  public exchange: BotGameExchange;
  public subscriptionEndDate: number;
  public daysRemaining: number;
  private bot: Bot;

  public constructor(bot: Bot, botGame: BotGame) {
    this.bot = bot;
    this.authentication = new GameAuthentication(bot, botGame.authentication || {} as GameAuthentication);
    this.character = new GameCharacter(bot, botGame.character || {} as GameCharacter);
    this.tchat = new GameTchat(bot, botGame.tchat || {} as GameTchat);
    this.map = new GameMap(bot, botGame.map || {} as GameMap);
    this.fight = new GameFight(bot, botGame.fight || {} as GameFight);
    this.jobs = new BotGameJobs(bot,botGame.jobs || {} as BotGameJobs);
    this.exchange = new BotGameExchange(bot, botGame.exchange || {} as BotGameExchange);
    this.subscriptionEndDate = botGame.subscriptionEndDate;
    this.monitorPackets();
    this.updateDaysRemaining();
  }

  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("AccountInformationsUpdateMessage", (packet: any) => {
      this.subscriptionEndDate = packet.subscriptionEndDate;
      this.updateDaysRemaining();
    });
  }

  private updateDaysRemaining() {
    const end = moment(this.subscriptionEndDate); // date de fin de l'abonnement
    const now = moment(); // date actuelle
    const diff = end.diff(now, "days", true); // différence en jours entre les deux dates
    this.daysRemaining = diff < 0 ? 0 : diff;
  }
}
