import {Component, OnInit, ViewChild} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ProxyServer} from "@dashboard/src/app/service/user/proxyServers/proxyServer";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: "app-proxy-servers",
  templateUrl: "./proxy-servers.component.html",
  styleUrls: ["./proxy-servers.component.scss"]
})
export class ProxyServersComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: "0px", y: "0px"};
  public proxyServerSelected: ProxyServer;

  constructor(public userService: UserService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  setProxyServer(proxyServer: ProxyServer) {
    this.proxyServerSelected = proxyServer;
  }
}
