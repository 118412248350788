import {ProxiesManager} from "@dashboard/src/app/service/user/proxies";
import {IasManager} from "@dashboard/src/app/service/user/ias";
import {ScriptsManager} from "@dashboard/src/app/service/user/scripts";
import {UserConfig} from "@dashboard/src/app/service/user/config";
import {WebSocketAngular} from "@dashboard/src/app/utils/WebSocketAngular";
import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {ControllersManager} from "@dashboard/src/app/service/user/controllers";
import {UserService} from "@dashboard/src/app/service/user.service";
import { ProxyServersManager} from "@dashboard/src/app/service/user/proxyServers";

export class NezuUser extends CustomDispatcher {
  public login: string;
  public dispatcher = new CustomDispatcher("user");
  public socket: WebSocketAngular;
  public scripts: ScriptsManager;
  public ias: IasManager;
  public proxies: ProxiesManager;
  public config: UserConfig;
  public controllers: ControllersManager;
  public userService: UserService;
  public proxyServers: ProxyServersManager;

  public constructor(user: NezuUser, socket: WebSocketAngular) {
    super("user");
    this.login = user.login;
    this.socket = socket;
    this.scripts = new ScriptsManager(user.scripts || {} as ScriptsManager, this);
    this.ias = new IasManager(user.ias || {} as IasManager);
    this.proxies = new ProxiesManager(user.proxies || {} as ProxiesManager,this);
    this.config = new UserConfig(user.config || {} as UserConfig);
    this.controllers = new ControllersManager(user.controllers, this);
    this.proxyServers = new ProxyServersManager(user.proxyServers || {} as ProxyServersManager, this);
  }

  public send(data: any, call: string) {
    if (this.socket && this.socket.connected) {
      this.socket.send(data, call, "user");
    }
  }

  async init() {
    await this.controllers.init();
  }
}
