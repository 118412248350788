import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

export class ControllerSchema {
  public _type: string = "ControllerSchema";
  public id: string;
  public name: string;
  public controller: Controller;

  public constructor(controller: ControllerSchema, controllers: Controller[]) {
    this.id = controller.id;
    this.name = controller.name;
    this.controller = controllers.find(elt => elt.id == this.id);
  }

  public toJson(): ControllerSchema {
    return {
      _type: this._type,
      id: this.id,
      name: this.name
    } as ControllerSchema;
  }
}

export class ControllerGroupSchema {
  public _type: string = "ControllerGroupSchema";
  public name: string;
  public elements: any[] = [];

  public constructor(group: ControllerGroupSchema, controllers: Controller[]) {
    this.name = group ? group.name : "main";
    this.elements = group && group.elements ? group.elements.map(
      elt => elt._type == "ControllerGroupSchema" ?
        new ControllerGroupSchema(elt as ControllerGroupSchema, controllers)
        : new ControllerSchema(elt as ControllerSchema, controllers)) : [];
  }

  public controllerExist(id: string): boolean {
    return this.elements.some(elt => elt._type == "ControllerSchema" && (elt as ControllerSchema).id == id)
      || this.elements.some(elt => elt._type == "ControllerGroupSchema" && (elt as ControllerGroupSchema).controllerExist(id));
  }

  public getControllerById(id: string): ControllerSchema {
    const elt = this.elements.find(elt => elt._type == "ControllerSchema" && (elt as ControllerSchema).id == id);
    if (elt) return elt;
    for (const group of this.elements.filter(elt => elt._type == "ControllerGroupSchema")) {
      const elt = group.getControllerById(id);
      if (elt) return elt;
    }
    return null;
  }

  public removeControllerById(id: string) {
    const index = this.elements.findIndex(elt => elt._type == "ControllerSchema" && (elt as ControllerSchema).id === id);
    if (index != -1) this.elements.splice(index, 1);
    for (const elt of this.elements.filter(elt => elt._type == "ControllerGroupSchema") as ControllerGroupSchema[]) {
      elt.removeControllerById(id);
    }
  }

  public removeGroupByName(name: string) {
    const index = this.elements.findIndex(elt => elt._type == "ControllerGroupSchema" && (elt as ControllerGroupSchema).name === name);
    if (index != -1) this.elements.splice(index, 1);
  }

  public toJson(): ControllerGroupSchema {
    return {
      _type: this._type,
      name: this.name,
      elements: this.elements.map(elt => elt.toJson())
    } as ControllerGroupSchema;
  }
}
