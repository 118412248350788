<mat-divider></mat-divider>
<nav backgroundColor="primary" color="warn" mat-tab-nav-bar>
  <a (click)="navigate(tab.link)" *ngFor="let tab of tabs"
     [active]="activeLink == tab.link"
     mat-tab-link>
    {{ tab.label }}
  </a>
</nav>
<div class="outlet">
  <router-outlet name="tabs"></router-outlet>
</div>
