import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {NezuUser} from "@dashboard/src/app/service/user";
import {ControllerGroupSchema, ControllerSchema} from "@dashboard/src/app/service/user/controllers/ControllerSchema";

export class ControllersManager {
  public user: NezuUser;
  public controllers: Controller[] = [];
  public schema: ControllerGroupSchema;

  constructor(controllers: ControllersManager, user: NezuUser) {
    this.user = user;
    this.controllers = controllers.controllers.map(elt => new Controller(elt as Controller, user));
    this.schema = new ControllerGroupSchema(controllers.schema, this.controllers);
    this.monitorPackets();
  }

  public async init() {
    for (const controller of this.controllers) {
      if (controller.url) controller.connect();
    }
  }

  sendUpdateSchema() {
    this.user.send({
      schema: this.schema.toJson()
    }, "UpdateControllerGroupSchemaRequestMessage");
  }

  addDirectory(name: string) {
    if (!this.schema.elements.some(elt => elt._type == "ControllerGroupSchema" && (elt as ControllerGroupSchema).name == name)) {
      const schema = new ControllerGroupSchema({name} as ControllerGroupSchema, this.controllers);
      this.schema.elements.push(schema);
      this.sendUpdateSchema();
    }
  }

  moveControllerToGroup(controller: ControllerSchema, group: ControllerGroupSchema) {
    this.schema.removeControllerById(controller.id);
    group.elements.push(controller);
    this.sendUpdateSchema();
  }

  private monitorPackets() {
    const wrapper = this.user.socket.wrap();
    wrapper.addMonitorPacket("ControllerUpdatedMessage", (packet: any) => {
      this.controllers = this.controllers.filter(elt => elt.id !== packet.controller.id);
      this.controllers.push(new Controller(packet.controller as Controller, this.user));
    });
    wrapper.addMonitorPacket("ControllerAddedMessage", (packet: any) => {
      this.controllers = this.controllers.filter(elt => elt.id !== packet.controller.id);
      const controller = new Controller(packet.controller as Controller, this.user);
      this.controllers.push(controller);
      controller.connect();
    });
    wrapper.addMonitorPacket("ControllerRemovedMessage", (packet: any) => {
      const controller = this.controllers.find(elt => elt.id == packet.id);
      if (controller) {
        controller.socket.disconnect();
        this.controllers = this.controllers.filter(elt => elt.id !== packet.id);
      }
    });
    // wrapper.addMonitorPacket("ControllersInformationsMessage.ts", (packet: ControllersInformationsMessage) => {
    //   this.controllers = packet.controllers.map((controller) => new Controller(controller, this.user));
    // });
    wrapper.addMonitorPacket("ControllerGroupSchemaUpdated", (packet: any) => {
      this.schema = new ControllerGroupSchema(packet.schema, this.controllers);
      this.user.emit("ControllerGroupSchemaUpdated");
    });
  }
}
