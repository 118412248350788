<div class="list-actions">
  <div (click)="setProxyServer()">
    {{proxyServer.linkProxy}}
  </div>
  <button (click)="autoDetect()" class="submit-button" color="primary" mat-raised-button>
    Auto Detect
  </button>
  <button (click)="autoConfig()" class="submit-button" color="primary" mat-raised-button>
    Auto Config
  </button>
  <button (click)="restartAll()" class="submit-button" color="primary" mat-raised-button>
    Restart All
  </button>
  <button (click)="updateIpAll()" class="submit-button" color="primary" mat-raised-button>
    Update All Ip
  </button>
  <button (click)="checkAllIp()" class="submit-button" color="primary" mat-raised-button>
    Check All Ip
  </button>
</div>

<div class="table-with-paginator mat-elevation-z8">
  <div class="mat-elevation-z8 table-container">
    <table [dataSource]="dataSource" mat-table matSort matSortDirection="asc">
      <ng-container matColumnDef="url">
        <th *matHeaderCellDef mat-header-cell> Proxy</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{element.url}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="actualIp">
        <th *matHeaderCellDef mat-header-cell> Actual IP</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{element.publicIp}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="upTime">
        <th *matHeaderCellDef mat-header-cell> Up Time (s)</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{element.deviceInformation?.uptime}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="interfaceName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Interface</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{element.interfaceName}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="ssid">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> SSID</th>
        <td *matCellDef="let element" mat-cell>
          <div (click)="renameSSID(element)">
            {{element.ssid}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="wifiPassword">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Wifi Pass</th>
        <td *matCellDef="let element" mat-cell>
          <div (click)="changeWifiPassword(element)">
            {{element.wifiPassword}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th *matHeaderCellDef mat-header-cell> operator</th>
        <td *matCellDef="let element" mat-cell>
          <div [style.color]="element.netMode == 'UNKNOWN' ? 'red' : 'green'" [style.fontStyle]="'bold'">
            {{element.netMode}}  {{element.oper}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell> State</th>
        <td *matCellDef="let element" mat-cell>
          <div [style.color]="element.netStatus == 'Connected' ? 'green' : 'red'" [style.fontStyle]="'bold'">
            {{element.netStatus}}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell> Actions</th>
        <td *matCellDef="let element" class="actions-list" mat-cell>
          <button (click)="$event.stopPropagation(); element.checkIp()"
                  aria-label="Example icon-button with a heart icon" color="primary" mat-raised-button>
            Check IP
          </button>
          <button (click)="$event.stopPropagation(); element.updateIp()"
                  aria-label="Example icon-button with a heart icon" color="primary" mat-raised-button>
            Update IP
          </button>
          <button [matMenuTriggerFor]="menu" mat-button>Actions</button>
          <mat-menu #menu="matMenu">
            <button (click)="element.rebootProxy()" mat-menu-item>Reboot</button>
            <button (click)="element.checkInformations()" mat-menu-item>Check Infos</button>
            <button (click)="element.enableDebugMode()" mat-menu-item>Enable Debug Mode
            </button>
          </mat-menu>

          <button (click)="$event.stopPropagation(); proxyServer.deleteProxy(element.id)"
                  aria-label="Example icon-button with a heart icon" color="warn"
                  mat-icon-button>
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    aria-label="Select page of process"
    showFirstLastButtons>
  </mat-paginator>
</div>
