import {Component, OnInit} from '@angular/core';
import {UserService} from '@dashboard/src/app/service/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-element',
  templateUrl: './user-element.component.html',
  styleUrls: ['./user-element.component.scss']
})
export class UserElementComponent implements OnInit {

  constructor(public userService: UserService, public router: Router) {
  }

  ngOnInit(): void {
  }

}
