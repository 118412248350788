import {ChangeDetectorRef, Component} from "@angular/core";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {SocketAngularStateEnum} from "./enums/SocketAngularStateEnum";
import {UserService} from "@dashboard/src/app/service/user.service";

import * as debug from "debug";
import {MapPoint} from "@dashboard/src/app/utils/MapPoint";
import {AuthenticationService} from "@dashboard/src/app/service/authentication.service";
import {environment} from "@dashboard/src/environments/environment";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "nezudashpc";

  public toggleSidenavEventSubject: Subject<void> = new Subject<void>();
  public SocketAngularStateEnum = SocketAngularStateEnum;
  private iconList: string[] = ["account", "settings", "connected", "disconnected"];

  constructor(
    public userService: UserService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private ref: ChangeDetectorRef
  ) {
    const appHeight = () => {
      const doc = document.documentElement;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        doc.style.setProperty("--app-height", `${window.innerHeight + 8}px`);
      } else {
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      }
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    MapPoint.init();
    translate.setDefaultLang("fr");
    translate.use("fr");
    debug.enable("app*");
    // this.iconList.forEach(icon => this.iconRegistryService.addIcon(icon));

    console.log("currentUrl : " + window.location.search);
    const parameters = new URLSearchParams(window.location.search);
    if (parameters.get("token")) {
      environment.token = parameters.get("token");
    } else if (localStorage.getItem("nezudash-token")) {
      environment.token = localStorage.getItem("nezudash-token");
    }
    if (environment.token) {
      this.userService.socket.connect();
    }
  }


}
