<div class="table-with-paginator mat-elevation-z8">
  <div class="table-container">
    <table [dataSource]="dataSource" class="table-inventory" mat-table matSort>
      <ng-container matColumnDef="image">
        <th *matHeaderCellDef mat-header-cell> Image</th>
        <td *matCellDef="let element" mat-cell>
          <img (click)="bot.fm.fmWithObject(element.objectUID)" [src]="pathImages + element._iconId + '.png'">
        </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef mat-header-cell> Quantity</th>
        <td *matCellDef="let element" mat-cell> {{element.quantity | number}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell> Name</th>
        <td *matCellDef="let element" mat-cell> {{element._name}}</td>
      </ng-container>
      <ng-container matColumnDef="UID">
        <th *matHeaderCellDef mat-header-cell> UID</th>
        <td *matCellDef="let element" mat-cell> {{element.objectUID}}</td>
      </ng-container>
      <ng-container matColumnDef="gid">
        <th *matHeaderCellDef mat-header-cell> GID</th>
        <td *matCellDef="let element" mat-cell> {{element.objectGID}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell> Actions</th>
        <td *matCellDef="let element" class="cell-actions" mat-cell>

        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    aria-label="Select page of inventory"
    showFirstLastButtons>
  </mat-paginator>
</div>
<!--<div class="character-container">-->
<!--<img class="image-character"-->
<!--src="http://staticns.ankama.com/dofus/renderer/look/7b317c3131312c323138342c3233392c3436312c313235307c313d33333334323332312c323d33373938313938392c333d35313232383336392c343d37373830333639382c353d38343038323835387c3134307d/full/2/500_500-0.png">-->
<!--</div>-->

