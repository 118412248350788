import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSortModule} from "@angular/material/sort";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MonacoEditorModule} from "@materia-ui/ngx-monaco-editor";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {CustomConsoleModule} from "@dashboard/src/app/features/custom-console/custom-console.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BotHeaderComponent} from "@dashboard/src/app/pages/controller/bot/bot-header/bot-header.component";
import {BotContentComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-content.component";
import {BotFooterComponent} from "@dashboard/src/app/pages/controller/bot/bot-footer/bot-footer.component";
import {
  BotConsoleComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-console/bot-console.component";
import {
  BotInventoryComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-inventory/bot-inventory.component";
import {BotConfigComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-config/bot-config.component";
import {BotNetworkComponent} from "./bot-content/bot-network/bot-network.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
  BotNetworkPacketLineComponent
} from "./bot-content/bot-network/bot-network-packet-line/bot-network-packet-line.component";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {ROUTES_BOT} from "@dashboard/src/app/pages/controller/bot/bot.routes";
import {BotComponent} from "@dashboard/src/app/pages/controller/bot/bot.component";
import {BotMapComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-map/bot-map.component";
import {BotStatsComponent} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats.component";
import {BotFmComponent} from "./bot-content/bot-fm/bot-fm.component";
import {BotFmHistoryComponent} from "./bot-content/bot-fm/bot-fm-history/bot-fm-history.component";
import {BotFmInventoryComponent} from "./bot-content/bot-fm/bot-fm-inventory/bot-fm-inventory.component";
import {BotFmExchangeComponent} from "./bot-content/bot-fm/bot-fm-exchange/bot-fm-exchange.component";
import {BotFmEffectsComponent} from "./bot-content/bot-fm/bot-fm-effects/bot-fm-effects.component";
import {
  ItemEffectsComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-inventory/bot-inventory-content/item-effects/item-effects.component";
import {
  BotInventoryContentComponent
} from "./bot-content/bot-inventory/bot-inventory-content/bot-inventory-content.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {
  BotInventoryCharacterComponent
} from "./bot-content/bot-inventory/bot-inventory-character/bot-inventory-character.component";
import {BotCharacterComponent} from "./bot-content/bot-character/bot-character.component";
import {
  BotCharacterStatsComponent
} from "./bot-content/bot-character/bot-character-stats/bot-character-stats.component";
import {BotStatsTableComponent} from "./bot-content/bot-stats/bot-stats-table/bot-stats-table.component";
import {BotStatsGraphComponent} from "./bot-content/bot-stats/bot-stats-graph/bot-stats-graph.component";
import {CanvasJSAngularChartsModule} from "@canvasjs/angular-charts";
import {
  KamasByDateComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats-graph/kamas-by-date/kamas-by-date.component";
import {
  KamasByHourComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-stats/bot-stats-graph/kamas-by-hour/kamas-by-hour.component";
import {
    ViewCharacteristicDetailedComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-character-stats/view-characteristic-detailed/view-characteristic-detailed.component";
import {
  ViewCharacteristicValueComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-character-stats/view-characteristic-value/view-characteristic-value.component";
import {
  BotBankComponent
} from "@dashboard/src/app/pages/controller/bot/bot-content/bot-character/bot-bank/bot-bank.component";

@NgModule({
  declarations: [
    BotComponent,
    BotHeaderComponent,
    BotContentComponent,
    BotFooterComponent,
    BotConsoleComponent,
    BotInventoryComponent,
    BotBankComponent,
    BotConfigComponent,
    BotNetworkComponent,
    BotNetworkPacketLineComponent,
    BotMapComponent,
    BotStatsComponent,
    BotFmComponent,
    BotFmHistoryComponent,
    BotFmInventoryComponent,
    BotFmExchangeComponent,
    BotFmEffectsComponent,
    ItemEffectsComponent,
    BotInventoryContentComponent,
    BotInventoryCharacterComponent,
    BotCharacterComponent,
    BotCharacterStatsComponent,
    BotStatsTableComponent,
    BotStatsGraphComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    CanvasJSAngularChartsModule,
    RouterModule.forChild(ROUTES_BOT),
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    MatSelectModule,
    MonacoEditorModule,
    MatButtonModule,
    MatPaginatorModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    CustomConsoleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    NgxJsonViewerModule,
    MatProgressBarModule,
    MatDividerModule,
    MatGridListModule,
    KamasByDateComponent,
    KamasByHourComponent,
    ViewCharacteristicDetailedComponent,
    ViewCharacteristicValueComponent
  ]
})
export class BotModule {
}
