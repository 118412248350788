<div class="team-infos">
  <button (click)="_open = !_open"
          (contextmenu)="clickOnContextMenu($event)" class="close-open-team" mat-button>
    {{"Bots solo"}}
  </button>
  <button (click)="clickOnContextMenu($event)" class="left-button" mat-button></button>
</div>

<span *ngIf="_open" class="team-open">
  <div (cdkDropListDropped)="dropBot($event)"
       [cdkDropListConnectedTo]="LIST_IDS"
       [cdkDropListData]="controller.botsSolo"
       [id]="'solo'"
       cdkDropList
       class="example-list">
    <div *ngFor="let bot of controller.botsSolo" [cdkDragData]="bot" [cdkDragDisabled]="userService.isMobile" cdkDrag>
      <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
      <app-bot-element [bot]="bot"></app-bot-element>
    </div>
  </div>
</span>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="addBot()" mat-menu-item>Add bot</button>
    <button (click)="copyIds()" mat-menu-item>Copies IDs</button>
    <button (click)="removeBots()" class="action-dangerous" mat-menu-item>Just Bots</button>

  </ng-template>
</mat-menu>

