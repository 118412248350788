<div [ngClass]="{
    'selected': selected,
    'fromMITM': (packet.source =='MITM_CLIENT' || packet.data._fromClientMITM) && !packet.data._fromKoli,
    'fromServer': packet.source == 'SERVER' && !packet.data._fromKoli,
    'fromBot': packet.source == 'BOT' && !packet.data._fromClientMITM && !packet.data._fromKoli,

    'fromMITMKoli':(packet.source =='MITM_CLIENT' || packet.data._fromClientMITM) && packet.data._fromKoli,
    'fromBotKoli':  packet.source == 'BOT' && packet.data._fromKoli,
    'fromServerKoli':  packet.source == 'SERVER'&& packet.data._fromKoli
  }" class="packet-line">
  <div class="packet-line-date">{{ packet.date | date:'hh:mm:ss.SSS' }} [{{ packet._indexPacket }}][{{ packet._delay }}
    ]
  </div>
  <div class="packet-line-origin">{{ packet.fromClient ? "CLIENT" : "SERVER" }}</div>
  <span [ngClass]="{'packet-blocked':packet.data._blocked}">{{ packet.data._messageType }}</span>
</div>
