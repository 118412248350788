<div class="footer-container" >
  <div class="level">
    <img class="icon-img " src="assets/img/icon/menuIconAchievement.png">
    <div class="bot-footer-element">
      <mat-progress-bar
        [mode]="'determinate'"
        [value]="bot.game.character.stats.pourcentExperience"
        class="progress-bar-stats"
        color="warn">
      </mat-progress-bar>
      <div class="value-box">
        {{(bot.game.character.level ? bot.game.character.level : '?')
      + (!userService.isMobile ? ' (' + bot.game.character.stats.pourcentExperience + '%)' : "")}}
      </div>
    </div>
  </div>

  <div class="life">
    <img class="icon-img-life" src="assets/img/icon/heart.png">
    <div class="bot-footer-element">
      <mat-progress-bar
        [mode]="'determinate'"
        [value]="bot.game.character.stats.pourcentLife"
        class="progress-bar-stats"
        color="warn">
      </mat-progress-bar>
      <div class="value-box">
        {{bot.game.character.stats.lifePoints
      + (!userService.isMobile ? ' (' + bot.game.character.stats.pourcentLife + '%)' : "")}}
      </div>
    </div>
  </div>

  <div class="pods">
    <img class="icon-img" src="assets/img/icon/menuIconBag.png">
    <div class="bot-footer-element">
      <mat-progress-bar
        [mode]="'determinate'"
        [value]="bot.game.character.inventory.pourcentPods"
        class="progress-bar-stats"
        color="warn">
      </mat-progress-bar>
      <div class="value-box">
        {{bot.game.character.inventory.weight
      + (!userService.isMobile ? '(' + bot.game.character.inventory.pourcentPods + '%)' : "")}}
      </div>
    </div>
  </div>

  <div class="energy">
    <img class="icon-img" src="assets/img/icon/flash-on.png">
    <div class="bot-footer-element">
      <mat-progress-bar
        [mode]="'determinate'"
        [value]="bot.game.character.stats.pourcentEnergy"
        class="progress-bar-stats"
        color="warn">
      </mat-progress-bar>
      <div class="value-box">
        {{bot.game.character.stats.energy
      + (!userService.isMobile ? '(' + bot.game.character.stats.pourcentEnergy + '%)' : "")}}
      </div>
    </div>
  </div>
</div>
