import {Component, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'app-folder-element',
  templateUrl: './folder-element.component.html',
  styleUrls: ['./folder-element.component.scss']
})
export class FolderElementComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: '0px', y: '0px'};

  constructor() {
  }

  ngOnInit(): void {
  }

  onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  onContextMenuAction1() {
    alert();
  }

  onContextMenuAction2() {
    alert();
  }
}
