import {Component, Inject, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
  selector: "app-dialog-question",
  templateUrl: "./dialog-question.component.html",
  styleUrls: ["./dialog-question.component.scss"]
})
export class DialogQuestionComponent implements OnInit {

  public data: {
    title: string,
    forms: {
      label: string,
      name: string,
      type: string,
      required: boolean,
      possibleValues?: {name: string, value: any}[]
      defaultValue: any
    }[]
  };
  public formGroup = new UntypedFormGroup({});

  constructor(public dialogRef: MatDialogRef<DialogQuestionComponent>, @Inject(DIALOG_DATA) data: any) {
    this.data = data;
    for (const form of data.forms) {
      this.formGroup.addControl(form.name, new UntypedFormControl(form.defaultValue,
        form.required ? [Validators.required] : []));
    }
  }

  ngOnInit(): void {
  }

  submit() {
    if (this.formGroup.valid) {
      const result: any = {};
      for (const form of this.data.forms) {
        result[form.name] = this.formGroup.get(form.name).value;
      }
      this.dialogRef.close(result);
    }
  }

  public close() {
    this.dialogRef.close(null);
  }

}
