import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProxyServersComponent} from "@dashboard/src/app/pages/user/proxy-servers/proxy-servers.component";
import {MatDividerModule} from "@angular/material/divider";
import {ProxyServerComponent} from "./proxy-server/proxy-server.component";
import {ProxyServerElementComponent} from "./proxy-server-element/proxy-server-element.component";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSortModule} from "@angular/material/sort";
import {MatDialogModule} from "@angular/material/dialog";
import {DialogQuestionModule} from "@dashboard/src/app/features/dialog-question/dialog-question.module";


@NgModule({
  declarations: [ProxyServersComponent, ProxyServerComponent, ProxyServerElementComponent],
  exports: [
    ProxyServersComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSortModule,
    MatDialogModule,
    DialogQuestionModule
  ]
})
export class ProxyServersModule {
}
