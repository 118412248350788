import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";

@Injectable({
  providedIn: "root"
})
export class ControllerResolver implements Resolve<Controller> {

  constructor(private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Controller> {
    this.userService.componentSelected = ComponentTypeSelectedEnum.CONTROLLER;
    const controller = this.userService.user.controllers.controllers.find(
      elt => elt.id === route.params.controllerId);
    // @ts-ignore
    this.userService.controllerSelected = controller;
    this.userService.teamSelected = null;
    this.userService.botSelected = null;
    this.userService.user.emit("ControllerSelectedUpdated");
    // @ts-ignore
    return of(controller);
  }
}
