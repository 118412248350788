import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@dashboard/src/app/service/user.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit {
  public activeLink = "console";

  public tabs = [
    {label: "Scripts", link: "scripts"},
    {label: "TDM", link: "tdm"},
    {label: "Proxies", link: "proxies"},
    {label: "ProxiesServer", link: "proxiesServer"},
    {label: "Config", link: "config"},
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
  }


  ngOnInit(): void {
    const childrenTabs = this.route.children.find(elt => elt.outlet == "tabs");
    if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
      this.activeLink = childrenTabs.snapshot.url[0].path;
    } else {
      this.activeLink = this.tabs[0].link;
    }
    // this.route.data.subscribe(data => {
    //   this.bot = data.bot;
    //   // this.activeTabIndex = data.tab.indexOf("_") != -1 ? data.tab.split("_")[0] : data.tab.split;
    //   // this.selectedIndex = data.tab1 ? Number(data.tab1) : 0;
    // });
  }


  navigate(path: string) {
    this.activeLink = path;
    this.router.navigate([{outlets: {tabs: path}}], {relativeTo: this.route});
  }

}
