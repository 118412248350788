import {ChatServerMessage} from "@common/lib/network/packets/ChatServerMessage";
import {Bot} from "..";

export class BotConsole {
  private bot: Bot;
  public logs: ChatServerMessage[] = [];

  constructor(bot: Bot, botConsole: BotConsole) {
    this.bot = bot;
    this.logs = botConsole.logs || [];
    this.monitorPackets();
  }


  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("packet::ChatServerMessage", (packet: ChatServerMessage) => {
      this.addLog(packet);
    });
    wrapper.addMonitorPacket("LogsUpdateMessage", (packet: any) => {
      this.logs = packet.logs;
    });
  }

  public addLog(log: ChatServerMessage) {
    this.logs.push(log);
    if (this.logs.length > 150) {
      this.logs = this.logs.slice(this.logs.length - 101, this.logs.length - 1);
    }
  }

  sendCommand(command: string) {
    this.bot.send("CommandRequestMessage", {
      command
    });
  }
}
