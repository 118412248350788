import {NezuUser} from "@dashboard/src/app/service/user";
import {ProxyInformations} from "@common/lib/models/user/proxies/proxy";

export class ProxiesManager {
  public proxies: ProxyInformations[];
  private user: NezuUser;

  constructor(proxies: ProxiesManager, user: NezuUser) {
    this.proxies = proxies.proxies || [];
    this.user = user;
    this.monitorPackets();
  }

  public addProxy(proxy: string) {
    proxy = proxy.replace("http://", "");
    proxy = proxy.replace("https://", "");
    this.user.send({
      proxy
    }, "AddProxyRequestMessage");
  }

  public deleteProxy(id: string) {
    this.user.send({
      id
    }, "DeleteProxyRequestMessage");
  }

  public rebootProxy(id: string) {
    this.user.send({
      id
    }, "RebootProxyRequestMessage");
  }

  public checkIp(id: string) {
    this.user.send({
      id
    }, "ProxyCheckIpRequestMessage");
  }

  public checkInformations(id: string) {
    this.user.send({
      id
    }, "ProxyCheckInformationsRequestMessage");
  }

  refreshIp(id: string) {
    this.user.send({
      id
    }, "RefreshIpProxyRequestMessage");
  }

  private monitorPackets() {
    const wrapper = this.user.socket.wrap();
    wrapper.addMonitorPacket("ProxyAddedMessage", (packet: any) => {
      this.proxies = this.proxies.filter(proxy => proxy.id != packet.proxy.id);
      this.proxies.push(packet.proxy);
      this.user.emit("ProxiesUpdated");
    });
    wrapper.addMonitorPacket("ProxyDeletedMessage", (packet: any) => {
      this.proxies = this.proxies.filter(proxy => proxy.id != packet.id);
      this.user.emit("ProxiesUpdated");
    });
    wrapper.addMonitorPacket("ProxyInformationUpdatedMessage", (packet: any) => {
      const proxyIndex = this.proxies.findIndex(elt => elt.id == packet.proxy.id);
      if (proxyIndex != -1) {
        this.proxies[proxyIndex] = packet.proxy;
        this.user.emit("ProxiesUpdated");
      }
    });
  }
}
