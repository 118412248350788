import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {ProxyServer} from "@dashboard/src/app/service/user/proxyServers/proxyServer";

@Component({
  selector: "app-proxy-server-element",
  templateUrl: "./proxy-server-element.component.html",
  styleUrls: ["./proxy-server-element.component.scss"]
})
export class ProxyServerElementComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: "0px", y: "0px"};
  @Input() public proxyServer: ProxyServer;
  @Input() public selected: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }
}
