import {Field, Message} from 'protobufjs';

export class BaseUserConfig extends Message<BaseUserConfig> {
    @Field.d(1, 'string', 'required', null)
    public configContent: string;

    public configData: string;

    constructor(config: BaseUserConfig) {
        super();
        this.configContent = config && config.configContent ? config.configContent : '{}';
        this.configData = JSON.parse(this.configContent);
    }
}
