import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddScriptComponent} from './add-script.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
    declarations: [AddScriptComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        TranslateModule
    ]
})
export class AddScriptModule {
}
