import {Component, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {UserService} from '@dashboard/src/app/service/user.service';
import {MatDialog} from '@angular/material/dialog';
import {AddScriptComponent} from '@dashboard/src/app/pages/user/scripts/scripts-manager/add-script/add-script.component';
import {ScriptInformations} from '@common/lib/models/user/scripts/script';

@Component({
  selector: 'app-scripts-manager',
  templateUrl: './scripts-manager.component.html',
  styleUrls: ['./scripts-manager.component.scss']
})
export class ScriptsManagerComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: '0px', y: '0px'};
  public scriptSelected: ScriptInformations;

  constructor(public userService: UserService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  addScript() {
    this.dialog.open(AddScriptComponent, {
      width: '300px',
      data: {},
    }).afterClosed().pipe(
    ).subscribe(async (content: { name: string }) => {
      if (content.name) {
        this.userService.user.scripts.addScript(content.name);
      }
    });
  }
}
