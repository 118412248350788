<form (ngSubmit)="addProxy()" [formGroup]="form" class="form-list">
  <mat-form-field appearance="outline" class="proxy-input">
    <mat-label>Proxy (http://login:password&#64;ip:port)</mat-label>
    <input formControlName="proxy" matInput placeholder="http://login:password@ip:port">
    <!--    <mat-hint>Errors appear instantly!</mat-hint>-->
    <!--    <mat-error *ngIf="form.get('login')?.hasError('email') && !form.get('login')?.hasError('required')">-->
    <!--      Please enter a valid email address-->
    <!--    </mat-error>-->
    <!--    <mat-error *ngIf="form.get('login')?.hasError('required')">-->
    <!--      Email is <strong>required</strong>-->
    <!--    </mat-error>-->
  </mat-form-field>
  <button class="submit-button" color="primary" mat-raised-button type="submit">
    Add
  </button>
</form>

<div class="table-with-paginator mat-elevation-z8">
  <div class="mat-elevation-z8 table-container">
    <table [dataSource]="dataSource" mat-table>
      <ng-container matColumnDef="url">
        <th *matHeaderCellDef mat-header-cell> Proxy</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{ element.url }}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="actualIp">
        <th *matHeaderCellDef mat-header-cell> Actual IP</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{ element.actualIp }}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="upTime">
        <th *matHeaderCellDef mat-header-cell> Up Time (s)</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{ element.deviceInformation?.uptime }}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th *matHeaderCellDef mat-header-cell> Operator</th>
        <td *matCellDef="let element" mat-cell>
          <div>
            {{ element.currentPlmn?.FullName }}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell> State</th>
        <td *matCellDef="let element" mat-cell>
          <div [style.color]="'green'" [style.fontStyle]="'bold'">
            {{ "ONLINE" }}
          </div>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell> Actions</th>
        <td *matCellDef="let element" class="actions-list" mat-cell>
          <button (click)="$event.stopPropagation(); copyProxy(element)"
                  aria-label="Example icon-button with a heart icon" color="primary" mat-raised-button>
            Copy
          </button>
          <button (click)="$event.stopPropagation(); userService.user.proxies.checkInformations(element.id)"
                  aria-label="Example icon-button with a heart icon" color="primary" mat-raised-button>
            Check Infos
          </button>
          <button (click)="$event.stopPropagation(); userService.user.proxies.checkIp(element.id)"
                  aria-label="Example icon-button with a heart icon" color="primary" mat-raised-button>
            Check IP
          </button>
          <button (click)="$event.stopPropagation(); userService.user.proxies.refreshIp(element.id)"
                  aria-label="Example icon-button with a heart icon" color="warn" mat-raised-button>
            Refresh IP
          </button>
          <button (click)="$event.stopPropagation(); userService.user.proxies.rebootProxy(element.id)"
                  aria-label="Example icon-button with a heart icon" color="warn" mat-raised-button>
            Reboot
          </button>
          <button (click)="$event.stopPropagation(); userService.user.proxies.deleteProxy(element.id)"
                  aria-label="Example icon-button with a heart icon" color="warn"
                  mat-icon-button>
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    aria-label="Select page of process"
    showFirstLastButtons>
  </mat-paginator>
</div>
