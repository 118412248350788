<div [ngClass]="{'selected': selected}" [style.background]="inGroup ? '272727':'272727'" class="controller-container">

  <div [style.background-color]="controller.socket?.connected ? 'green':'red'"
       class="state mat-elevation-z8"></div>

  <button
    (contextmenu)="clickOnContextMenu($event)"
    [routerLink]="'controller/' + controller.id"

    class="select-controller-button"
    mat-button>

    {{ name | slice:0:8 }}
  </button>

  <div class="bottom-left">
    <span *ngIf="!selected">{{ controller.countBotsConnected }} / {{ controller.countBots }}</span>
    <span *ngIf="selected">{{ infosCountBot }}</span>
  </div>

  <div [matMenuTriggerFor]="contextMenu"
       [style.left]="contextMenuPosition.x"
       [style.top]="contextMenuPosition.y">
  </div>
  <mat-menu #contextMenu="matMenu">
    <ng-template let-item="item" matMenuContent>
      <button (click)="rename()" mat-menu-item>Rename</button>
      <button (click)="restartController()" mat-menu-item>Restart</button>
      <button [matMenuTriggerFor]="moveTo" mat-menu-item>Move To</button>
    </ng-template>
  </mat-menu>

  <mat-menu #moveTo="matMenu">
    <button (click)="userService.user.controllers.moveControllerToGroup(schema, group)"
            *ngFor="let group of otherGroups"
            mat-menu-item>{{ group.name }}
    </button>
  </mat-menu>
</div>
