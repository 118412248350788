<form [formGroup]="form" class="form-tool-bar">
  <mat-form-field (keydown)="$event.stopPropagation()" (keyup)="$event.stopPropagation()" appearance="outline"
                  class="search-bar">
    <mat-label>Recherche de stats</mat-label>
    <input formControlName="searchText" matInput>
  </mat-form-field>
</form>

<div class="table-with-paginator mat-elevation-z8">
  <div class="table-container">
    <table [dataSource]="dataSource" class="table-inventory" mat-table matSort>
      <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell> Date</th>
        <td *matCellDef="let element" mat-cell> {{ element.date | date:'dd/MM/yyyy HH:mm:ss' }} </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th *matHeaderCellDef mat-header-cell> Comment</th>
        <td *matCellDef="let element" mat-cell> {{element.comment}}</td>
      </ng-container>
      <ng-container matColumnDef="level">
        <th *matHeaderCellDef mat-header-cell> Level</th>
        <td *matCellDef="let element" mat-cell> {{element.level}}</td>
      </ng-container>
      <ng-container matColumnDef="experience">
        <th *matHeaderCellDef mat-header-cell> Experience</th>
        <td *matCellDef="let element" mat-cell> {{element.experience}}</td>
      </ng-container>
      <ng-container matColumnDef="energy">
        <th *matHeaderCellDef mat-header-cell> Energy</th>
        <td *matCellDef="let element" mat-cell> {{element.energy}}</td>
      </ng-container>
      <ng-container matColumnDef="pods">
        <th *matHeaderCellDef mat-header-cell> Pods</th>
        <td *matCellDef="let element" mat-cell> {{element.pods}}</td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th *matHeaderCellDef mat-header-cell> Position</th>
        <td *matCellDef="let element" mat-cell> {{element.position}}</td>
      </ng-container>
      <ng-container matColumnDef="mapid">
        <th *matHeaderCellDef mat-header-cell> Mapid</th>
        <td *matCellDef="let element" mat-cell> {{element.mapid}}</td>
      </ng-container>
      <ng-container matColumnDef="kamas">
        <th *matHeaderCellDef mat-header-cell> Kamas</th>
        <td *matCellDef="let element" mat-cell> {{element.kamas}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell> Actions</th>
        <td *matCellDef="let element" class="cell-actions" mat-cell>
          <button
            color="primary" mat-raised-button>
            Equip
          </button>
          <button
            color="primary" mat-raised-button>
            Remove
          </button>
          <button color="primary" mat-raised-button>
            Throw
          </button>
          <button color="warn" mat-raised-button>
            Delete
          </button>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    aria-label="Select page of inventory"
    showFirstLastButtons>
  </mat-paginator>
</div>
<!--<div class="character-container">-->
<!--<img class="image-character"-->
<!--src="http://staticns.ankama.com/dofus/renderer/look/7b317c3131312c323138342c3233392c3436312c313235307c313d33333334323332312c323d33373938313938392c333d35313232383336392c343d37373830333639382c353d38343038323835387c3134307d/full/2/500_500-0.png">-->
<!--</div>-->


