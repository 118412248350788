<form [formGroup]="formGroup">
  <h3 mat-dialog-title class="title">Add bot</h3>

  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Alias</mat-label>
      <input formControlName="alias" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Login</mat-label>
      <input formControlName="login" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input formControlName="password" matInput>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="actions">
    <button class="action-button" color="primary" mat-raised-button cdkFocusInitial (click)="submit()">Valid</button>
    <button class="action-button" color="primary" mat-dialog-close mat-raised-button (click)="close()">Cancel</button>
  </div>
</form>
