import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {Controller} from "@dashboard/src/app/service/user/controllers/controller";
import {
  AddBotInstanceDialogComponent
} from "@dashboard/src/app/features/sidenav/controller-content/add-bot-instance-dialog/add-bot-instance-dialog.component";
import {
  AddTeamInstanceDialogComponent
} from "@dashboard/src/app/features/sidenav/controller-content/add-team-instance-dialog/add-team-instance-dialog.component";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";
import {Utils} from "@common/lib/utils";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {AccountsService} from "@dashboard/src/app/service/accounts.service";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: "app-controller-content",
  templateUrl: "./controller-content.component.html",
  styleUrls: ["./controller-content.component.scss"]
})
export class ControllerContentComponent implements OnInit {
  @Input() controller: Controller;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};
  public ComponentTypeSelectedEnum = ComponentTypeSelectedEnum;

  public isMobile: boolean = false;

  constructor(
    public dialog: MatDialog,
    private clipboard: Clipboard,
    public userService: UserService,
    public accountsService: AccountsService
  ) {
  }

  public get infosCountBot() {
    const countBots = this.controller.bots.length;
    const countBotsConnected = this.controller.bots.filter(elt => elt.network.connected).length;
    return `${countBotsConnected} / ${countBots}`;
  }

  public get teams() {
    return this.controller.teams.filter(team => {
      const nameTeam = team.name;
      return true;
    });
  }

  ngOnInit(): void {

  }

  onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
    // this.ref.detectChanges();
  }

  addBot() {
    this.dialog.open(AddBotInstanceDialogComponent, {
      width: "500px",
      data: {}
    }).afterClosed().pipe(
    ).subscribe(async (bot: any) => {
      if (bot) {
        this.controller.addBot(bot);
      }
    });
  }

  addTeam() {
    this.dialog.open(AddTeamInstanceDialogComponent, {
      width: "500px",
      data: {}
    }).afterClosed().pipe(
    ).subscribe(async (team: any) => {
      if (team) {
        this.controller.addTeam(team);
      }
    });
  }

  dropTeam(event: CdkDragDrop<Team[]>) {
    console.log("MoveTeamInArray");
    this.controller.moveTeamInArray(event.previousIndex, event.currentIndex);
  }

  exportAccounts() {
    const date = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear();
    const filename = this.controller.config.configData.name + "_" + date + ".csv";
    let accounts = "";
    for (const team of this.controller.teams) {
      for (const bot of team.bots) {
        accounts += `${bot.login};${bot.password};${team.name}` + "\n";
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(accounts));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async generateTeam() {
    if (this.controller.teams.findIndex(elt => elt.name.toLowerCase() == "lutin") == -1) {
      this.controller.addTeam({name: `LUTIN`} as Team, {});
    }
    if (this.controller.teams.findIndex(elt => elt.name.toLowerCase() == "fm") == -1) {
      this.controller.addTeam({name: `FM`} as Team);
    }
    if (this.controller.teams.findIndex(elt => elt.name.toLowerCase() == "----------------") == -1) {
      this.controller.addTeam({name: `----------------`} as Team);
    }
    for (let i = 1; i <= 30; i++) {
      const name = `T${i} KOLI`;
      const team = this.controller.teams.find(elt => elt.name.toLowerCase() == name.toLowerCase());
      if (!team) this.controller.addTeam({name} as Team);
    }


    await Utils.sleep(2000);


    for (let i = 1; i <= 30; i++) {
      const team = this.controller.teams.find(elt => elt.name.toLowerCase() == `T${i} KOLI`.toLowerCase());
      if (team) {
        team.config.updateConfig(JSON.stringify({
          "RESET_URL": "http://94.107.22.52:40800/reset?proxy=p:" + (4000 + i),
          "proxy": "http://nes:Cdh3652fHPtC6h@94.107.22.52:" + (40800 + i),
          "scriptName": "KOLI",
          "server": this.controller.config.configData.server,
          "ACTIVE_AUTO_CONNECT": true,
          "AUTO_BUY_STUFF": true,
          "AUTO_STUFF": true,
          "LOW_COST": true,
          "MAP_FOR_INACTIVITY": "HDV_RESSOURCES_BONTA"
        }, null, "\t"));
      }
    }

  }

  removeBans() {
    for (const bot of this.controller.bots.filter(elt => elt.isBan)) {
      this.controller.removeBot(bot.login);
    }
  }

  connectBotsDisconnected() {
    for (const team of this.controller.teams) {
      team.connectBotsDisconnected();
    }
  }

  disconnectBots() {
    for (const team of this.controller.teams) {
      team.disconnectBots();
    }
  }

  restart() {
    this.controller.restart();
  }

  fillAccounts() {
    this.controller.fillAccounts();
  }

  fillOldAccounts() {
    const server = this.controller.config.configData.server;
    if (this.userService.user.login == "nes") {
      let countNeed = 0;
      for (const team of this.controller.teams) {
        countNeed += team.bots.length < 8 ? 8 - team.bots.length : 0;
      }
      this.accountsService.getOldAccounts(server, countNeed).subscribe((result: string) => {
        const accounts = result.split("<br>").filter(elt => elt != "");
        for (const team of this.controller.teams) {
          const countNeed = team.bots.length < 8 ? 8 - team.bots.length : 0;
          const accountsLineTmp = accounts.splice(0, Math.min(countNeed, accounts.length));
          for (let accountLine of accountsLineTmp) {
            accountLine = Utils.replaceAll(accountLine, "\t", " ");
            const [login, password] = accountLine.split(" ");
            team.controller.addBot({
              alias: login.indexOf("@") != -1 ? login.split("@")[0] : login,
              login: login,
              password: password
            }, team.id);
          }
        }
      });
    }
  }

  addProxies() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Add proxies from text",
        forms: [{
          label: "login:password@ip:port",
          name: "name",
          type: "textarea",
          required: true,
          defaultValue: ""
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        let i = 0;
        const server = this.controller.config.configData.server;
        for (let line of result.name.split("\n")) {
          if (line == "") continue;
          i++;
          line = Utils.replaceAll(line, "\t", " ");
          line = Utils.replaceAll(line, "http://", "");
          const [cred, host] = line.split("@");
          const [login, password] = cred.split(":");
          const [ip, port] = host.split(":");
          if (this.controller.teams.findIndex(elt => elt.name == `TEAM_${i}`) == -1) {
            this.controller.addTeam({name: `TEAM_${i}`} as Team, {
              "scriptName": "QUESTS_ASTRUB",
              "proxy": `http://${login}:${password}@${ip}:${port}`,
              "server": server,
              "breed": "Forgelance",
              "delay": 0
            });
            await Utils.sleep(500);
          }
        }
      }
    });
  }

  fillAccountsFromText() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Add bots from text",
        forms: [{
          label: "login password per line",
          name: "name",
          type: "textarea",
          required: true,
          defaultValue: ""
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        const accs = [];
        for (let line of result.name.split("\n")) {
          if (line == "") continue;
          line = Utils.replaceAll(line, "\t", " ");
          const [login, password] = line.split(" ");
          accs.push({login, password});
        }

        const teams = this.controller.teams.filter(elt => elt.bots.length == 0);
        for (const team of teams) {
          const accountsTmp = accs.splice(0, Math.min(8, accs.length));
          for (const account of accountsTmp) {
            team.controller.addBot({
              alias: account.login.indexOf("@") != -1 ? account.login.split("@")[0] : account.login,
              login: account.login,
              password: account.password
            }, team.id);
          }
        }
        for (let acc of accs) {
          console.log(acc.login + "\t" + acc.password);
        }
      }
    });

  }

  removeAllBots() {
    for (const team of this.controller.teams) {
      for (const bot of team.bots) {
        team.controller.removeBot(bot.login);
      }
    }
  }

  public async rebuildTeam() {
    for (const team of this.controller.teams) {
      if (team.bots.length > 7) {
        const accs = team.bots.splice(0, team.bots.length - 7);
        for (const acc of accs) {
          const teamWithPlace = this.controller.teams.find(elt => elt.bots.length < 7);
          if (teamWithPlace) {
            team.controller.changeTeamBot(acc.login, teamWithPlace.id);
            await Utils.sleep(1000);
          }
        }
      }
    }
  }

  createAllBots() {
    for (const team of this.controller.teams) {
      if (team.kamas == 0) {
        team.createFromConfig();
      }
    }
  }

  addBotsFromText() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Add bots from text",
        forms: [{
          label: "login password per line",
          name: "name",
          type: "textarea",
          required: true,
          defaultValue: ""
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        let accounts = result.name.split("\n").map((line: string) => {
          line = Utils.replaceAll(line, "\t", " ");
          const [login, password] = line.split(" ");
          return {login, password};
        });
        accounts = accounts.filter((elt: any) => elt.login && elt.password);
        for (const team of this.controller.teams) {
          if (team.bots.length >= 8) continue;
          const accountsTmp = accounts.splice(0, Math.min(8 - team.bots.length, accounts.length));
          for (const acc of accountsTmp) {
            this.controller.addBot({
              alias: acc.login.indexOf("@") != -1 ? acc.login.split("@")[0] : acc.login,
              login: acc.login,
              password: acc.password
            }, team.id);
          }
        }
        for (const acc of accounts) {
          this.controller.addBot({
            alias: acc.login.indexOf("@") != -1 ? acc.login.split("@")[0] : acc.login,
            login: acc.login,
            password: acc.password
          });
        }
      }
    });
  }

  copyIds() {
    let str = "";
    for (const bot of this.controller.bots) {
      str += bot.login + " " + bot.password + "\n";
    }
    this.clipboard.copy(str);
  }

  public generate() {

  }
}
