import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserConfigComponent} from './user-config.component';


@NgModule({
  declarations: [UserConfigComponent],
  exports: [UserConfigComponent],
  imports: [
    CommonModule
  ]
})
export class UserConfigModule {
}
