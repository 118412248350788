import {Component, OnInit} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {environment} from "@dashboard/src/environments/environment";

@Component({
  selector: "app-bot-inventory-character",
  templateUrl: "./bot-inventory-character.component.html",
  styleUrls: ["./bot-inventory-character.component.scss"]
})
export class BotInventoryCharacterComponent implements OnInit {
  public bot: Bot;
  public pathImages = environment.HTTP_SERVER_ADDRESS + "/images/items/";

  constructor(private userService: UserService) {
    this.bot = userService.botSelected;
  }

  ngOnInit(): void {
  }

  getElement(position: number): any {
    return this.bot.game.character.inventory.objects.find(elt => elt.position == position);
  }
}
