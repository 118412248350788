import {CustomDispatcher} from "@common/lib/utils/CustomDispatcher";
import {NezuUser} from "@dashboard/src/app/service/user";
import {CustomProxy} from "@dashboard/src/app/service/user/proxyServers/proxyServer/customProxy";

export class ProxyServer extends CustomDispatcher {
  public id: string;
  public ip: string;
  public connected: boolean;
  public proxies: CustomProxy[];
  public linkProxy: string;
  private user: NezuUser;

  public constructor(proxyServer: ProxyServer, user: NezuUser) {
    super("proxyServer");
    this.id = proxyServer.id;
    this.connected = proxyServer.connected;
    this.user = user;
    this.proxies = proxyServer.proxies.map(proxy => new CustomProxy(proxy, this, this.user)) || [];
    this.ip = proxyServer.ip;
    this.linkProxy = proxyServer.linkProxy;
    this.monitorPackets();
  }

  deleteProxy(id: string) {

  }

  public sendToSupervisor(callName: string, data: any) {
    this.user.socket.send({
      cible: "PROXY_SERVER",
      id: this.id,
      callName,
      data,
      directToCible: false
    }, "PacketWithCibleProxyServers");
  }

  public sendToProxyServer(callName: string, data: any) {
    this.user.socket.send({
      cible: "PROXY_SERVER",
      id: this.id,
      callName,
      data,
      directToCible: true
    }, "PacketWithCibleProxyServers");
  }

  autoDetect() {
    this.sendToProxyServer("AutoDetectRequestMessage", {});
  }

  autoConfig() {
    this.sendToProxyServer("AutoConfigRequestMessage", {});
  }

  public setLinkProxy(newValue: string) {
    this.sendToSupervisor("UpdateLinkProxyRequestMessage", {
      linkProxy: newValue
    });
  }

  private monitorPackets() {
    const wrapper = this.wrap();
    wrapper.addMonitorPacket("ProxyServerSocketStateUpdated", (packet: any) => {
      this.connected = packet.connected;
    });
    wrapper.addMonitorPacket("ProxyServerInformationsMessage", (packet: any) => {
      this.proxies = packet.proxies.map((elt: any) => new CustomProxy(elt, this, this.user));
      this.user.emit("ProxiesUpdated");
    });
    wrapper.addMonitorPacket("LinkProxyUpdatedMessage", (packet: any) => {
      this.linkProxy = packet.linkProxy;
    });
    wrapper.addMonitorPacket("CustomProxyUpdatedMessage", (packet: any) => {
      console.log("CustomProxyUpdatedMessage");
      const proxy = this.proxies.find(elt => elt.id == packet.proxy.id);
      const indexProxy = this.proxies.findIndex(elt => elt.id == packet.proxy.id);
      if (indexProxy != -1) {
        proxy.ssid = packet.proxy.ssid;
        proxy.interfaceName = packet.proxy.interfaceName;
        proxy.localIp = packet.proxy.localIp;
        proxy.wifiPassword = packet.proxy.wifiPassword;
        proxy.infos = packet.proxy.infos;
        proxy.publicIp = packet.proxy.publicIp;


        proxy.netMode = packet.proxy.netMode;
        proxy.netStatus = packet.proxy.netStatus;
        proxy.oper = packet.proxy.oper;
        proxy.rssi = packet.proxy.rssi;
      } else this.proxies.push(new CustomProxy(packet.proxy, this, this.user));
      this.user.emit("ProxiesUpdated");
    });
  }
}
