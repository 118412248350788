import {Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {debounceTime} from "rxjs";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Utils} from "@common/lib/utils";
import {GameRolePlayShowActorMessage} from "@protocol/messages/GameRolePlayShowActorMessage";
import {GameContextRemoveElementMessage} from "@protocol/messages/GameContextRemoveElementMessage";
import {GameContextRemoveMultipleElementsMessage} from "@protocol/messages/GameContextRemoveMultipleElementsMessage";
import {GameMapMovementMessage} from "@protocol/messages/GameMapMovementMessage";
import {TeleportOnSameMapMessage} from "@protocol/messages/TeleportOnSameMapMessage";
import {GameRolePlayActorInformations} from "@protocol/types/GameRolePlayActorInformations";
import Konva from "konva";
import {MovementPath} from "@dashboard/src/app/utils/MovementPath";
import {GameContextCreateMessage} from "@protocol/messages/GameContextCreateMessage";
import {MatMenuTrigger} from "@angular/material/menu";
import {
  LightFighterInformations
} from "@dashboard/src/app/service/user/controllers/controller/bot/game/fight/LightFighterInformations";
import Shape = Konva.Shape;

const log = Utils.createDebug("app:mapViewer");
const CHANGE_MAP_MASK_RIGHT = 1 | 2 | 128;
const CHANGE_MAP_MASK_BOTTOM = 2 | 4 | 8;
const CHANGE_MAP_MASK_LEFT = 8 | 16 | 32;
const CHANGE_MAP_MASK_TOP = 32 | 64 | 128;

@Component({
  selector: "app-bot-map",
  templateUrl: "./bot-map.component.html",
  styleUrls: ["./bot-map.component.scss"]
})
export class BotMapComponent implements OnInit {
  @ViewChild("KonvaContainer") myCanvas: ElementRef;
  public bot: Bot;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};


  public tileWidth = 88;
  public tileHeight = 42;
  public MAP_WIDTH = 14;
  public MAP_HEIGHT = 20;
  public CELLPOS: any[] = [];
  public stage: Konva.Stage;
  public layer: Konva.Layer;
  public entities: {
    entitie: GameRolePlayActorInformations,
    shape: Shape
  }[] = [];
  public fighters: {
    fighter: LightFighterInformations,
    shape: Shape
  }[] = [];
  private wrapper: CustomWrapper;
  private changeSize = new EventEmitter();
  private focusOnTab = false;

  constructor(public userService: UserService) {
    this.bot = userService.botSelected;
  }

  ngOnDestroy(): void {
    if (this.stage) this.stage.destroy();
    if (this.layer) this.layer.destroy();
    if (this.wrapper) this.wrapper.done();
  }

  async ngOnInit() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("GameContextCreateMessage", (packet: GameContextCreateMessage) => {
      this.updateMap();
    }, 5000);
    wrapper.addMonitorPacket("FightersUpdated", () => {
      this.genFighters();
    });
    wrapper.addMonitorPacket("MapDataUpdated", () => {
      this.updateMap();
    });
    wrapper.addMonitorPacket("GameRolePlayShowActorMessage", (packet: GameRolePlayShowActorMessage) => {
      const entite = this.entities.find(elt => elt.entitie.contextualId == packet.informations.contextualId);
      if (entite) {
        entite.shape.move({
          x: this.CELLPOS[packet.informations.disposition.cellId].pixelX,
          y: this.CELLPOS[packet.informations.disposition.cellId].pixelY
        });
      } else {
        this.addEntitie(packet.informations);
      }
    });
    wrapper.addMonitorPacket("GameContextRemoveElementMessage", (packet: GameContextRemoveElementMessage) => {
      const entite = this.entities.find(elt => elt.entitie.contextualId == packet.id);
      if (entite) {
        this.entities = this.entities.filter(elt => elt != entite);
        entite.shape.remove();
      }
    });
    wrapper.addMonitorPacket("GameContextRemoveMultipleElementsMessage", (packet: GameContextRemoveMultipleElementsMessage) => {
      for (const id of packet.elementsIds) {
        const entite = this.entities.find(elt => elt.entitie.contextualId == id);
        if (entite) {
          this.entities = this.entities.filter(elt => elt != entite);
          entite.shape.remove();
        }
      }
    });
    wrapper.addMonitorPacket("GameMapMovementMessage", (packet: GameMapMovementMessage) => {
      const entity = this.entities.find(elt => elt.entitie.contextualId === packet.actorId);
      if (entity) {
        this.moveEntitieToCellId(entity, packet.keyMovements);
      }
    });
    wrapper.addMonitorPacket("TeleportOnSameMapMessage", (packet: TeleportOnSameMapMessage) => {
      const entity = this.entities.find(elt => elt.entitie.contextualId === packet.targetId);
      if (entity) {

      }
    });
    if (this.bot.game.map.mapData) this.updateMap();
    this.changeSize.pipe(
      debounceTime(100)
    ).subscribe(data => {
      log("update size mapViewer");
      this.fitStageIntoParentContainer();
    });
  }


  async updateMap() {
    if (!this.myCanvas) {
      await Utils.sleep(500);
    }
    if (this.stage) this.stage.destroy();
    if (this.layer) this.layer.destroy();
    this.entities = [];
    this.fighters = [];
    this.stage = new Konva.Stage({
      container: "container-konva",
      width: this.myCanvas.nativeElement.clientWidth,
      height: this.myCanvas.nativeElement.clientHight
    });
    this.stage.on("contextmenu", (e) => {
      // prevent default behavior
      e.evt.preventDefault();
      if (e.target === this.stage) return;
      const currentShape = e.target;
      // show menu
      const menuNode = document.getElementById("menu");
      menuNode.style.display = "initial";
      const pointerPosition = this.stage.getPointerPosition();
      var containerRect = this.stage.container().getBoundingClientRect();
      menuNode.style.left = (e.evt.clientX - containerRect.x) + "px";
      menuNode.style.top = (e.evt.clientY - 48) + "px";

      //
      //
      // if (e.target === this.stage) return;
      // const currentShape = e.target;
      // e.evt.stopPropagation();
      // e.evt.preventDefault();
      // const menuNode = document.getElementById("menu");
      // menuNode.style.display = "initial";
      // const containerRect = this.stage.container().getBoundingClientRect();
      // menuNode.style.top = containerRect.top + this.stage.getPointerPosition().y + 4 + "px";
      // menuNode.style.left = containerRect.left + this.stage.getPointerPosition().x + 4 + "px";
      console.log("test");
    });
    this.layer = new Konva.Layer();
    this.stage.add(this.layer);
    this.initCells();
    const map = this.bot.game.map.mapData;
    if (this.bot.game.character.state.context == 1) {
      this.genMapRolePlay(map, this.bot);
    } else {
      this.genMapFight(map, this.bot);
    }
    this.fitStageIntoParentContainer();
  }

  @HostListener("window:resize", ["$event.target"])
  public onResize(target: any) {
    this.changeSize.emit("update");
  }


  public fitStageIntoParentContainer() {
    const containerWidth = this.myCanvas.nativeElement.offsetWidth;
    const containerHeight = this.myCanvas.nativeElement.offsetHeight;
    const stageWidth = this.tileWidth * (this.MAP_WIDTH + 0.5);
    const stageHeight = this.tileHeight * (this.MAP_HEIGHT + 0.5);
    // var scale = containerWidth / stageWidth;
    let scale = 0;
    if (this.myCanvas.nativeElement.clientWidth > this.myCanvas.nativeElement.clientHeight *
      (stageWidth / stageHeight)) {
      scale = containerHeight / stageHeight;
    } else scale = containerWidth / stageWidth;
    this.stage.width(stageWidth * scale);
    this.stage.height(stageHeight * scale);
    this.stage.scale({x: scale, y: scale});
    this.stage.draw();
  }

  genMapRolePlay(mapJson: any, bot: Bot) {
    this.genTiles(mapJson);
    for (const entitie of this.bot.game.map.actors.entities) {
      this.addEntitie(entitie);
    }
    for (const elt of this.bot.game.map.interactiveElements) {
      // @ts-ignore
      const cellId: number = elt["_cellId"];
      const interactShape = this.drawCircle(
        this.CELLPOS[cellId].pixelX,
        this.CELLPOS[cellId].pixelY,
        "blue", cellId);
      interactShape.on("click", () => this.bot.game.map.moveAndUseElementOnCellId(cellId));
    }
    for (const key of Object.keys(this.CELLPOS)) {
      const cellId: number = parseInt(key);
      const cellData = mapJson.cells[cellId];
      const left = cellData.mapChangeData & CHANGE_MAP_MASK_LEFT && (cellId % 14 === 0);
      const right = cellData.mapChangeData & CHANGE_MAP_MASK_RIGHT && (cellId % 14 === 13);
      const top = cellData.mapChangeData & CHANGE_MAP_MASK_TOP && (cellId < 28);
      const bottom = cellData.mapChangeData & CHANGE_MAP_MASK_BOTTOM && (cellId > 531);
      if (left) {
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "green");
      } else if (right) {
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "red");
      } else if (top) {
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "blue");
      } else if (bottom) {
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "violet");
      } else {
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "grey");
      }
    }
  }

  genMapFight(mapJson: any, bot: Bot) {
    this.genTiles(mapJson);
    this.genFighters();
  }

  genFighters() {
    for (const fighter of this.fighters) {
      fighter.shape.remove();
    }
    this.fighters = [];
    for (const fighter of [...this.bot.game.fight.allies, ...this.bot.game.fight.enemies]) {
      if (!fighter.alive) continue;
      let colorCell = "red";
      if (fighter.contextualId == this.bot.game.character.id) {
        colorCell = "lightgreen";
      } else if (!fighter.teamId) {
        colorCell = "green";
      }
      const fighterShape = this.drawCircle(
        this.CELLPOS[fighter.disposition.cellId].pixelX,
        this.CELLPOS[fighter.disposition.cellId].pixelY, colorCell, fighter.disposition.cellId);
      this.fighters.push({
        fighter: fighter,
        shape: fighterShape
      });
    }
  }

  genTiles(mapJson: any) {
    const content = mapJson;
    for (const [cellId, cell] of content.cells.entries()) {
      const obstacle = this.bot.game.map.obstacles.find(elt => elt.obstacleCellId == cellId);
      if (obstacle && obstacle.state == 1) {
        this.CELLPOS[cellId].mov = true;
        this.CELLPOS[cellId].los = true;
      } else {
        this.CELLPOS[cellId].mov = cell.mov;
        this.CELLPOS[cellId].los = cell.los;
      }
    }
    for (const key of Object.keys(this.CELLPOS)) {
      const cellId: number = parseInt(key);
      // Affichage de la grille
      let color = "#ffffff";
      let stroke = "#9e9a9a";
      let ddd = false;

      const obstacle = this.bot.game.map.obstacles.find(elt => elt.obstacleCellId == cellId);
      if (obstacle && obstacle.state == 1) {
        color = "#40ff00";
      }
      if (obstacle && obstacle.state != 1) {
        color = "#ff0000";
      } else if (!this.CELLPOS[cellId].los) {
        color = "#767373";
        ddd = true;
      } else if (!this.CELLPOS[cellId].mov) {
        color = "#b3afaf";
      }
      const poly = this.drawTile(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, color, cellId, stroke, ddd);
      const cellData = content.cells[cellId];
      const left = cellData.mapChangeData & CHANGE_MAP_MASK_LEFT && (cellId % 14 === 0);
      const right = cellData.mapChangeData & CHANGE_MAP_MASK_RIGHT && (cellId % 14 === 13);
      const top = cellData.mapChangeData & CHANGE_MAP_MASK_TOP && (cellId < 28);
      const bottom = cellData.mapChangeData & CHANGE_MAP_MASK_BOTTOM && (cellId > 531);
      if (left) {
        poly.on("click", (event) => {
          if (event.evt.button != 0) return false;
          this.bot.game.map.changeMap("left", cellId);
        });
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "green");
      } else if (right) {
        poly.on("click", (event) => {
          if (event.evt.button != 0) return false;
          this.bot.game.map.changeMap("right", cellId);
        });
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "red");
      } else if (top) {
        poly.on("click", (event) => {
          if (event.evt.button != 0) return false;
          this.bot.game.map.changeMap("top", cellId);
        });
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "blue");
      } else if (bottom) {
        poly.on("click", (event) => {
          if (event.evt.button != 0) return false;
          this.bot.game.map.changeMap("bottom", cellId);
        });
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "violet");
      } else {
        poly.on("click", (event) => {
          if (event.evt.button != 0) return false;
          this.bot.game.map.moveToCell(cellId);
        });
        this.printCellId(this.CELLPOS[cellId].pixelX, this.CELLPOS[cellId].pixelY, cellId, "grey");
      }
    }
  }

  public printCellId(x: number, y: number, cellId: number, color = "black") {
    if (!this.CELLPOS[cellId].los) {
      y = y - this.tileHeight / 4;
      color = "#474747";
    }
    const text = new Konva.Text({
      text: cellId.toString(),
      fontFamily: "Arial",
      fontSize: 15,
      fill: color,
      align: "center",
      verticalAlign: "middle",
      x: x + this.tileWidth / 2 - 15,
      y: y + this.tileHeight / 2 - 0,
      width: 30,
      height: 1
    });
    text.on("click", () => {
      // this.clickOnCell(cellId);
    });
    this.layer.add(text);
  }

  public getColorFromString(str: string) {
    let i = 0;
    let r = 0;
    let g = 0;
    let b = 0;
    for (i = 0; str && i < str.length; ++i) {
      switch (i % 3) {
        case 0:
          r += str.charCodeAt(i) * 20;
          g += str.charCodeAt(i) * 10;
          b += str.charCodeAt(i) * 40;
          break;
        case 1:
          r += str.charCodeAt(i) * 10;
          g += str.charCodeAt(i) * 40;
          b += str.charCodeAt(i) * 20;
          break;
        case 2:
          r += str.charCodeAt(i) * 40;
          g += str.charCodeAt(i) * 20;
          b += str.charCodeAt(i) * 10;
          break;
      }
    }
    r = 0xee - (r % 150);
    g = 0xee - (g % 150);
    b = 0xee - (b % 150);
    return ((r & 0xff) << 16) | ((g & 0xff) << 8) | (b & 0xff);
  }

  private drawCircle(x: number, y: number, color: string, cellId: number): Shape {
    if (!this.CELLPOS[cellId].los) {
      y = y - this.tileHeight / 4;
    }
    // @ts-ignore
    const fill = color;
    const circle = new Konva.Circle({
      x: x + this.tileWidth / 2,
      y: y + this.tileHeight / 2,
      radius: this.tileHeight / 3,
      fill
    });
    this.layer.add(circle);
    return circle;
  }

  private drawSquare(x: number, y: number, color: string, cellId: number) {
    const rec = new Konva.Rect({
      x: x + this.tileHeight * 0.7,
      y: y + this.tileHeight * 0.2,
      width: this.tileHeight * 0.6,
      height: this.tileHeight * 0.6,
      fill: color
    });
    rec.on("click", function () {
      console.log("Click on rec" + cellId);
    });
    this.layer.add(rec);
  }

  private drawSun(x: number, y: number, cellId: number) {
    const img = new Image();
    img.onload = () => {
      const image = new Konva.Image({
        x: x + this.tileHeight * 0.45,
        y: y + this.tileHeight * 0.2,
        image: img,
        width: img.width,
        height: img.height
      });
      image.on("click", function () {
        console.log("Click on Sun" + cellId);
      });
      this.layer.add(image);
      this.layer.batchDraw();
    };
    img.src = "https://ankama.akamaized.net/games/dofus-tablette/assets/2.21.2/gfx/world/png/21000.png";
  }

  private initCells() {
    let startX = 0;
    let startY = 0;
    let cell = 0;
    for (let a = 0; a < this.MAP_HEIGHT; a++) {
      for (let b = 0; b < this.MAP_WIDTH; b++) {
        const p = this.cellCoords(cell);
        this.CELLPOS[cell] = {
          x: startX + b,
          y: startY + b,
          pixelX: p.x * this.tileWidth + (p.y % 2 === 1 ? this.tileWidth / 2 : 0),
          pixelY: (p.y * this.tileHeight) / 2
        };
        cell++;
      }
      startX++;
      for (let b = 0; b < this.MAP_WIDTH; b++) {
        const p = this.cellCoords(cell);
        this.CELLPOS[cell] = {
          x: startX + b,
          y: startY + b,
          pixelX: p.x * this.tileWidth + (p.y % 2 === 1 ? this.tileWidth / 2 : 0),
          pixelY: (p.y * this.tileHeight) / 2
        };
        cell++;
      }
      startY--;
    }
  }

  private drawTile(x: number, y: number, color: string, cellId: number, stroke?: string, ddd: boolean = false) {
    stroke = stroke || "black";
    const strokeWidth = 0.5;
    const points = [
      x + this.tileWidth, y + this.tileHeight / 2,
      x + this.tileWidth / 2, y + this.tileHeight,
      x, y + this.tileHeight / 2,
      x + this.tileWidth / 2, y
    ];
    const poly = new Konva.Line({
      points,
      fill: color,
      stroke,
      strokeWidth,
      closed: true
    });
    this.layer.add(poly);
    if (ddd) {
      this.layer.add(new Konva.Line({
        points: [
          x + this.tileWidth / 2, y - this.tileHeight / 4,
          x + this.tileWidth, y + this.tileHeight / 2 - this.tileHeight / 4,
          x + this.tileWidth / 2, y + this.tileHeight - this.tileHeight / 4,
          x, y + this.tileHeight / 2 - this.tileHeight / 4
        ],
        fill: color,
        stroke: "#373737",
        strokeWidth,
        closed: true
      }));
      this.layer.add(new Konva.Line({
        points: [
          x + this.tileWidth, y + this.tileHeight / 2 - this.tileHeight / 4,
          x + this.tileWidth / 2, y + this.tileHeight - this.tileHeight / 4,
          x + this.tileWidth / 2, y + this.tileHeight,
          x + this.tileWidth, y + this.tileHeight / 2
        ],
        fill: color,
        stroke: "#373737",
        strokeWidth,
        closed: true
      }));
      this.layer.add(new Konva.Line({
        points: [
          x, y + this.tileHeight / 2 - this.tileHeight / 4,
          x + this.tileWidth / 2, y + this.tileHeight - this.tileHeight / 4,
          x + this.tileWidth / 2, y + this.tileHeight,
          x, y + this.tileHeight / 2

        ],
        fill: color,
        stroke: "#373737",
        strokeWidth,
        closed: true
      }));

    }
    return poly;
  }

  // private clickOnCell(cellId: string) {
  //   this.bot.sendToBot("DashCommandRequestMessage", {
  //     command: "/moveToCell " + cellId
  //   } as DashCommandRequestMessage);
  //   console.log("Click on " + cellId);
  // }

  private cellCoords(cellId: number) {
    return {
      x: cellId % this.MAP_WIDTH, // X
      y: Math.floor(cellId / this.MAP_WIDTH) // Y
    };
  }

  private clickOnCell(cellId: number) {
    this.bot.game.map.moveToCell(cellId);
  }

  private addEntitie(entitie: GameRolePlayActorInformations) {
    // @ts-ignore
    if (!entitie._messageType) entitie._messageType = entitie._type;
    const entiteShape = {
      entitie,
      shape: null,
      cellId: entitie.disposition.cellId
    } as any;
    switch (entitie._messageType) {
      case "GameRolePlayCharacterInformations":
        if (entitie.contextualId == this.bot.game.character.id) {
          entiteShape.shape = this.drawCircle(
            this.CELLPOS[entitie.disposition.cellId].pixelX,
            this.CELLPOS[entitie.disposition.cellId].pixelY,
            "green", entitie.disposition.cellId);
        } else {
          entiteShape.shape = this.drawCircle(
            this.CELLPOS[entitie.disposition.cellId].pixelX,
            this.CELLPOS[entitie.disposition.cellId].pixelY,
            "lightgreen", entitie.disposition.cellId);
        }
        break;
      case "GameRolePlayNpcWithQuestInformations":
      case "GameRolePlayNpcInformations":
        entiteShape.shape = this.drawCircle(
          this.CELLPOS[entitie.disposition.cellId].pixelX,
          this.CELLPOS[entitie.disposition.cellId].pixelY,
          "pink", entitie.disposition.cellId);
        break;
      case "GameRolePlayGroupMonsterInformations":
        entiteShape.shape = this.drawCircle(
          this.CELLPOS[entitie.disposition.cellId].pixelX,
          this.CELLPOS[entitie.disposition.cellId].pixelY,
          "red", entitie.disposition.cellId);
        break;
    }
    if (entiteShape.shape) {
      entiteShape.shape["entiteShape"] = entiteShape;
      this.entities.push(entiteShape);


    }
  }


  private async moveEntitieToCellId(entity: any, keyMovements: number[], ratioDuration = 1) {
    const durations = MovementPath.getMovementDuration(keyMovements);
    const moveActionId = Utils.getRandomInt(1, 999999);
    entity.moveActionId = moveActionId;
    for (let i = 1; i < keyMovements.length; i++) {
      const duration = durations[i - 1];

      const actualCellId = keyMovements[i - 1];
      const nextCellId = keyMovements[i];

      const actualX = this.CELLPOS[actualCellId].pixelX + this.tileWidth / 2;
      const actualY = this.CELLPOS[actualCellId].pixelY + this.tileHeight / 2;
      const nextX = this.CELLPOS[nextCellId].pixelX + this.tileWidth / 2;
      const nextY = this.CELLPOS[nextCellId].pixelY + this.tileHeight / 2;

      const diffX = nextX - actualX;
      const diffY = nextY - actualY;

      // const anim = new Konva.Animation(function (frame) {
      //   entity.shape.x(actualX + diffX * (frame.time / duration));
      //   entity.shape.y(actualY + diffY * (frame.time / duration));
      // }, this.layer);
      await Utils.sleep(duration * ratioDuration);
      if (entity.moveActionId != moveActionId) break;
      entity.shape.x(nextX);
      entity.shape.y(nextY);
      // anim.start();
      // anim.stop();
    }
  }
}
