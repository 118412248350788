import {MapPoint} from "@dashboard/src/app/utils/MapPoint";

const AnimDuration = {
  mounted: {linear: 135, horizontal: 200, vertical: 120, symbolId: "AnimCourse"},
  parable: {linear: 400, horizontal: 500, vertical: 450, symbolId: "FX"},
  running: {linear: 170, horizontal: 255, vertical: 150, symbolId: "AnimCourse"},
  walking: {linear: 480, horizontal: 510, vertical: 425, symbolId: "AnimMarche"},
  slide: {linear: 57, horizontal: 85, vertical: 50, symbolId: "AnimStatique"}
};

export class MovementPath {

  static getMovementDuration(keyMovements: number[], forceWalk = false, mounted = false) {
    let durations = [];
    let anims = null;
    if (keyMovements.length <= 3 || forceWalk) {
      anims = AnimDuration.walking;
    } else if (mounted) {
      anims = AnimDuration.mounted;
    } else {
      anims = AnimDuration.running;
    }
    for (let i = 1; i < keyMovements.length; i++) {
      const origin = keyMovements[i - 1];
      const target = keyMovements[i];
      const orientation = MapPoint.orientationTo(origin, target);
      if (orientation % 2 == 0) {
        if (orientation % 4 == 0) {
          durations.push(anims.horizontal);
        } else {
          durations.push(anims.vertical);
        }
      } else {
        durations.push(anims.linear);
      }
    }
    return durations;
  }
}
